/* eslint-disable import/no-cycle */
import { Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import OrderTable from './components/OrderTable';
import { selectOrder } from '../../redux/actions/orderActions';
import { openModal } from '../../redux/actions/modalActions';
import { addItemsToTable } from '../../redux/actions/tableActions';
import { toast } from 'react-toastify';


const ActualOrder = () => {
  const history = useHistory();
  let selectedOrderId = '';
  const location = useLocation();
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);
  const cart = useSelector(state => state.cart);
  const { ticketsModalIsOpen } = modals;
  const { orderSelected } = cart;

  function handleLoadorder() {
    dispatch(selectOrder(selectedOrderId));
    dispatch(openModal('cart'));
  }

  function goBackToVenta() {
    const { location: { pathname } } = history;
    const orderId = pathname.split('/')[3];
    if (orderId) {
      history.push('/pos/ventas');
    }
  }

  const handleRefound = () => {
    setTimeout(() => {
      dispatch(openModal('refund'));
      dispatch(addItemsToTable('refund'));
    }, 1000);
  };

  useEffect(() => {
    const orderId = location.pathname.split('/')[3];
    if (orderId) {
      selectedOrderId = orderId;
      handleLoadorder();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('closeCartModal', goBackToVenta);

    return () => {
      window.removeEventListener('closeCartModal', goBackToVenta);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('ventas/devolucion')) {
      const getOrderId = location.pathname.split('/')[4];
      if (getOrderId) {
        dispatch(selectOrder(getOrderId));
      } else {
        toast.warning('No se ha encontrado una orden para devolución');
      }
    }
  }, [location]);

  useEffect(() => {
    if (orderSelected && orderSelected.order && orderSelected.order.crmid && location.pathname.includes('ventas/devolucion')) {
      handleRefound();
    }
  }, [orderSelected, location]);

  return (
    <Paper>
      {
        ticketsModalIsOpen && localStorage.getItem('printMode') === 'true' ? null : (
          <OrderTable />
        )
      }
    </Paper>
  );
};

export default ActualOrder;
