/* eslint-disable react/prop-types */
import React from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './drop-up.scss';

const DropUpButton = ({
  title,
  icon,
  isSelected = false,
  action = () => {},
  isOpen,
  onClick = () => {},
  disabled = false,
  color = '#8252A0',
  bordered = true,
}) => (
  <div className="drop-up-selected-container w-100">
    <button
      type="button"
      className={`drop-up-select ${isSelected ? 'border-left' : ''}`}
      onClick={() => onClick()}
      disabled={disabled}
      style={{
        backgroundColor: color, border: 0, borderRadius: bordered ? 5 : 0, color: 'white', filter: disabled ? 'opacity(0.5)' : 'none',
      }}
    >
      <FontAwesomeIcon style={{ fontSize: 19 }} icon={icon} />
      <span className="option-title">{title}</span>
    </button>
    {isSelected && (
    <div className="drop-up-toggle" onClick={() => action()}>
      <FontAwesomeIcon
        icon={!isOpen ? faCaretUp : faCaretDown}
        style={{ fontSize: 15 }}
      />
    </div>
    )}
  </div>
);

export default DropUpButton;
