/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  HighlightOffOutlined,
  CheckCircleOutlineOutlined,
} from '@material-ui/icons';
import { Spinner } from 'reactstrap';
import { toolsHelper } from '../../../helpers';
/* Components */
import ListMdaItem from '../../Mda/components/ListMda/ListMdaItem';

import RefundConstructor from './Refund';
import Deliver from './Deliver';
import { formatString } from '../../../helpers/tools';

class WharehouseForm extends Component {
  static propTypes = {
    activity: PropTypes.string.isRequired,
    authUser: PropTypes.object.isRequired,
    dispatchChangeComment: PropTypes.func.isRequired,
    dispatchSelectEmpty: PropTypes.func.isRequired,
    dispatchSelectToDelivered: PropTypes.func.isRequired,
    dispatchSelectToRefunded: PropTypes.func.isRequired,
    dispatchChangeBadProducts: PropTypes.func.isRequired,
    dispatchChangeProducts: PropTypes.func.isRequired,
    loads: PropTypes.object.isRequired,
    refundAll: PropTypes.func,
    table: PropTypes.object.isRequired,
    isExternalDeliver: PropTypes.bool,
    cart: PropTypes.object,
  };

  static defaultProps = {
    refundAll: () => {},
    isExternalDeliver: false,
  };

  constructor(props) {
    super(props);
    const { authUser, isExternalDeliver } = this.props;
    const { user: { metodos_pagos } } = authUser;
    this.state = {
      metodo: metodos_pagos[0],
      subMetodo: metodos_pagos[0]?.body[0],
      open: !!isExternalDeliver,
      defectos: '',
      cambio: '',
    };
  }

  getDate = () => {
    const actual = new Date();
    const day = `0${actual.getDate()}`.slice(-2);
    const month = `0${actual.getMonth() + 1}`.slice(-2);
    const year = actual.getFullYear();

    return `${year}-${month}-${day}`;
  };

  getInputsAdornaments = () => {
    const { loads } = this.props;
    if (loads.tableIsLoading) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <Spinner />
          </InputAdornment>
        ),
      };
    }
    return {};
  };

  changeComment = (value) => {
    const { dispatchChangeComment } = this.props;
    dispatchChangeComment(value);
  }

  handleMetodo = (x) => {
    this.setState({
      metodo: x,
      subMetodo: x.body[0],
    });
  };

  handleSubMetodo = (x) => {
    this.setState({ subMetodo: x });
  };

  handleMethodMenu = (event) => {
    const { name } = event.currentTarget;
    this.setState({ [name]: event.currentTarget });
  };

  handleMethodOnClose = (name) => {
    this.setState({ [name]: null });
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }


  handleChangeCambio = (event) => {
    const { dispatchChangeProducts } = this.props;
    this.setState({ cambio: event.target.value });
    dispatchChangeProducts(event.target.value);
  };

  handleChange = (event) => {
    const { dispatchChangeBadProducts, dispatchChangeProducts } = this.props;
    this.setState({ defectos: event.target.value });
    dispatchChangeBadProducts(event.target.value);

    if (event.target.value === 'false') {
      this.setState({ cambio: 'false' });
      dispatchChangeProducts('false');
    }
    if (event.target.value === 'true') {
      this.setState({ cambio: '' });
      dispatchChangeProducts('');
    }
  };


  render() {
    const {
      activity,
      dispatchSelectEmpty,
      dispatchSelectToDelivered,
      dispatchSelectToRefunded,
      loads,
      refundAll,
      table,
      authUser,
      isExternalDeliver,
      cart,
    } = this.props;
    const focusUsernameInputField = (input) => {
      // eslint-disable-next-line no-unused-expressions
      if (loads.tableIsLoading) { input && input.focus(); }
    };
    const productsSelected = Boolean(table.items && table.items.some(x => x.field > 0));
    // const qtySelected = (table.items.length > 0 ? (table.items.map(x => x.field).reduce((a, b) => (a + b))) : (0));
    let moneySelected = (table.items.length > 0 ? (table.items.map(x => (x.price * x.field)).reduce((a, b) => (a + b))) : (0));
    if (table.totalDiscount > 0) moneySelected -= (moneySelected * (table.totalDiscount / 100));
    const dif = (table.total - (table.total * (table.totalDiscount / 100))) - table.balance;
    const somePaid = Boolean(dif > 0);
    if (Math.round(table.balance) < Math.round(moneySelected)) moneySelected -= table.balance;
    const {
      metodo,
      subMetodo,
      open,
      defectos,
      cambio,
    } = this.state;
    const { user: { config } } = authUser;
    const deliverOutStock = Boolean(config.pos_deliver_out_stock === '1');
    // const { orderSelected: { order } } = cart;

    return (
      <>
        <FormControl className="w-100 form_control_deliver">
          {
            activity === 'refund' && table.store.treebesalmid_dev_label && cart?.orderSelected?.order && cart?.orderSelected?.order.estado_mda === 'Entregado' && (
              <div>
                <h3 style={{ display: 'flex', justifyContent: 'center' }}>¿La mercancia esta dañada?</h3>
                <RadioGroup
                  aria-label="Gender"
                  name="defecto"
                  value={defectos}
                  onChange={this.handleChange}
                  className="d-flex"
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                >
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                  <FormControlLabel value="true" control={<Radio />} label="Si" />
                </RadioGroup>
              </div>
            )
          }
          {
            activity === 'refund' && table.store.treebesalmid_dev_label && defectos === 'true' && cart?.orderSelected?.order && cart?.orderSelected?.order.estado_mda === 'Entregado' && (
              <div>
                <h3 style={{ display: 'flex', justifyContent: 'center' }}>¿Cambiaras el producto dañado por uno igual?</h3>
                <RadioGroup
                  aria-label="Gender"
                  name="defecto"
                  value={cambio}
                  onChange={this.handleChangeCambio}
                  className="d-flex"
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                >
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                  <FormControlLabel value="true" control={<Radio />} label="Si" />
                </RadioGroup>
              </div>
            )
          }
          {
            activity === 'refund' && table.store.treebesalmid_dev_label && (cambio === '' || defectos === '') && cart?.orderSelected?.order && cart?.orderSelected?.order.estado_mda === 'Entregado' ? null : (
              <>
                <Collapse in={Boolean(!loads.tableIsLoading)} className="w-100">
                  <div className="infoDetail">
                    <ListMdaItem
                      item={{
                        createdtime: table.date,
                        entity_label: table.ref,
                        // eslint-disable-next-line no-nested-ternary
                        de_documentoid_label: activity === 'deliver' ? table.store.name : table.toSell,
                        a_documentoid_label: activity === 'deliver' ? table.toSell : activity === 'refund' && table.store.treebesalmid_dev_label && defectos === 'true' ? table.store.treebesalmid_dev_label : table.store.name,
                      }}
                      isCollapse={false}
                      sc="Entrega"
                    />
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {table.account.nombre.substring(0, 1)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={formatString(table.account.nombre)}
                        secondary="Cliente"
                      />
                    </ListItem>
                  </div>
                  <TextField
                    id="outlined-comments"
                    label="Comentarios"
                    className="w-100"
                    styke={{ margin: '0 !important' }}
                    variant="outlined"
                    multiline
                    rows={1}
                    rowsMax={4}
                    inputRef={focusUsernameInputField}
                    onKeyUp={(e) => {
                      // @ts-ignore
                      const { value } = e.target;
                      this.changeComment(value);
                    }}
                  />
                </Collapse>
                {activity !== 'deliver' ? (
                  <RefundConstructor />
                ) : (
                  <>
                    {activity === 'deliver' ? (
                      <div className="wharehouse_form_btn w-100 d-flex justify-content-around align-items-center mt-2">
                        <Button
                          onClick={() => dispatchSelectToDelivered()}
                          variant="outlined"
                          color="primary"
                        >
                          Seleccionar Por Entregar
                        </Button>
                        <Tooltip title={deliverOutStock
                          ? 'Permite entregar existencia aun cuando el stock sea cero'
                          : 'No permite entregar sin existencia; debe contar con stock disponible para realizar la entrega.'}
                        >
                          <Chip
                            label="Entrega sin existencia"
                            variant="outlined"
                            color={deliverOutStock ? 'primary' : 'inherit'}
                            clickable={deliverOutStock}
                            icon={deliverOutStock
                              ? (<CheckCircleOutlineOutlined />)
                              : (<HighlightOffOutlined />)}
                          />
                        </Tooltip>
                        <Button
                          onClick={() => dispatchSelectEmpty()}
                          variant="outlined"
                          color="secondary"
                        >
                          Seleccionar Nada
                        </Button>
                      </div>
                    ) : (
                      <div className="wharehouse_form_btn w-100 d-flex justify-content-around">
                        <Button
                          onClick={() => dispatchSelectToRefunded()}
                          variant="outlined"
                          color="primary"
                        >
                          Selecciona todo
                        </Button>
                        <Collapse
                          in={productsSelected && somePaid}
                        >
                          <Tooltip title={`por $${toolsHelper.formatMoney(moneySelected)} en una salida de dinero via ${metodo.method} - ${subMetodo.entity_label}`}>
                            <Button
                              onClick={() => {
                                if (productsSelected && somePaid) {
                                  const referencia = table.ref;
                                  const payments = [{
                                    metodo: metodo.method,
                                    tcybid: subMetodo.crmid,
                                    monto: moneySelected,
                                    referencia,
                                  }];
                                  const crmid = table.toSell;
                                  refundAll({ payments, crmid });
                                }
                              }}
                              variant="outlined"
                              color="inherit"
                            >
                              Devolver productos y dinero
                            </Button>
                          </Tooltip>
                        </Collapse>
                        <Button
                          onClick={() => dispatchSelectEmpty()}
                          variant="outlined"
                          color="secondary"
                        >
                          Vacía selección
                        </Button>
                      </div>
                    )}
                    {activity === 'deliver' && (
                      <Deliver />
                    )}
                    {
                    loads.tableIsLoading && (
                      <div className="w-100 h-100 py-4 mt-2">
                        <LinearProgress color="primary" />
                      </div>
                    )
                  }
                  </>
                )}
              </>
            )
          }
        </FormControl>
      </>
    );
  }
}

export default WharehouseForm;
