
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const SET_CART_ACTION_SELECTED = 'SET_CART_ACTION_SELECTED';

export const changePosV2View = value => (
  async (dispatch) => {
    dispatch({ type: CHANGE_VIEW, payload: value });
  }
);

export const setCartActionSelected = value => (
  async (dispatch) => {
    dispatch({ type: SET_CART_ACTION_SELECTED, payload: value });
  }
);
