/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addProductToCart } from '../../../redux/actions/cartActions';
import ProductAPI from '../../../api/ProductAPI';

const CartBarCodeScanner = ({onSelect}) => {
  const productApi = new ProductAPI();
  const dispatch = useDispatch();

  const listenScanCode = async (e) => {
    const { detail } = e;
    if (detail === '' || !detail) {
      return;
    }

    const searchParam = {};
    searchParam.codigo_barras = detail;
    const response = await productApi.productFastSearch(searchParam);
    const { result } = response;
    const options = Object.entries(result);

    for (let i = 0; i < options.length; i += 1) {
      const itemArray = options[i];
      const item = itemArray[1];
      if (item.codigo_barras) {
        const products = Object.values(item.Products);
        // No products
        if (!products || products.length === 0) {
          toast.warn('No se encontraron productos con el código de barras escaneado');
          return;
        }
        if (products.length > 0) {
          // Filter products by barcode
          const filteredProducts = products.filter(p => p.codigo_barras === detail);
          // More than one product with the same barcode
          if (filteredProducts.length > 1) {
            onSelect(item);
            toast.warn('Hay más de un producto con el código de barras escaneado');
            return;
          }
          // Only one product with the barcode
          const product = filteredProducts[0];
          if (item && item.crmid && product.crmid) {
            dispatch(addProductToCart(item.crmid, product.crmid, 1));
            return;
          }
          toast.warn('No se encontraron productos con el código de barras escaneado');
          return;
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('barcode-scanned', listenScanCode);
    return () => {
      document.removeEventListener('barcode-scanned', listenScanCode);
    };
  }, []);

  return (<div className="barcode-reader" />);
};

export default CartBarCodeScanner;
