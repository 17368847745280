/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';
import { setActivityToOrder } from '../../../../redux/actions/orderActions';
import { refundAll } from '../../../../redux/actions/sellActions';
import RefundDialog from '../../../Refund/components/RefundDialog';
import {
  addItemsToTable,
  changeFieldValue,
  chnageComment,
  clearTable,
  refoundBadProduct,
  refoundChangeProduct,
  selectEmptyToTable,
  selectToDelivered,
  selectToRefunded,
} from '../../../../redux/actions/tableActions';
import DropUpButton from '../DropUpButton';

const CartRefundBtn = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const table = useSelector(state => state.table);
  const loads = useSelector(state => state.loads);
  const modals = useSelector(state => state.modals);
  const cart = useSelector(state => state.cart);
  const { orderSelected } = cart;
  const { enableAction } = table;

  function onSave() {
    dispatch(
      setActivityToOrder(cart.orderSelected.crmid, 'Devolver Productos'),
    );
  }

  function onOpen() {
    dispatch(openModal('refund'));
    dispatch(addItemsToTable('refund'));
  }

  return (
    <div className="refund_container w-100">
      {/* <DropUpButton
        title="Devolver Productos"
        icon={faCartArrowDown}
        onClick={cart.totalProducts > 0 ? onOpen : () => {}}
        /* disabled={disabled}
      /> */}
      {/* <Button
      onClick={onOpen}
      className="w-100"
      color="secondary"
      variant="contained"
      disabled={disabled}
    >
      <span className="pr-1">
        <FontAwesomeIcon icon="cart-arrow-down" />
      </span>
      <span className="pl-1">
        Devolver Productos
      </span>
    </Button> */}
      <RefundDialog
        authUser={authUser}
        dispatchChangeComment={comment => dispatch(chnageComment(comment))}
        dispatchChangeFieldValue={obj => dispatch(changeFieldValue(obj))}
        dispatchSelectEmpty={() => dispatch(selectEmptyToTable())}
        dispatchSelectToDelivered={() => dispatch(selectToDelivered())}
        dispatchSelectToRefunded={() => dispatch(selectToRefunded())}
        dispatchChangeBadProducts={value => dispatch(refoundBadProduct(value))
        }
        dispatchChangeProducts={value => dispatch(refoundChangeProduct(value))
        }
        enableAction={enableAction}
        isLoading={Boolean(loads.orderIsLoading)}
        isOpen={Boolean(modals.refundModalIsOpen)}
        loads={loads}
        onClose={() => {
          dispatch(closeModal('refund'));
          dispatch(clearTable());
        }}
        onSave={onSave}
        refundAll={object => dispatch(refundAll(object))}
        table={table}
        title={`Devolución a cliente de la orden: ${orderSelected?.order?.salesorder_no}`}
      />
    </div>
  );
};

export default CartRefundBtn;
