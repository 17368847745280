/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useEffect, useLayoutEffect, useState,
} from 'react';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeProductInCart, setProductSerie } from '../../../../redux/actions/cartActions';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';

const ExpansionPanell = withStyles({
  expanded: {
    margin: 'auto',
  },
})(ExpansionPanel);

const ProductSerials = ({ product }) => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(product?.series ? [...product?.series] : []);

  const createOption = (label, treebeslotesid, isFixed = false) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    treebeslotesid,
    isFixed,
  });


  const setDefaultLotes = () => {
    const defaultOptions = [];
    Object.values(product.TreebesLotes).map((l) => {
      const option = createOption(l.number, l.treebeslotesid);
      defaultOptions.push(option);
    });
    if (defaultOptions.length) {
      setOptions(defaultOptions);
    }
  };

  /* function selectFirstSerie() {
    const firstSerie = Object.values(product.TreebesLotes)[0];
    const selectedOption = createOption(firstSerie.number, firstSerie.treebeslotesid);
    setSelectedOptions([selectedOption]);
  } */

  function handleSelectOption(selectedOption) {
    // validate stock
    const qtyStock = parseFloat(product.qtyinstock);
    if (selectedOptions.length + 1 > qtyStock) {
      const updatedOptions = [...selectedOptions];
      setSelectedOptions([...updatedOptions]);
      toast.warn('No puedes seleccionar más series de las que tienes en stock');
      return;
    }
    const updatedOptions = [...selectedOptions, selectedOption];
    setSelectedOptions([...updatedOptions]);
    const updatedProduct = { ...product, series: updatedOptions };
    dispatch(setProductSerie(updatedProduct));
  }

  function handleRemoveValue(selectedOption) {
    // validate last option
    if (selectedOptions.length === 1) {
      toast.warn('No puedes eliminar la única serie seleccionada');
      const updatedOptions = [...selectedOptions];
      setSelectedOptions([...updatedOptions]);
      return;
    }
    const updatedOptions = selectedOptions.filter(option => option.value !== selectedOption.value);
    setSelectedOptions([...updatedOptions]);
    const updatedProduct = { ...product, series: updatedOptions };
    dispatch(setProductSerie(updatedProduct));
  }

  function handleClearValue() {
    // Only left the first option
    const updatedOptions = [selectedOptions[0]];
    setSelectedOptions([...updatedOptions]);
    toast.warn('Debes seleccionar al menos una serie');
    const updatedProduct = { ...product, series: updatedOptions };
    dispatch(setProductSerie(updatedProduct));
  }

  /*   useMemo(() => {
    const qty = parseFloat(product.quantity);
    if (qty > selectedOptions.length) {
      // Add the missing series
      const missingQty = qty - selectedOptions.length;
      const updatedOptions = [...selectedOptions];
      for (let i = 0; i < missingQty; i++) {
        const serie = Object.values(product.TreebesLotes)[selectedOptions.length + i];
        if (!serie) {
          break;
        }
        const selectedOption = createOption(serie.number, serie.treebeslotesid);
        updatedOptions.push(selectedOption);
        const updatedProduct = { ...product, series: [...updatedOptions] };
        setSelectedOptions([...updatedOptions]);
        dispatch(setProductSerie(updatedProduct));
      }
    }
  }, [product.quantity]); */

  const handleChange = (selectedOption, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        handleSelectOption(actionMeta.option);
        break;
      case 'remove-value':
      case 'deselect-option':
      case 'pop-value':
        handleRemoveValue(actionMeta.removedValue);
        break;
      case 'clear':
        handleClearValue();
        break;
      default:
        break;
    }
  };

  const handleDone = () => {
    const event = new Event('focusSearch');
    dispatch(closeModal(`${product.crmid}-Serials`));
    setTimeout(() => {
      document.dispatchEvent(event);
    }, 1000);
  };

  const handleCancel = () => {
    const event = new Event('focusSearch');
    dispatch(removeProductInCart(product.row));
    dispatch(closeModal(`${product.crmid}-Serials`));
    setTimeout(() => {
      document.dispatchEvent(event);
    }, 1000);
  };

  useEffect(() => {
    if (Object.values(product.TreebesLotes).length) {
      setDefaultLotes();
    }
  }, [product]);

  useEffect(() => {
    if (product?.series?.length > 0) {
      setSelectedOptions(product.series);
    }
  }, [product.series]);

  useLayoutEffect(() => {
    if (Object.values(product.TreebesLotes).length && (!product.series || product.series.length === 0) && !modals[`${product.crmid}-SerialsModalIsOpen`]) {
      dispatch(openModal(`${product.crmid}-Serials`));
    }
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <ExpansionPanell
        classes={{ expanded: { margin: 'auto' } }}
        square
        key={Math.random()}
        expanded={localStorage.getItem('scanedProductId') === product?.id || open}
      >
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon onClick={() => setOpen(!open)} />
                }

        >
          <div style={{ width: '100%' }} className="d-flex justify-content-between align-items-center">
            <Typography>
              Series
            </Typography>
            <Badge badgeContent={selectedOptions.length} color="primary" />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={options}
              defaultValue={selectedOptions || []}
              onChange={handleChange}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanell>

      <Dialog
        scroll="paper"
        open={modals[`${product.crmid}-SerialsModalIsOpen`]}
        aria-labelledby="simple-dialog-title"
        classes={{
          paper: 'overflow-y-visible',
        }}
      >
        <DialogTitle id="simple-dialog-title">Seleccione de Serie</DialogTitle>
        <DialogContent classes={{
          root: 'overflow-y-visible',
        }}
        >
          <small>
            Seleccione series para
            {' '}
            <strong>{product.productname}</strong>
          </small>
          <div style={{ width: '100%' }}>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={options}
              defaultValue={selectedOptions || []}
              onChange={handleChange}
              autoFocus
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={selectedOptions.length === 0}
            onClick={() => handleDone()}
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductSerials;
