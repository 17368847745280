/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React, { memo, useEffect, useRef } from 'react';
import { useInfiniteHits } from 'react-instantsearch';
import ProductItem from './product-item';
import './product-grid.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setSingleDiscount } from '../../../../redux/actions/cartActions';

const ProductGrid = memo(({ onSelect }) => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const cart = useSelector(state => state.cart);
  const { user: { config: { store } } } = authUser;
  const { hits, isLastPage, showMore } = useInfiniteHits();
  const sentinelRef = useRef(null);
  const { cartProductsByArray } = cart;

  const checkMeliDiscounts = () => {
    // Loop throw cart products
    cartProductsByArray.map((cartProduct, index) => {
      const { parentId, crmid, tpoc_id } = cartProduct;
      hits.map((hit) => {
        if (`${hit.crmid}` === parentId || `${hit.crmid}` === crmid || `${hit.crmid}` === tpoc_id) {
          // Check if hit has discount
          if (hit.discount > 0) {
            // Check if cart product has discount
            if (cartProduct.discount_amount <= 0) {
              // Check if discounts are different
              dispatch(setSingleDiscount(index + 1, hit.discount));
            }
          }
        }
      });
    });
  };

  useEffect(() => {
    if (sentinelRef.current !== null && hits.length > 0) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  useEffect(() => {
    checkMeliDiscounts();
  }, [cartProductsByArray]);

  return (
    <div className="product-grid">
      <div className="ais-Hits">
        <ol className="ais-Hits-list">
          {hits.map(hit => (
            <li key={hit.crmid} className="ais-Hits-item">
              <ProductItem product={hit} onSelect={onSelect} activeIndex={store.crmid} />
            </li>
          ))}
          <li ref={sentinelRef} aria-hidden="true" />
        </ol>
      </div>
    </div>
  );
});

export default ProductGrid;
