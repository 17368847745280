import _addressHelper from './address';
import _balanceHelper from './balance';
import _cartHelper from './cart';
import _orderHelper from './order';
import _pricebookHelper from './pricebook';
import _productHelper from './product';
import _storageHelper from './storage';
import _tableHelper from './table';
import _toolsHelper from './tools';
import _userHelper from './user';
import _phoneHelper from './formatPhone';
import _printerHelper from './print';

export const addressHelper = _addressHelper;
export const balanceHelper = _balanceHelper;
export const cartHelper = _cartHelper;
export const orderHelper = _orderHelper;
export const pricebookHelper = _pricebookHelper;
export const productHelper = _productHelper;
export const storageHelper = _storageHelper;
export const tableHelper = _tableHelper;
export const toolsHelper = _toolsHelper;
export const userHelper = _userHelper;
export const phoneHelper = _phoneHelper;
export const printerHelper = _printerHelper;

export default {
  addressHelper,
  balanceHelper,
  cartHelper,
  orderHelper,
  pricebookHelper,
  productHelper,
  storageHelper,
  tableHelper,
  userHelper,
  phoneHelper,
  printerHelper,
};
