/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Share from '@material-ui/icons/Share';

import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartArrowDown,
  faCopy,
  faDolly,
  faEye,
  faFileSignature,
  faFolderOpen,
  faHandHoldingUsd,
  faHistory,
  faLink,
  faMapMarkerAlt,
  faPenSquare,
  faPrint,
  faTools,
  faTruck,
  faUndo,
  faFileInvoice,
  faColumns,
  faComment,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import OrderStatusBlock from '../../../OrderStatusBlock';
import { printOrder, selectOrder } from '../../../../../../redux/actions/orderActions';
import ShareModal from '../../../../../Share/components/ShareModal';
import appModules from '../../../../../../constant/appModules';
import SatLogo from '../../../../../Icons/SatLogo';
import {
  closeModal,
  openModal,
} from '../../../../../../redux/actions/modalActions';
import ShippingGuideList from '../../../../../ShippingGuide/ShippingGuideList';
import { addItemsToTable } from '../../../../../../redux/actions/tableActions';
import TimbrarApi from '../../../../../../api/TimbrarApi';
import {
  fetchTimbrado,
  printTicketFactura,
} from '../../../../../../redux/actions/TimbrarActions';
import PaymentLinks from '../../../../../PaymentLinks/PaymentLinks';
import OrderAPI from '../../../../../../api/OrderAPI';
import {
  changeSalesOrderStatus,
  saveSell,
} from '../../../../../../redux/actions/sellActions';
import OrderCustomFields from '../../../../../OrderCustomFields/OrderCustomFields';
import TicketsActions from '../../../../../Cart/components/controls/components/TicketsActions';
import TicketFacturaAction from '../../../../../Cart/components/controls/components/TicketFacturaAction';
import BsModal from '../../../../../rkr2-components/BsModal/BsModal';
import Fde from '../../../../../rkr2-components/fde/Fde';
import Invoice from '../../../../../rkr2-components/invoice/Invoice';
import Mda from '../../../../../rkr2-components/mda/Mda';
import File from '../../../../../rkr2-components/file/File';
import Address from '../../../../../rkr2-components/address/Address';
import { setTicketData } from '../../../../../../redux/actions/ticketAction';
import { cloneQuote } from '../../../../../../redux/actions/cotizarActions';
import Shipping from '../../../../../Cart/components/controls/components/shipping/ShippingAction';
import CustomerAPI from '../../../../../../api/CustomerAPI';
import { openDetailTab } from '../../../../../../redux/actions/cartActions';
import useOrderFile from '../../../../../../hooks/order/useOrderFile';

const OrderTableItem = ({ row, headers, searchPagination }) => {
  const { loading: loadingDoc, uploadFile } = useOrderFile(row.crmid);
  const timbrarApi = new TimbrarApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const loads = useSelector(state => state.loads);
  const tabs = useSelector(state => state.tabs);
  const user = useSelector(state => state.authUser.user);
  const { enableEditOrder } = useSelector(state => state.order);
  const cart = useSelector(state => state.cart);
  const { selectedCustomer } = useSelector(state => state.customer);
  const {
    sinStock, orderSelected, products, subTotal, totalIva, description,
  } = cart;
  const disableButton = orderSelected
    || (orderSelected && cart?.orderSelected?.order?.estado_mda === 'Entregado');

  const {
    pos_no_cobrar, pos_sininv, pos_no_entregar, sostatus, pos_plantilla_pred_sell, pos_plantilla_pred_cot,
  } = user.config;
  const [modalOption, setModalOption] = useState('');
  const [openBsModal, setOpenBsModal] = useState(false);
  const [bsModalTitle, setBsModalTitle] = useState('');
  const [orderPicked, setOrderPicked] = useState(null);
  const [openFile, setOpenFile] = useState(false);
  const [file, setFile] = useState();

  const [anchorIn, setAnchorIn] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openGuias, setOpenGuias] = useState(false);
  const [openLinks, setOpenLinks] = useState(false);
  const [openFieldsDialog, setOpenFieldsDialog] = useState(false);
  const domain = useSelector(state => state.authUser.domain);
  const [ticketAnchorEl, setTicketAnchorEl] = React.useState(null);
  const open = Boolean(ticketAnchorEl);

  const noCobrar = Boolean(pos_no_cobrar === '1');

  const noEntregar = Boolean(pos_no_entregar === '1');

  /* Enables tickets Flags */
  const sellTicket = Boolean(tabs.posTypeTab === 'Historico');
  const treebessatcfdiid = orderSelected ? orderSelected.treebessatcfdiid : '';
  const enableFactura = Boolean(treebessatcfdiid !== '');
  const rowRef = useRef();
  const orderAndQuoteStatusKeys = [
    'sostatus',
    'quotestage',
    'estado_fde',
    'estado_mda',
  ];

  const handleCloseGuias = () => {
    setOpenGuias(false);
  };

  const handleCloseCart = () => {
    dispatch(closeModal('cart'));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorIn(null);
    setOpenLinks(false);
    setOpenFieldsDialog(false);
    setTicketAnchorEl(null);
  };

  const handleSelectOrder = async (item) => {
    if (!orderSelected || orderSelected.crmid !== item.crmid) {
      await dispatch(selectOrder(item.crmid));
    }
  };

  const orderDetail = async () => {
    if (!loading) {
      setLoading(true);
      if (!orderSelected || orderSelected.crmid === row.crmid) {
        dispatch(openModal('cart'));
        handleClose();
        setLoading(false);
        return;
      }

      toast.info('Cargando la orden...');
      await dispatch(selectOrder(row.crmid));
      dispatch(openModal('cart'));
      handleClose();
      setLoading(false);
    }
  };

  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);

  const handleOpenShare = async (item) => {
    await handleSelectOrder(item);
    await setOrderPicked(item);
    setOpenShare(true);
    handleClose();
  };

  function handleCloseShare() {
    setOpenShare(false);
  }

  const handleModal = (modal = '') => {
    handleCloseCart();
    dispatch(openModal(modal));
    handleClose();
  };

  const orderInfoDetail = async (modal = '', order) => {
    const modalTitle = {
      file: 0,
      address: 1,
      invoice: 2,
      fde: 3,
      mda: 4,
      guides: 5,
      campos: 6,
      comentarios: 7,
    };
    if (!orderSelected || orderSelected.crmid !== order.crmid) {
      await dispatch(selectOrder(order.crmid));
    }
    await dispatch(openDetailTab(modalTitle[modal]));
    await dispatch(openModal('cart'));
    await setOrderPicked(order);
    setAnchorEl(null);
    setAnchorIn(null);
  };

  const openGuideModal = async (order) => {
    await setOrderPicked(order);
    setOpenGuias(true);
    setAnchorEl(null);
  };

  const timbrar = (item) => {
    dispatch(fetchTimbrado(item.crmid));
    dispatch(openModal('timbrar'));
  };

  const handleDevolucion = async (row) => {
    if (!orderSelected || orderSelected.crmid !== row.crmid) {
      await dispatch(selectOrder(row.crmid));
    }
    await dispatch(openModal('refund'));
    await dispatch(addItemsToTable('refund'));
    handleClose();
  };

  const handleGenerateOrder = async (row) => {
    if (!orderSelected || orderSelected.crmid !== row.crmid) {
      await dispatch(selectOrder(row.crmid));
    }
    await dispatch(changeSalesOrderStatus('Created'));
    await dispatch(saveSell());
    handleClose();
  };

  const checkInvoice = async (item) => {
    handleClose();
    if (
      !Object.keys(orderSelected).length
      || orderSelected?.crmid !== item.crmid
    ) {
      toast.warn('Buscando la orden');
      await handleSelectOrder(item);
    }
    const response = await timbrarApi.getList(item.crmid);
    const { result } = response;
    if (result.length === 0) {
      timbrar(item);
    } else {
      toast.warn('Esa orden ya fue timbrada');
    }
  };

  const paymentLink = async (item) => {
    handleClose();
    if (
      !Object.keys(orderSelected).length
      || orderSelected?.crmid !== item.crmid
    ) {
      toast.warn('Buscando la orden');
      await handleSelectOrder(item);
    }
    await setOrderPicked(item);
    await setOpenLinks(true);
  };

  const handleCobrar = async (item) => {
    toast.warn('Buscando la orden');
    handleClose();
    await handleSelectOrder(item);
    dispatch(openModal('checkout'));
  };

  const handleDeliver = async (item) => {
    history.push(`/almacenes/entregar/${item.crmid}`);
  };

  const handleExternalDeliver = async (item) => {
    history.push(`/almacenes/programar/entrega/externa/${item.crmid}`);
  };

  const goToCrm = () => {
    const module = tabs.posTypeTab === 'Historico' ? 'SalesOrder' : 'Quotes';
    const url = `https://${domain}/index.php?module=${module}&view=Detail&record=${row.crmid}`;
    window.open(url, '_blank');
  };

  const showLinks = async (order) => {
    await setOrderPicked(order);
    await setOpenLinks(true);
  };

  const showCustomFields = async (order) => {
    await setOrderPicked(order);
    await setOpenFieldsDialog(true);
  };

  const goToMap = async (order) => {
    console.log('order', order);
    const orderApi = new OrderAPI();
    const { result, success } = await orderApi.getOrder(order.crmid);
    if (success) {
      const url = `https://www.google.com/maps/search/?api=1&query=${
        result.ship_street || ''
      }, ${result.ship_pobox || ''}, ${result.ship_country || ''}, ${
        result.ship_state || ''
      }, ${result.ship_code || ''}`;
      window.open(url, '_blank');
    }
  };

  const cloneOrder = async (row) => {
    handleClose();
    toast.success('Clonando la orden...');
    if (!orderSelected || orderSelected.crmid !== row.crmid) {
      await dispatch(selectOrder(row.crmid));
    }
    if (tabs.posTypeTab === 'Historico') {
      await dispatch(changeSalesOrderStatus(sostatus));
      await dispatch(saveSell());
    } else {
      await dispatch(cloneQuote());
    }
    searchPagination();
  };

  const handleClick = async (event) => {
    setTicketAnchorEl(event.currentTarget);
    if (!orderSelected || orderSelected.crmid !== row.crmid) {
      toast.success('Seleccionando orden...');
      await dispatch(selectOrder(row.crmid));
    }
  };

  // Close bs modal
  const onCloseBsModal = () => {
    setOpenBsModal(false);
    setOrderPicked(null);
    setBsModalTitle('');
    setModalOption('');
  };

  const handleOpen = async () => {
    switch (tabs.posTypeTab) {
      case 'Historico':
        if (pos_plantilla_pred_sell && pos_plantilla_pred_sell !== '') {
          await dispatch(printOrder(row.crmid));
          break;
        }
        await dispatch(
          setTicketData(
            selectedCustomer,
            orderSelected.order,
            products,
            subTotal,
            totalIva,
            description || '',
            'order',
          ),
        );
        dispatch(openModal('tickets'));
        break;
      case 'Vender':
        await dispatch(
          setTicketData(
            selectedCustomer,
            {},
            products,
            subTotal,
            totalIva,
            description || '',
            'product',
          ),
        );
        dispatch(openModal('tickets'));
        break;
      case 'Cotizaciones':
        if (pos_plantilla_pred_cot && pos_plantilla_pred_cot !== '') {
          await dispatch(printOrder(row.crmid));
          break;
        }
        await dispatch(
          setTicketData(
            selectedCustomer,
            orderSelected.order,
            products,
            subTotal,
            totalIva,
            description || '',
            'quote',
          ),
        );
        dispatch(openModal('tickets'));
        break;
      default:
        break;
    }
    handleClose();
  };

  const backOrder = async (order) => {
    handleClose();
    setLoading(true);
    const orderApi = new OrderAPI();
    const { result, success } = await orderApi.backOrder(order.crmid);
    if (success) {
      toast.success('El back order se realizo correctamente');
      searchPagination();
    } else {
      toast.error('Error al realizar el back order');
    }

    setLoading(false);
  };

  function handleCloseFile() {
    setOpenFile(false);
    setFile(null);
  }
  const toBase64 = fi => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fi);
    reader.onload = () => {
      resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
    };
    reader.onerror = error => reject(error);
  });

  async function handleUpload() {
    if (file.length > 0) {
      let arr = [];
      await Object.keys(file).forEach(async (key) => {
        const meta = file[key];
        const { name } = meta;
        toBase64(meta).then(async (f) => {
          arr = [{ name, file: f }];
          await uploadFile(arr);
        });
      });
    }
    setOpenFile(false);
    setFile(null);
  }

  useEffect(() => {
    dispatch(closeModal('cart'));
  }, [tabs.posTypeTab, enableEditOrder]);

  useEffect(() => {
    if (tabs.posTypeTab === 'Historico' && !loads.listIsLoading) {
      rowRef.current.addEventListener('dragover', (event) => {
        rowRef.current.style.border = 'dashed';
        event.preventDefault();
      });

      rowRef.current.addEventListener('drop', (event) => {
        rowRef.current.style.border = '';
        const { files } = event.dataTransfer;
        setFile(files);
        setOpenFile(true);
        event.preventDefault();
      });

      rowRef.current.addEventListener('dragleave', (event) => {
        rowRef.current.style.border = '';
        event.preventDefault();
      });

      return () => {
        if (rowRef?.current) {
          rowRef.current.removeEventListener('dragleave', () => {});
          rowRef.current.removeEventListener('dragover', () => {});
          rowRef.current.removeEventListener('drop', () => {});
        }
      };
    }
  }, [tabs, loads]);

  return (
    <>
      {tabs.posTypeTab === 'Cotizaciones' && !loads.listIsLoading && (
        <TableRow
          hover
          key={row.crmid}
          className={orderSelected?.crmid === row.crmid ? 'selected-order' : ''}
          style={{ cursor: 'pointer' }}
        >
          <TableCell className="fixed-cell order-cell">
            <IconButton
              aria-owns={anchorIn ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={e => setAnchorIn(e.target)}
            >
              <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={e => setAnchorEl(e.target)}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <FontAwesomeIcon icon={faTools} style={{ fontSize: '20px' }} />
              )}
            </IconButton>
            <IconButton
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={() => handleOpenShare(row)}
            >
              <Share />
            </IconButton>
          </TableCell>
          {headers.some(head => orderAndQuoteStatusKeys.includes(head.name)) && (
            <TableCell className="order-cell">
              <OrderStatusBlock
                order={row}
                tab={tabs.posTypeTab}
                searchPagination={() => searchPagination()}
              />
            </TableCell>
          )}
          {headers.map(
            header => !orderAndQuoteStatusKeys.includes(header.name) && (
            <TableCell
              onClick={() => orderDetail()}
              className="order-cell"
              component="th"
              scope="row"
            >
              {row[header.name]}
            </TableCell>
            ),
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorIn}
            open={Boolean(anchorIn)}
            onClose={handleClose}
          >
            <MenuItem disabled>Información</MenuItem>
            <MenuItem>
              <List dense>
                <ListItem>
                  <Tooltip title="Archivos">
                    <IconButton
                      onClick={() => orderInfoDetail('file', row)}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Archivos
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                </ListItem>
              </List>
            </MenuItem>
          </Menu>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled>Operaciones</MenuItem>
            <MenuItem>
              <List dense>
                <ListItem>
                  {tabs.posTypeTab === 'Historico' && (
                    <>
                      <Tooltip title="Cobrar">
                        <IconButton
                          disabled={
                            pos_sininv ? false : !sinStock ? noCobrar : true
                          }
                          onClick={() => handleCobrar(row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <FontAwesomeIcon
                            icon={faHandHoldingUsd}
                            style={{ fontSize: '20px' }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Entregar">
                        <IconButton
                          disabled={
                            noEntregar ? true : row?.sostatus === 'Cancelado'
                          }
                          onClick={() => handleDeliver(row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <FontAwesomeIcon
                            icon={faCartArrowDown}
                            style={{ fontSize: '20px' }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Timbrar">
                        <IconButton
                          onClick={() => checkInvoice(row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <SatLogo
                            width="28px"
                            fill="#646464"
                            style={{ fontSize: '20px' }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Devolución">
                        <IconButton
                          onClick={() => handleDevolucion(row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <FontAwesomeIcon
                            icon={faUndo}
                            style={{ fontSize: '20px' }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Entrega externa">
                        <IconButton
                          onClick={() => handleExternalDeliver(row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <FontAwesomeIcon
                            icon={faHistory}
                            style={{ fontSize: '20px' }}
                          />
                        </IconButton>
                      </Tooltip>
                      {/*  <div onClick={async () => {
                          await handleSelectOrder(row);
                          enviosRef.current.click();
                        }}
                        >
                          <FontAwesomeIcon
                            icon={faTruck}
                            style={{ fontSize: '20px' }}
                          />
                        </div> */}
                      <Shipping
                        style={{ visibility: 'none' }}
                        relatedOrderId={row.crmid}
                        placeInOrderAction
                        tooltip="Cotizar envios"
                      />
                    </>
                  )}
                  {tabs.posTypeTab !== 'Historico' && (
                    <Tooltip title="Generar venta">
                      <IconButton
                        disabled={
                          pos_sininv ? false : !sinStock ? noCobrar : true
                        }
                        onClick={() => handleGenerateOrder(row)}
                        className='d-flex justify-content-center" align-items-center mr-2'
                      >
                        <FontAwesomeIcon
                          icon={faFileInvoice}
                          style={{ fontSize: '20px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem disabled>Acciones</MenuItem>
            <MenuItem>
              <List dense>
                <ListItem>
                  <Tooltip title="Ver en sistema">
                    <IconButton
                      onClick={() => goToCrm()}
                      className='d-flex justify-content-center" align-items-center mr-2'
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ fontSize: '20px' }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Enlaces de pagos">
                    <IconButton
                      onClick={() => paymentLink(row)}
                      className='d-flex justify-content-center" align-items-center mr-2'
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        style={{ fontSize: '20px' }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Imprimibles">
                    <IconButton
                      onClick={handleClick}
                      className='d-flex justify-content-center" align-items-center mr-2'
                    >
                      <FontAwesomeIcon
                        icon={faPrint}
                        style={{ fontSize: '20px' }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="cart-print-menu"
                    keepMounted
                    open={Boolean(ticketAnchorEl)}
                    anchorEl={ticketAnchorEl}
                    onClose={handleClose}
                    className="cart_controls cart_horizontal"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                  >
                    <TicketsActions
                      open={() => handleOpen()}
                      enable={Object.keys(orderSelected).length}
                      tooltip={
                        sellTicket ? 'Ticket de Venta' : 'Ticket de Presurtido'
                      }
                    />
                    <TicketFacturaAction
                      open={() => {
                        dispatch(printTicketFactura(treebessatcfdiid));
                        handleClose();
                      }}
                      enable={
                        Object.keys(orderSelected).length && enableFactura
                      }
                      loading={Boolean(loads.timbrarIsLoading)}
                    />
                  </Menu>
                  <Tooltip title="Clonar">
                    <IconButton
                      onClick={() => cloneOrder(row)}
                      className='d-flex justify-content-center" align-items-center mr-2'
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ fontSize: '20px' }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver en google map">
                    <IconButton
                      onClick={() => goToMap(row)}
                      className='d-flex justify-content-center" align-items-center mr-2'
                    >
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ fontSize: '20px' }}
                      />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Campos personalizados">
                    <IconButton onClick={() => showCustomFields(row)} className='d-flex justify-content-center" align-items-center mr-2'>
                      <FontAwesomeIcon
                        icon={faPenSquare}
                        style={{ fontSize: '20px' }}
                      />
                    </IconButton>
                  </Tooltip> */}
                </ListItem>
              </List>
            </MenuItem>
          </Menu>
        </TableRow>
      )}
      {tabs.posTypeTab === 'Historico' && !loads.listIsLoading && (
        <tr
          id={`order-${row.crmid}`}
          ref={rowRef}
          hover
          key={row.crmid}
          className={orderSelected?.crmid === row.crmid ? 'selected-order' : ''}
          style={{ cursor: 'pointer' }}
        >
          <TableCell className="fixed-cell order-cell">
            <IconButton
              aria-owns={anchorIn ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={e => setAnchorIn(e.target)}
            >
              <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={e => setAnchorEl(e.target)}
            >
              {loading || loadingDoc ? (
                <CircularProgress size={20} />
              ) : (
                <FontAwesomeIcon icon={faTools} style={{ fontSize: '20px' }} />
              )}
            </IconButton>
            <IconButton
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={() => handleOpenShare(row)}
            >
              <Share />
            </IconButton>
          </TableCell>
          {headers.some(head => orderAndQuoteStatusKeys.includes(head.name)) && (
            <TableCell className="order-cell">
              <OrderStatusBlock
                order={row}
                tab={tabs.posTypeTab}
                searchPagination={() => searchPagination()}
              />
            </TableCell>
          )}
          {headers.map(
            header => !orderAndQuoteStatusKeys.includes(header.name) && (
            <TableCell
              onClick={() => orderDetail()}
              className="order-cell"
              component="th"
              scope="row"
            >
              {row[header.name]}
            </TableCell>
            ),
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorIn}
            open={Boolean(anchorIn)}
            onClose={handleClose}
          >
            <MenuItem disabled>Información</MenuItem>
            <MenuItem>
              <List dense>
                <ListItem>
                  <Tooltip title="Archivos">
                    <IconButton
                      onClick={() => orderInfoDetail('file', row)}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Archivos
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                  {tabs.posTypeTab === 'Historico' && (
                    <>
                      <Tooltip title="Dir. de envío">
                        <IconButton
                          onClick={() => orderInfoDetail('address', row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Dir. envío
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Facturas">
                        <IconButton
                          onClick={() => orderInfoDetail('invoice', row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faFileSignature}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Facturas
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="F. efectivo">
                        <IconButton
                          onClick={() => orderInfoDetail('fde', row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faHandHoldingUsd}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              F. efectivo
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem />
            <MenuItem>
              <List dense>
                <ListItem>
                  {tabs.posTypeTab === 'Historico' && (
                    <>
                      <Tooltip title="M. Almacen">
                        <IconButton
                          onClick={() => orderInfoDetail('mda', row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faDolly}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              M. Almacen
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Guias">
                        <IconButton
                          onClick={() => orderInfoDetail('guides', row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faTruck}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Guias
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Campos personalizados">
                        <IconButton
                          onClick={() => orderInfoDetail('campos', row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faPenSquare}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Campos
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Comentarios">
                        <IconButton
                          onClick={() => orderInfoDetail('comentarios', row)}
                          className='d-flex justify-content-center" align-items-center mr-2'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faComment}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Comentarios
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </ListItem>
              </List>
            </MenuItem>
          </Menu>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled>Operaciones</MenuItem>
            <MenuItem>
              <List dense>
                <ListItem>
                  {tabs.posTypeTab === 'Historico' && (
                    <>
                      <Tooltip title="Cobrar">
                        <p>
                          <IconButton
                            disabled={
                              pos_sininv ? false : !sinStock ? noCobrar : true
                            }
                            onClick={() => handleCobrar(row)}
                            className='d-flex justify-content-center" align-items-center'
                          >
                            <p className='d-flex flex-column justify-content-center" align-items-center'>
                              <FontAwesomeIcon
                                icon={faHandHoldingUsd}
                                style={{ fontSize: '20px' }}
                              />
                              <span
                                style={{ fontSize: 12, marginTop: '0.5rem' }}
                              >
                                Cobrar
                              </span>
                            </p>
                          </IconButton>
                        </p>
                      </Tooltip>
                      <Tooltip title="Entregar">
                        <IconButton
                          disabled={
                            noEntregar ? true : row?.sostatus === 'Cancelado'
                          }
                          onClick={() => handleDeliver(row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faCartArrowDown}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Entregar
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Timbrar">
                        <IconButton
                          onClick={() => checkInvoice(row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <SatLogo
                              width="28px"
                              fill="#646464"
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.2rem' }}>
                              Timbrar
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Devolución">
                        <IconButton
                          onClick={() => handleDevolucion(row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faUndo}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Devolución
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Entrega externa">
                        <IconButton
                          onClick={() => handleExternalDeliver(row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faHistory}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Entrega
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                      <div className='d-flex flex-column justify-content-center" align-items-center'>
                        <Shipping
                          style={{ visibility: 'none' }}
                          relatedOrderId={row.crmid}
                          placeInOrderAction
                          tooltip="Cotizar envios"
                        />
                        <span
                          style={{
                            fontSize: 12,
                            marginTop: '0.2rem',
                            color: '#7e7e7e',
                          }}
                        >
                          Envíos
                        </span>
                      </div>
                      <Tooltip title="Back Order">
                        <IconButton
                          onClick={() => backOrder(row)}
                          className='d-flex justify-content-center" align-items-center'
                        >
                          <p className='d-flex flex-column justify-content-center" align-items-center'>
                            <FontAwesomeIcon
                              icon={faColumns}
                              style={{ fontSize: '20px' }}
                            />
                            <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                              Back Order
                            </span>
                          </p>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {tabs.posTypeTab !== 'Historico' && (
                    <Tooltip title="Generar venta">
                      <IconButton
                        disabled={
                          pos_sininv ? false : !sinStock ? noCobrar : true
                        }
                        onClick={() => handleGenerateOrder(row)}
                        className='d-flex justify-content-center" align-items-center mr-2'
                      >
                        <FontAwesomeIcon
                          icon={faFileInvoice}
                          style={{ fontSize: '20px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem disabled>Acciones</MenuItem>
            <MenuItem>
              <List dense>
                <ListItem>
                  <Tooltip title="Ver en sistema">
                    <IconButton
                      onClick={() => goToCrm()}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Sistema
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Enlaces de pagos">
                    <IconButton
                      onClick={() => paymentLink(row)}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faLink}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Enlaces
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Imprimibles">
                    <IconButton
                      onClick={handleClick}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faPrint}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Imprimibles
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="cart-print-menu"
                    keepMounted
                    open={Boolean(ticketAnchorEl)}
                    anchorEl={ticketAnchorEl}
                    onClose={handleClose}
                    className="cart_controls cart_horizontal"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                  >
                    <TicketsActions
                      open={() => handleOpen()}
                      enable={Object.keys(orderSelected).length}
                      tooltip={
                        sellTicket ? 'Ticket de Venta' : 'Ticket de Presurtido'
                      }
                    />
                    <TicketFacturaAction
                      open={() => {
                        dispatch(printTicketFactura(treebessatcfdiid));
                        handleClose();
                      }}
                      enable={
                        Object.keys(orderSelected).length && enableFactura
                      }
                      loading={Boolean(loads.timbrarIsLoading)}
                    />
                  </Menu>
                  <Tooltip title="Clonar">
                    <IconButton
                      onClick={() => cloneOrder(row)}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Clonar
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver en google map">
                    <IconButton
                      onClick={() => goToMap(row)}
                      className='d-flex justify-content-center" align-items-center'
                    >
                      <p className='d-flex flex-column justify-content-center" align-items-center'>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          style={{ fontSize: '20px' }}
                        />
                        <span style={{ fontSize: 12, marginTop: '0.5rem' }}>
                          Mapa
                        </span>
                      </p>
                    </IconButton>
                  </Tooltip>
                </ListItem>
              </List>
            </MenuItem>
          </Menu>
        </tr>
      )}
      {openShare && (
        <ShareModal
          open={openShare}
          title="Compartir orden"
          handleClose={handleCloseShare}
          order={orderSelected}
          template={
            tabs.posTypeTab === 'Historico'
              ? appModules.SalesOrder
              : appModules.Quotes
          }
          isLoading={isLoading}
        />
      )}

      {openLinks && (
        <PaymentLinks
          open={openLinks}
          handleClose={handleClose}
          orderId={orderPicked?.crmid}
        />
      )}

      {openFieldsDialog && (
        <OrderCustomFields
          open={openFieldsDialog}
          handleCloseDialog={handleClose}
          orderId={orderPicked?.crmid}
          module={
            tabs.posTypeTab === 'Historico'
              ? appModules.SalesOrder
              : appModules.Quotes
          }
        />
      )}

      <Modal isOpen={openGuias} toggle={handleCloseGuias} size="lg">
        <ModalHeader toggle={handleCloseGuias}>Guías</ModalHeader>
        <ModalBody className="mda_modal_body">
          {openGuias && <ShippingGuideList orderId={orderPicked?.crmid} />}
        </ModalBody>
      </Modal>
      <BsModal
        title={bsModalTitle}
        isOpen={openBsModal}
        onClose={onCloseBsModal}
      >
        {modalOption === 'fde' && <Fde orderId={orderPicked?.crmid} />}
        {modalOption === 'invoice' && <Invoice orderId={orderPicked.crmid} />}
        {modalOption === 'mda' && <Mda orderId={orderPicked.crmid} />}
        {modalOption === 'file' && <File orderId={orderPicked.crmid} />}
        {modalOption === 'address' && (
          <Address
            orderId={orderPicked.crmid}
            clientId={selectedCustomer.crmid}
            isOrder={tabs.posTypeTab === 'Historico'}
          />
        )}
        {modalOption === 'guides' && (
          <ShippingGuideList orderId={orderPicked?.crmid} />
        )}
      </BsModal>
      <Dialog
        open={openFile}
        onClose={handleCloseFile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Agregar archivo a la orden
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro que quiere agregar el archivo a la orden
            {' '}
            {row.subject}
            {' '}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFile} color="primary">
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={handleUpload}
            color="primary"
            autoFocus
          >
            {loadingDoc ? 'Subiendo' : 'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
OrderTableItem.propTypes = {
  row: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  searchPagination: PropTypes.func.isRequired,
};

export default OrderTableItem;
