/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

import productReducer from './productReducer';
import cartReducer from './cartReducer';
import customerReducer from './customerReducer';
import authUserReducer from './authUserReducer';
import sellReducer from './sellReducer';
import discountReducer from './discountReducer';
import tabReducer from './tabReducer';
import modalReducer from './modalReducer';
import loadReducer from './loadReducer';
import displayReducer from './displayReducer';
import orderReducer from './orderReducer';
import tableReducer from './tableReducer';
import keyBoardReducer from './keyBoardReducer';
import BalanceReducer from './BalanceReducer';
import TimbrarReducer from './TimbrarReducer';
import clientReducer from './clientReducer';
import cotizacionReducer from './cotizacionReducer';
import financeReducer from './financeReducer';
import accountReducer from './accountReducer';
import filterReducer from './filterReducer';
import expensesReducer from './expensesReducer';
import guideReducer from './guideReducer';
import taskReducer from './taskReducer';
import ticketReducer from './ticketReducer';
import shippingReducer from './shipReducer';
import priceBookReducer from './priceBookReducer';
import PosV2Reducer from './PosV2Reducer';

export default combineReducers({
  product: productReducer,
  order: orderReducer,
  cart: cartReducer,
  customer: customerReducer,
  client: clientReducer,
  authUser: authUserReducer,
  sell: sellReducer,
  discount: discountReducer,
  tabs: tabReducer,
  modals: modalReducer,
  loads: loadReducer,
  displays: displayReducer,
  table: tableReducer,
  keyBoard: keyBoardReducer,
  balance: BalanceReducer,
  timbrar: TimbrarReducer,
  cotizacion: cotizacionReducer,
  finance: financeReducer,
  account: accountReducer,
  filters: filterReducer,
  gastos: expensesReducer,
  guide: guideReducer,
  tasks: taskReducer,
  tickets: ticketReducer,
  pricebook: priceBookReducer,
  shipping: shippingReducer,
  posv2: PosV2Reducer,
});
