/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  Collapse,
  LinearProgress,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardArrowDown } from '@material-ui/icons';
import { toolsHelper } from '../../../../../helpers';

import RefundFdeItem from './RefundFdeItem';
import RefundFdeControls from './RefundFdeControls';
import RefundFdeTotals from './RefundFdeTotals';

const RefundFde = ({
  fluid,
  joinSelection,
  loading,
  methods,
  metodos_pagos,
  onChangeMonto,
  openFde,
  toDeliver,
  returnSelection,
  selectAndUnSelectAll,
  selectMethod,
  selectSingle,
  selectSub,
  setOpenFde,
  tab,
}) => {
  const someOpen = Boolean(methods.some(x => x?.select === true));
  const allChecked = Boolean(methods.every(x => x?.select === true));
  // eslint-disable-next-line no-param-reassign
  methods = methods.filter(m => m !== false); // -> used to remove invalid payment method

  return (
    <div className="w-100 h-auto d-flex flex-column">
      <Collapse in={loading} className="w-100">
        <LinearProgress />
      </Collapse>
      <Collapse in={!loading && methods.length > 0}>
        <div className="refund_fde_head">Flujo a devolver</div>
        <RefundFdeControls
          checked={allChecked}
          enableJoin={methods.filter(x => x?.select === true).length > 1}
          indeter={Boolean(someOpen && !allChecked)}
          joinSelection={joinSelection}
          open={methods.length > 0}
          returnSelection={returnSelection}
          selectAndUnSelectAll={selectAndUnSelectAll}
        />
        <div className="refund_toRefund">
          {
            methods && methods.length && methods
              .map(item => (
                <RefundFdeItem
                  key={`key_method_${item.id}`}
                  item={item}
                  metodosPagos={metodos_pagos}
                  selectMethod={(key, id) => selectMethod(key, id)}
                  selectSub={selectSub}
                  selectSingle={selectSingle}
                  onChangeMonto={onChangeMonto}
                  tab={tab}
                />
              ))
          }
        </div>
        <RefundFdeTotals
          methods={methods}
          balance={toDeliver}
        />
      </Collapse>
      <Collapse in={!loading && methods.length <= 0}>
        <ListItem dense className="w-100">
          <ListItemText
            primary="Sin flujos de efectivo por devolver"
            secondary="La orden no cuenta con flujos de efectivo por devolver"
          />
        </ListItem>
      </Collapse>
      {!loading && fluid.length > 0 && (
        <div className="refund_fde_head">
          <span>Flujos de Efectivo de la Orden (Histórico)</span>
          <ButtonBase
            className="p-1"
            onClick={() => setOpenFde(!openFde)}
          >
            <KeyboardArrowDown className={openFde ? 'mda_drop' : 'mda_drop noDropDown'} />
          </ButtonBase>
        </div>
      )}
      <Collapse in={openFde} mountOnEnter className="w-100">
        <List className="refund_fde">
          {fluid.map(x => (
            <ListItem key={`key_${x.crmid}`}>
              <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                <div
                  className={
                      `refund_fde_icon ${
                        x.tfde_tipo === 'Ingreso' ? (
                          'fde_list_icon_in'
                        ) : (
                          'fde_list_icon_out'
                        )
                      }`
                    }
                >
                  {x.tfde_tipo === 'Ingreso' ? (
                    <FontAwesomeIcon
                      icon="sign-out-alt"
                      style={{
                        fill: 'gray',
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="sign-in-alt"
                      style={{
                        transform: 'rotate(180deg)',
                        fill: 'gray',
                      }}
                    />
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between mr-auto ml-2">
                  <span className="fde_list_meth">{x.tfde_metodo}</span>
                  <span className="fde_list_acco">{x.cuenta}</span>
                </div>
                <div className="d-flex flex-column justify-content-between mr-2">
                  <span className="fde_list_mont">
                    {`$ ${toolsHelper.formatMoney(
                      x.monto,
                    )}`}
                  </span>
                  <span className="fde_list_date">
                    {`${x.createdtime} - ${moment(
                      x.createdtime,
                    ).fromNow()}`}
                  </span>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

RefundFde.propTypes = {
  fluid: PropTypes.array.isRequired,
  joinSelection: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  methods: PropTypes.array,
  toDeliver: PropTypes.number,
  metodos_pagos: PropTypes.array,
  onChangeMonto: PropTypes.func.isRequired,
  openFde: PropTypes.bool.isRequired,
  returnSelection: PropTypes.func.isRequired,
  selectAndUnSelectAll: PropTypes.func.isRequired,
  selectMethod: PropTypes.func.isRequired,
  selectSingle: PropTypes.func.isRequired,
  selectSub: PropTypes.func.isRequired,
  setOpenFde: PropTypes.func.isRequired,
};

RefundFde.defaultProps = {
  methods: [],
  metodos_pagos: [],
  toDeliver: 0.0,
};

export default RefundFde;
