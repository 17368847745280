/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UserAPI from '../../../../api/UserAPI';
import appModules from '../../../../constant/appModules';
import { closeModal } from '../../../../redux/actions/modalActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CamposPersonalizadosModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [fields, setFields] = React.useState(null);
  const userAPI = new UserAPI();
  const cart = useSelector(state => state.cart);
  const modals = useSelector(state => state.modals);
  const customer = useSelector(state => state.customer);
  const {
    orderSelected: { crmid },
  } = cart;
  const { selectedCustomer } = customer;

  const getFields = async () => {
    setLoading(true);
    const response = await userAPI.getCustomFields(
      appModules.Accounts,
      selectedCustomer.crmid,
    );
    const { success, result } = response;
    if (success) {
      setFields(Object.values(result));
    }
    setLoading(false);
  };

  const handleClose = () => {
    setFields(null);
    dispatch(closeModal('camposPersonalizados'));
  };

  const handleInputChange = (event, index) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? (target.checked ? '1' : '0') : target.value;
    const updatedFields = [...fields];
    updatedFields[index].value = value;
    setFields([...updatedFields]);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newValues = {};
    fields.map((field) => {
      newValues[field.name] = field.value;
    });
    const crmId = module === appModules.Accounts ? selectedCustomer.crmid : crmid;
    if (Object.keys(newValues).length) {
      await userAPI.setCustomFields(newValues, module, crmId);
      handleClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (modals.camposPersonalizadosModalIsOpen) {
      getFields();
    }
  }, [modals]);

  return (
    <Dialog
      fullScreen
      open={modals.camposPersonalizadosModalIsOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar classes={{ root: 'd-flex justify-content-between' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Campos personalizados
          </Typography>
          <IconButton
            type="submit"
            edge="start"
            color="inherit"
            onClick={submitForm}
            aria-label="close"
          >
            {loading && <CircularProgress style={{ color: 'white' }} />}
            {!loading && <SaveIcon />}
            {/* <CloseIcon /> */}
          </IconButton>
        </Toolbar>
      </AppBar>
      <div
        className="d-flex justify-content-center align-items-start"
        style={{ width: '100%', height: '100%', backgroundColor: '#efeff4' }}
      >
        <form
          className="mt-5"
          onSubmit={e => submitForm(e)}
          style={{ width: '80%' }}
        >
          <div style={{ maxWidth: 900 }}>
            <div className="row">
              {fields
                && fields.map((field, index) => (
                  <div key={`index${index}`} className="mt-2 col-6">
                    {field.type !== 'checkbox' && (
                      <label htmlFor="" className="custom-label">
                        {field.label}
                      </label>
                    )}
                    {field.type === 'picklist' && (
                      <select
                        key={`select${index}`}
                        name={field.name}
                        className="custom-input custom-input-sm"
                        onChange={e => handleInputChange(e, index)}
                      >
                        {field.picklistvalues
                          && Object.values(field.picklistvalues).map(
                            (option, index) => (
                              <option
                                key={`option${index}`}
                                defaultValue={option}
                                selected={option === field.value}
                              >
                                {option}
                              </option>
                            ),
                          )}
                      </select>
                    )}
                    {field.type === 'textarea' && (
                      <textarea
                        className="custom-input custom-input-textarea py-2"
                        type={field.type}
                        defaultValue={field.value || field.defaultvalue}
                        name={field.name}
                        required={field.required}
                        cols="30"
                        rows="3"
                        onChange={e => handleInputChange(e, index)}
                      />
                    )}
                    {field.type === 'checkbox' && (
                      <div className="d-flex align-items-center">
                        <input
                          checked={field.value === '1'}
                          className=""
                          type={field.type}
                          value={field.value}
                          name={field.name}
                          required={field.required}
                          onClick={e => handleInputChange(e, index)}
                        />
                        <p className="ml-2 mt-0">{field.label}</p>
                      </div>
                    )}
                    {field.type !== 'picklist'
                      && field.type !== 'textarea'
                      && field.type !== 'checkbox' && (
                        <input
                          className="custom-input custom-input-sm"
                          type={field.type}
                          defaultValue={field.value || field.defaultvalue}
                          name={field.name}
                          required={field.required}
                          onChange={e => handleInputChange(e, index)}
                        />
                    )}
                  </div>
                ))}
              {fields && fields.length === 0 && (
                <p>No hay campos personalizados</p>
              )}
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CamposPersonalizadosModal;
