/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatMoney } from '../../../../helpers/tools';
import Stock from '../products-table/Stock';


const useStyles = makeStyles(() => ({
  lightTooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    padding: '15px',
    opacity: 1,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
  },
}));

const ProductItem = ({ product, onSelect, activeIndex }) => {
  const authUser = useSelector(state => state.authUser);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { user: { config: { store }, product_image_default } } = authUser;
  const handleProductClick = () => {
    if (product?.Products?.length > 0) {
      onSelect(product);
    }
  };

  return (
  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      onClick={handleProductClick}
      key={Math.random()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        gap: '5px',
        height: '100%',
      }}
    >
      <div style={{
        width: '100%', display: 'flex', flexDirection: 'column', position: 'relative',
      }}
      >
        {
          product.discount > 0 && (
            <div style={{
              backgroundColor: '#23A1DB',
              color: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '0',
              left: '0',
              height: '23px',
            }}
            >
              <span style={{ fontSize: '12px' }}>
                -
                {product.discount}
                {' '}
                %
              </span>
            </div>
          )
        }
        <div style={{
          // width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: 'auto',
        }}
        >
          <Tooltip
            enterDelay={1000}
            classes={{ tooltip: classes.lightTooltip }}
            disableFocusListener
            placement="right"
            onOpen={() => setOpen(true)}
            open={open}
            onClose={() => setOpen(false)}
            title={(
              <Stock store={store} hit={product} />
              )}
          >
            <img src={product.tpv_image || product_image_default} alt="Product" style={{ width: '150px', height: '150px', opacity: !product[`alm_${store.crmid}`] ? '0.5' : '1' }} />
          </Tooltip>
        </div>
        {
            !product[`alm_${store.crmid}`] && product?.inventariable !== false && (
              <div style={{
                backgroundColor: '#F05B70',
                color: 'white',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: '0',
                left: '0',
                height: '23px',
              }}
              >
                <span style={{ fontSize: '12px' }}>Agotado</span>
              </div>
            )
          }
      </div>
      <div
        style={{
          maxWidth: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          gap: '5px',
          padding: '5px',
          flex: '1',
        }}
      >
        <div style={{ height: '100%' }}>
          {/* Product Name */}
          <p style={{ textAlign: 'center', fontWeight: 600, fontSize: '12px' }}>
            {product.entity_label}
          </p>
          {/* SKU */}
          <p style={{
            textAlign: 'center', fontSize: '12px', backgroundColor: '#F6F6F7', padding: '2px 4px',
          }}
          >
            SKU:
            {' '}
            {product.sku}
          </p>
        </div>
        {/* Price */}
        <div style={{ width: '100%', padding: '0 15px' }}>
          {product.discount > 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{ textDecoration: 'line-through', fontSize: '12px' }}>
              $
              {formatMoney(product.unit_price)}
            </p>
            <strong>{formatMoney(product.unit_price_included_discount)}</strong>
          </div>
          )}
          {
            !product.discount && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <strong style={{ textAlign: 'center' }}>
                  $
                  {formatMoney(product.unit_price)}
                </strong>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(ProductItem);
