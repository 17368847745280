import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSortBy } from 'react-instantsearch';
import PropTypes from 'prop-types';

const SortFor = ({ items }) => {
  const { refine, options, currentRefinement } = useSortBy({ items });
  const handleSort = (direction) => {
    switch (direction) {
      case 'asc':
        refine(options[0].value);
        break;
      case 'desc':
        refine(options[1].value);
        break;
      default:
        break;
    }
  };
  return (
    <div
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
      }}
    >
      {
        options[0].value === currentRefinement
        && <FontAwesomeIcon color="#303c52" icon={faCaretDown} style={{ fontSize: '14px' }} onClick={() => handleSort('desc')} />
      }
      {
        options[1].value === currentRefinement
        && <FontAwesomeIcon color="#303c52" icon={faCaretUp} style={{ fontSize: '14px' }} onClick={() => handleSort('asc')} />
      }
      {
        options[0].value !== currentRefinement && options[1].value !== currentRefinement
        && <FontAwesomeIcon color="#dddddd" icon={faCaretDown} style={{ fontSize: '14px' }} onClick={() => handleSort('desc')} />
      }
    </div>
  );
};

SortFor.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default SortFor;
