/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import {
  Button,
  Collapse,
  LinearProgress,
  Paper,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import InputNumber from '../../../Input/Number';
import { formatString } from '../../../../helpers/tools';
import useSound from '../../../../hooks/sound/useSound';
import AddBarCode from './components/AddBarCode';

const ReceiverTransferProducts = ({
  products,
  changeInput,
  rest,
  add,
  loading,
  selectAll,
  selectNone,
  updateBarCode,
}) => {
  const { playSound } = useSound();
  const getPartidas = () => {
    let count = 0;
    products.map((item) => {
      if (item.por_recibir !== 0) {
        count += 1;
      }
    });

    return count;
  };

  const getCantidad = () => {
    let count = 0;
    count = products.reduce((acc, item) => acc + item.por_recibir, 0);

    return count;
  };

  const scrolledActionEvent = new Event('scrolledAction');

  const handleReadBarCode = (e) => {
    const barCode = localStorage.getItem('barCode') || '';
    if (e.keyCode === 13 && barCode && products.length) {
      const findIndex = products.findIndex(prod => prod.codigo_barras === barCode);
      if (findIndex !== -1) {
        // Product
        const item = products[findIndex];
        localStorage.setItem('scanedProductId', item.productid);
        document.dispatchEvent(scrolledActionEvent);
        localStorage.setItem('selectedInput', item.productid);
        // console.log('item ->', item);
        if (item.cantidad >= item.por_recibir + 1) {
          add(item.treebesmdaid);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          playSound('error');
          toast.warn('Cantidad máxima agregada');
          localStorage.setItem('barCode', '');
        }
      } else {
        localStorage.setItem('scanedProductId', '');
        playSound('invalid');
        toast.error('No existe el codigo');
        localStorage.setItem('barCode', '');
      }
    } else if (
      e.key !== 'Enter'
      && e.key !== 'Shift'
      && e.key !== 'Backspace'
      && e.key !== 'CapsLock'
    ) {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCodeT', '');
    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [products]);

  useEffect(() => {
    document.addEventListener('scrolledAction', () => {
      const id = localStorage.getItem('scanedProductId');
      const element = document.getElementById(`${id}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      return () => {
        document.removeEventListener('scrolledAction', null, false);
      };
    });
  }, []);

  return (
    <div className="deliver__products">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center mr-2">
            <span className="mr-2">
              Partidas:
              {' '}
              {getPartidas()}
            </span>
            <span>
              Cantidad:
              {' '}
              {getCantidad()}
            </span>
          </div>
          <Button
            disabled={loading}
            className="mr-1"
            variant="outlined"
            onClick={selectAll}
          >
            Recibir todo
          </Button>
          <Button disabled={loading} variant="outlined" onClick={selectNone}>
            Recibir nada
          </Button>
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <div className="w-100 product-list" style={{ maxHeight: '55vh' }}>
        {products.length > 0
        && products.map(item => (
          <div
            id={item.productid}
            className="refund_product"
            key={item.productcode}
            style={{
              border:
            localStorage.getItem('scanedProductId') === item?.productid
              ? '8px solid red'
              : '4px solid #efeff4',
            }}
          >
            <div className="refund_product_detail">
              <div className="refund_product_title full-width">
                <Tooltip placement="left-start" title={item.productname || ''}>
                  <span>{formatString(item.productname)}</span>
                </Tooltip>
              </div>
              <div className="refund_product_desc">
                {`Por Recibir: ${item.cantidad}`}
              </div>
              <div className="refund_product_desc">
                <span>
                  {`codigo: ${item.productcode}`}
                </span>
                <span style={{ whiteSpace: 'nowrap', marginRight: 15 }}>
                  {`, Cantidad: ${item.cantidad}`}
                </span>
              </div>
            </div>
            <div className="refund_product_qty">
              <div style={{
                display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column',
              }}
              >
                {/* <span style={{ whiteSpace: 'nowrap', marginRight: 15 }}>
                  {`Cantidad: ${item.cantidad}`}
                </span>
                <input className="scanInput" type="text" readOnly value={item.codigo_barras} /> */}
                <AddBarCode item={item} updateBarCode={updateBarCode} />
              </div>
              <InputNumber
                number={item.por_recibir}
                disabledMinus={item.cantidad === 0 || item.por_recibir === 0}
                disabledPlus={item.cantidad === item.por_recibir}
                onClickMinus={() => rest(item.treebesmdaid)}
                onClickPlus={() => add(item.treebesmdaid)}
                circleDiameter="48px"
                onChange={(value) => {
                  changeInput(parseFloat(value), item.treebesmdaid);
                }}
                onWarning={item.field === 0}
                onDanger={item.por_recibir > item.cantidad}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  ); };

ReceiverTransferProducts.propTypes = {
  products: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  rest: PropTypes.func,
  add: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

ReceiverTransferProducts.defaultProps = {
  products: [],
  loading: false,
  changeInput: () => {},
  rest: () => {},
  add: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default ReceiverTransferProducts;
