/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
import React from 'react';
import {
  IconButton,
  List, ListItem, ListItemIcon, ListItemText, Paper,
} from '@material-ui/core';
import {
  Edit,
  ListAltSharp, PersonAdd, ShoppingCart, Visibility,
} from '@material-ui/icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexMenu from '../../../headless-menu/FlexMenu';
import { openModal, toggleModal } from '../../../../../redux/actions/modalActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CustomerAPI from '../../../../../api/CustomerAPI';

const CartClientMenu = () => {
  const dispatch = useDispatch();
  const domain = useSelector(state => state.authUser.domain);
  const cart = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const { orderSelected } = cart;
  const { selectedCustomer } = customer;

  const createClient = () => {
    dispatch(openModal('customerCreate'));
  };

  const editCustomer = () => {
    dispatch(toggleModal('customerEdit'));
  };

  const camposPersonalizados = () => {
    dispatch(openModal('camposPersonalizados'));
  };

  const changeDefaultCart = async () => {
    if (selectedCustomer.crmid === '54572') {
      toast.warn('A Publico en General no se le asigna carrito por defecto');
      return;
    }
    const customerApi = new CustomerAPI();
    const editedCustomer = {
      ...selectedCustomer,
      cart_default: orderSelected.crmid,
    };
    const response = await customerApi.edit(editedCustomer);

    if (response.success) {
      toast.success('Carrito por defecto actualizado');
    } else {
      toast.error('No se actualizo el carrito por defecto');
    }
  };

  const goToCrm = () => {
    const url = `https://${domain}/index.php?module=Accounts&view=Detail&record=${selectedCustomer.crmid}`;
    window.open(url, '_blank');
  };

  return (
    <FlexMenu>
      <FlexMenu.Trigger
        as={IconButton}
        aria-label="Menu"
        style={{
          borderRadius: '5px',
          height: 40,
          border: '1px solid #b8b8b8',
        }}
      >
        <FontAwesomeIcon icon={faUser} style={{ fontSize: '16px' }} />
      </FlexMenu.Trigger>
      <FlexMenu.OptionList as={Paper} style={{ width: 230 }}>
        <List component="nav">
          <ListItem button onClick={createClient}>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            <ListItemText primary="Crear Cliente" />
          </ListItem>
          <ListItem button onClick={editCustomer} disabled={!Object.keys(selectedCustomer).length || selectedCustomer?.crmid === '54572'}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Editar Cliente" />
          </ListItem>
          <ListItem button onClick={changeDefaultCart} disabled={!cart.products.length}>
            <ListItemIcon>
              <ShoppingCart />
            </ListItemIcon>
            <ListItemText primary="Carrito default" />
          </ListItem>
          <ListItem button onClick={goToCrm}>
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText primary="Ver en sistema" />
          </ListItem>
          <ListItem button onClick={camposPersonalizados} disabled={!Object.keys(selectedCustomer).length}>
            <ListItemIcon>
              <ListAltSharp />
            </ListItemIcon>
            <ListItemText primary="Campos" />
          </ListItem>
        </List>
      </FlexMenu.OptionList>
    </FlexMenu>
  ); };

export default CartClientMenu;
