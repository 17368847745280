/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

/* Temporal */
import { connect, useDispatch, useSelector } from 'react-redux';
import { addDiscount, removeDiscount } from '../../../redux/actions/discountActions';
import { openModal, closeModal } from '../../../redux/actions/modalActions';
import { editOrder } from '../../../redux/actions/sellActions';
import { setDiscount, updateTotals } from '../../../redux/actions/cartActions';

// import { cartProps } from '../../../propTypes/cartProps';
import discountProps from '../../../propTypes/discountProps';

import { formatMoney } from '../../../helpers/tools';

import Discount from '../../Discount';

import PosCartContainerAction from '../../../views/Pos/components/PosCartContainerAction';
import { calculateTotals } from '../../../helpers/cart';
import PosCartContainerActionV2 from '../../../views/Pos/components/PosCartContainerAction/PosCartContainerActionV2';

const getTotal = (discount, cart) => {
  let { total } = cart;
  if (discount.haveDiscount) {
    total = (total).toFixed(2);
    total = Number.parseFloat(total);
  }
  return formatMoney(total);
};

const CartTotal = ({
  tabs,
  cart,
  order,
  loads,
  modals,
  keyBoard,
  discount,
  isCollapse,
  toggleCollapse,
  dispatchEditOrder,
  dispatchSetDiscount,
  dispatchAddDiscount,
  dispatchRemoveDiscount,
  dispatchOpenDiscountModal,
  dispatchCloseDiscountModal,
  history,
}) => {
  const dispatch = useDispatch();
  const { posTypeTab } = tabs;
  const { orderSelected, totalProducts, cartProductsByArray } = cart;
  const { enableEditOrder } = order;
  const enableSaveEdit = Boolean(orderSelected && enableEditOrder);
  const user = useSelector(state => state.authUser.user);
  const { currencies, taxes } = user;

  const ctrlPressed = Boolean(keyBoard.ctrlButton);
  const generalLoading = Boolean(loads.orderIsLoading);
  const generalTotal = (Number.parseFloat(cart.total) - Number.parseFloat(getTotal(cart, discount)));
  const withDiscount = (Number.parseFloat(cart.total));
  const generalBalance = (withDiscount - Number.parseFloat(cart.pagado));
  const enableDeleteDiscount = Boolean(posTypeTab === 'Vender' || (enableSaveEdit));

  const getOrderCurrency = () => {
    let currencyCode = '';
    if (orderSelected && orderSelected.order) {
      const currencyId = orderSelected.order.currency_id.split('x').pop();
      const orderCurrency = currencies.find(c => c.id === currencyId);
      if (orderCurrency) {
        currencyCode = orderCurrency.currency_code;
        return currencyCode;
      }
    } else {
      const currencyId = localStorage.getItem('currencyId');
      const orderCurrency = currencies.find(c => c.id === currencyId);
      if (orderCurrency) {
        currencyCode = orderCurrency.currency_code;
        return currencyCode;
      }
    }
    return currencyCode;
  };

  const getTaxLabel = (taxName) => {
    const tax = taxes.find(t => t.taxname === taxName);

    return tax?.taxlabel || null;
  };

  React.useEffect(() => {
    dispatch(updateTotals());
  }, [discount]);

  return (
    <Collapse in={(!generalLoading && totalProducts > 0)}>
      <div className="cartTotal_container py-2 px-2">
        <div className="cartTotal_details" style={{borderRadius: 8, overflow: 'hidden'}}>
          <div className="payment" style={{ backgroundColor: '#F6F6F7' }}>
            <div className="payment__mount">
              <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                <div
                  className="payment__sub-total w-100 d-flex justify-content-between items-center"
                >
                  <span>Peso</span>
                  <span style={{ color: '#23A1DB' }}>
                    {`${cart.totalWeight} Kg`}
                  </span>
                </div>

                <Collapse in={discount.haveDiscount}>
                  <h5 className="payment__sub-total d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      className="payment__discount-delete"
                      onClick={dispatchRemoveDiscount}
                      disabled={!enableDeleteDiscount}
                    >
                      <DeleteForeverIcon
                        color={!enableDeleteDiscount ? 'disabled' : 'primary'}
                      />
                    </button>
                    {`Desc. -${discount.fixed ? formatMoney(discount.fixed) : ''} ${(discount.percentage !== 0) ? (`${discount.percentage}%: $${discount.total.toFixed(2)}`) : ('')}`}
                  </h5>
                </Collapse>

                <div className="payment__sub-total w-100 d-flex justify-content-between items-center">
                  <span>Sub-total:</span>
                  <span style={{ color: '#23A1DB' }}>
                    {`$${formatMoney(cart.subTotal)}`}
                  </span>
                </div>

                {
                  cart.totalIEPS !== 0 && (
                    <div className="payment__sub-total w-100 d-flex justify-content-between items-center">
                      <span>{`${getTaxLabel('tax2') || 'IEPS'}:`}</span>
                      <span style={{ color: '#23A1DB' }}>{`$${cart.totalIEPS ? formatMoney(cart.totalIEPS) : 0}`}</span>
                    </div>
                  )
                }

                {
                  cart.totalISR !== null && cart.totalISR !== 0 && (
                    <div className="payment__sub-total w-100 d-flex justify-content-between items-center">
                      <span>{`${getTaxLabel('tax3') || 'ISR'}:`}</span>
                      <span style={{ color: '#23A1DB' }}>{`$${cart.totalISR ? formatMoney(cart.totalISR) : 0}`}</span>
                    </div>
                  )
                }

                {
                  cart.totalIvaRet !== 0 && (
                    <div className="payment__sub-total w-100 d-flex justify-content-between items-center">
                      <span>{`${getTaxLabel('tax4') || 'IVA-RET'}:`}</span>
                      <span style={{ color: '#23A1DB' }}>{`$ -${cart.totalIvaRet ? formatMoney(cart.totalIvaRet) : 0}`}</span>
                    </div>
                  )
                }

                {
                  cart.totalIsrRet !== 0 && (
                    <div className="payment__sub-total w-100 d-flex justify-content-between items-center">
                      <span>{`${getTaxLabel('tax5') || 'ISR-RET'}:`}</span>
                      <span style={{ color: '#23A1DB' }}>{`$ -${cart.totalIsrRet ? formatMoney(cart.totalIsrRet) : 0}`}</span>
                    </div>
                  )
                }

                {
                  cart.totalIva !== 0 && (
                    <div className="payment__sub-total w-100 d-flex justify-content-between items-center">
                      <span>{`${getTaxLabel('tax1') || 'IVA'}:`}</span>
                      <span style={{ color: '#23A1DB' }}>{`$${formatMoney(cart.totalIva)}`}</span>
                    </div>
                  )
                }

                {/* {cart.anotherTaxes !== 0 && (
                  <h5 className="payment__sub-total">
                    {`Otros impuestos: $${formatMoney(cart.anotherTaxes)}`}
                  </h5>
                )} */}

                {parseFloat(cart.balance) > 1 && (
                  <div className="payment__sub-total w-100 d-flex justify-content-between items-center" style={{ fontWeight: 'bold' }}>
                    <span>por Cobrar:</span>
                    <span style={{ color: '#23A1DB' }}>{`$${formatMoney(cart.balance < 1 ? (generalTotal) : (generalBalance))}`}</span>
                  </div>
                )}
                <div className="w-100 py-1">
                  <Divider />
                </div>
              </Collapse>

              <div className="payment__total-container">
                <div className="w-100" style={{ position: 'relative' }}>
                  <div
                    style={{ position: 'absolute', top: '-8px' }}
                    className="w-100 d-flex justify-content-center item-center"
                    onClick={() => { toggleCollapse(!isCollapse); }}
                  >
                    {
                        isCollapse
                          ? <KeyboardArrowDownIcon height="18px" />
                          : <KeyboardArrowUpIcon height="18px" />
                      }
                  </div>
                  <div
                    className="payment__total w-100 d-flex justify-content-between"
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <div>
                        {/* <small className="d-block" style={{ fontSize: '0.6rem' }}>
                          {getOrderCurrency()}
                          {' '}
                        </small> */}
                        <span style={{ fontSize: '1rem' }}>Total: </span>
                      </div>
                      <span style={{ fontSize: 19, color: '#23A1DB' }}>
                        {`$ ${getTotal(discount, cart)}`}
                        {' '}
                        <span>{getOrderCurrency()}</span>
                        <span />
                      </span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 h-100 d-flex flex-column justify-content-end align-items-center ml-2">
          <Collapse in={(posTypeTab === 'Vender' && !enableSaveEdit) && isCollapse}>
            {posTypeTab === 'Vender' && (
              <Discount
                isOpen={Boolean(modals.discountModalIsOpen)}
                onOpen={dispatchOpenDiscountModal}
                onClose={dispatchCloseDiscountModal}
                isLoading={false}
                onSave={(data) => {
                  dispatchAddDiscount(data);
                  dispatchCloseDiscountModal();
                }}
                products={cart.products}
                totalToPay={cart.total}
                dispatchSetDiscount={dispatchSetDiscount}
              />
            )}
          </Collapse>
          {enableSaveEdit ? (
            <Button
              disabled={totalProducts === 0}
              className="w-100 py-2 mb-2 checkOutButton_saveEdition"
              onClick={
                () => {
                  dispatchEditOrder();
                  // eslint-disable-next-line no-unused-expressions
                  cart.orderSelected.status ? history.push('/pos/ventas') : history.push('/pos/cotizaciones');
                }
              }
            >
              {cart.orderSelected.status ? ('Guardar Orden Editada') : ('Guardar Cotización Editada')}
            </Button>
          ) : (
            /* <PosCartContainerAction ctrlPressed={ctrlPressed} /> */
            <PosCartContainerActionV2 />
          )}
        </div>
      </div>
    </Collapse>
  );
};

CartTotal.propTypes = {
  cart: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
  discount: discountProps.isRequired,
  modals: PropTypes.object.isRequired,
  isCollapse: PropTypes.bool.isRequired,
  keyBoard: PropTypes.object.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchAddDiscount: PropTypes.func.isRequired,
  dispatchSetDiscount: PropTypes.func.isRequired,
  dispatchRemoveDiscount: PropTypes.func.isRequired,
  dispatchOpenDiscountModal: PropTypes.func.isRequired,
  dispatchCloseDiscountModal: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  tabs: state.tabs,
  cart: state.cart,
  order: state.order,
  loads: state.loads,
  modals: state.modals,
  discount: state.discount,
  keyBoard: state.keyBoard,
});

const mapDispatchToProps = dispatch => ({
  dispatchRemoveDiscount: () => (
    dispatch(removeDiscount())
  ),
  dispatchOpenDiscountModal: () => dispatch(openModal('discount')),
  dispatchCloseDiscountModal: () => dispatch(closeModal('discount')),
  dispatchAddDiscount: discount => dispatch(addDiscount(discount)),
  /* EditOrder */
  dispatchEditOrder: () => dispatch(editOrder()),
  /* Cart */
  dispatchSetDiscount: (percentage, show) => {
    dispatch(setDiscount(percentage, show));
    dispatch(closeModal('discount'));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartTotal));
