/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { faCartArrowDown, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {
  shouldCharge,
  shouldDeliver,
} from '../../../../helpers/order';
import DropUpButton from '../DropUpButton';

const VentaContainerListOption = ({ selectOption }) => {
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const { pos_botonventas } = authUser.user.config;
  const { orderSelected, totalProducts } = cart;
  const almacen = orderSelected.cartStateWarehouse || '';
  const productsInOrder = Boolean(totalProducts > 0);
  const mostrarHistorico = pos_botonventas !== undefined ? pos_botonventas : 'Ambas';

  return (
    <div className="w-100 h-100 d-flex justify-content-around align-items-end flex-column">
      {shouldDeliver(almacen)
        && productsInOrder
        && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Entregar') && (
          <div className="w-100">
            <DropUpButton
              title="Entregar"
              color="#70BBFD"
              bordered={false}
              icon={faCartArrowDown}
              onClick={() => selectOption('DeliverHistorico')}
            />
          </div>
      )}
      {shouldCharge(orderSelected?.balance)
        && productsInOrder
        && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Cobrar') && (
          <div className="w-100">
            <DropUpButton
              title="Cobrar"
              bordered={false}
              icon={faHandHoldingUsd}
              onClick={() => selectOption('CobrarHistorico')}
            />
          </div>
      )}
      {/* {shouldRefoundMoney(orderSelected?.balance, orderSelected?.total) && (
        <DropUpButton title="Devolver Dinero" icon={faCartArrowDown} onClick={() => selectOption('DevolverDinero')} />
      )}
      {shouldRefoundMoney(orderSelected?.balance, orderSelected?.total) && orderExist && (
        <DropUpButton title="Devolver Productos" icon={faCartArrowDown} onClick={() => selectOption('DevolverProductos')} />
      )} */}
    </div>
  );
};

VentaContainerListOption.propTypes = {
  selectOption: PropTypes.func.isRequired,
};

export default VentaContainerListOption;
