import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FdeAPI from '../../../../../api/FdeAPI';
import BankCheckOut from '../../../../CheckOut/_components/constructor/components/BankCheckOut';
import MethodCheckOut from '../../../../CheckOut/_components/constructor/components/MethodCheckOut';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const EditFde = ({
  isOpen, handleClose, fde, updatedFde,
}) => {
  /* Payment selected */
  const [payment, setPayment] = React.useState(null);

  /* Bank selected */
  const [bank, setBank] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);


  const selectPaymentMethod = (pay) => {
    setPayment(pay);
    try {
      if (pay.body && pay.body.length === 1) {
        setBank(pay.body[0]);
      } else {
        setBank(null);
      }
    } catch (error) {
      toast.error('Error seleccionando el banco o caja');
    }
  };


  const selectBank = async (banc) => {
    // await setBank(null);
    await setBank(banc);
  };

  const editFde = async () => {
    setIsLoading(true);
    toast.success('Actualizando flujo de efectivo');
    const fdeApi = new FdeAPI();
    const editFieldsResponse = await fdeApi.getFdeById(fde.crmid);
    if (editFieldsResponse.success) {
      const fdeById = editFieldsResponse.result;

      const editedFde = {
        ...fdeById,
        cuenta_bancaria: bank.crmid,
        currency_id: fde.currency_id,
        currency_id2: fde.currency_id2,
        tfde_tipo: fde.tfde_tipo,
        monto: fde.monto,
        tfde_metodo: payment.method,
        fecha_realizado: fde.fecha_realizado,
        conversion_rate: fde.conversion_rate,
        crmid: fde.crmid,
        cuenta: bank.referencia || bank.entity_label,
      };

      const response = await fdeApi.put(editedFde);
      const { success, result } = response;

      if (success && result) {
        updatedFde(result);
        toast.success('Flujo de efectivo actualizado');
        setBank(null);
        setPayment(null);
      } else {
        toast.success('Error actualizando flujo de efectivo');
      }

      setIsLoading(false);
      handleClose();
    } else {
      setIsLoading(false);
      toast.error('Error obteniendo los datos del flujo de efectivo');
    }
  };


  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Editar flujo
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <MethodCheckOut payment={payment} selectPayment={pay => selectPaymentMethod(pay)} />
          {
        payment && (
          <BankCheckOut payment={payment} bank={bank} setBank={banc => selectBank(banc)} setPayIndex={() => {}} currency={fde.currency_code} />
        )
      }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={isLoading} onClick={editFde} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditFde.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fde: PropTypes.object.isRequired,
  updatedFde: PropTypes.func.isRequired,
};

export default EditFde;
