/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slider';
import './price-styles.scss';
import { useRange } from 'react-instantsearch';
import PropTypes from 'prop-types';

const PriceSlider = ({ attribute, symbol = '', position = 'front' }) => {
  const {
    start, range, refine,
  } = useRange({ attribute });
  const { min, max } = range;
  const [values, setValues] = useState([min, max]);
  const handleChange = (newValues) => {
    setValues(newValues);
  };
  const handleAfterChange = () => {
    refine(values);
  };


  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

  useEffect(() => {
    setValues([from, to]);
  }, [from, to]);

  return (
    <div style={{
      padding: '10px',
    }}
    >
      <Slider
        value={values}
        onChange={handleChange}
        onAfterChange={handleAfterChange}
        min={min}
        max={max}
        className="slider"
        // thumbClassName="example-thumb"
        trackClassName="example-track"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div>
          {symbol && position === 'front' && <span>{symbol}</span>}
          <span>{values[0]}</span>
          {symbol && position === 'back' && <span>{symbol}</span>}
        </div>
        <div>
          {symbol && position === 'front' && <span>{symbol}</span>}
          <span>{values[1]}</span>
          {symbol && position === 'back' && <span>{symbol}</span>}
        </div>
      </div>
    </div>
  );
};

PriceSlider.propTypes = {
  attribute: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  position: PropTypes.string,
};

PriceSlider.defaultProps = {
  symbol: '',
  position: 'front',
};

export default PriceSlider;
