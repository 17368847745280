import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle } from '@material-ui/core';
import TicketsActions from '../../../Cart/components/controls/components/TicketsActions';
import TicketFacturaAction from '../../../Cart/components/controls/components/TicketFacturaAction';
import { setTicketData } from '../../../../redux/actions/ticketAction';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';
import { printTicketFactura } from '../../../../redux/actions/TimbrarActions';

const PrintOptionModal = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const loads = useSelector(state => state.loads);
  const tabs = useSelector(state => state.tabs);
  const modals = useSelector(state => state.modals);
  const customer = useSelector(state => state.customer);
  const ventaTooltip = 'Ticket de Venta';
  const surtidoTooltip = 'Ticket de Presurtido';

  const {
    totalProducts, orderSelected, products, subTotal, totalIva, description,
  } = cart;
  const { posTypeTab } = tabs;
  const { selectedCustomer } = customer;
  /* Enables and Flags */
  const existProducts = Boolean(totalProducts > 0);
  const sellTicket = Boolean(posTypeTab === 'Historico');
  const treebessatcfdiid = (orderSelected) ? (orderSelected.treebessatcfdiid) : ('');
  const enableFactura = Boolean(treebessatcfdiid !== '');

  const handleClose = () => {
    dispatch(closeModal('printOption'));
  };

  const handleOpen = async () => {
    switch (posTypeTab) {
      case 'Historico':
        await dispatch(setTicketData(selectedCustomer, orderSelected.order, products, subTotal, totalIva, description || '', 'order'));
        break;
      case 'Vender':
        await dispatch(setTicketData(selectedCustomer, {}, products, subTotal, totalIva, description || '', 'product'));
        break;
      case 'Cotizaciones':
        await dispatch(setTicketData(selectedCustomer, orderSelected.order, products, subTotal, totalIva, description || '', 'quote'));
        break;
      default:
        break;
    }
    dispatch(openModal('tickets'));
    handleClose();
  };

  const dispatchOpenBill = async (bill) => {
    await dispatch(printTicketFactura(bill));
  };


  return (
    <Dialog open={modals.printOptionModalIsOpen} onClose={handleClose} aria-labelledby="simple-dialog-title">
      <DialogTitle id="simple-dialog-title">Seleccione opción de impresión</DialogTitle>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        padding: '15px',
      }}
      >
        <TicketsActions
          open={() => handleOpen()}
          enable={existProducts}
          tooltip={sellTicket ? ventaTooltip : surtidoTooltip}
        />
        <TicketFacturaAction
          open={() => {
            dispatchOpenBill(treebessatcfdiid);
            handleClose();
          }}
          enable={enableFactura}
          loading={Boolean(loads.timbrarIsLoading)}
        />
      </div>
    </Dialog>
  );
};

export default PrintOptionModal;
