/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import DeliverDialog from './components/DeliverDialog';
import { closeModal } from '../../redux/actions/modalActions';
import DropUpButton from '../rkr2-components/cart-sell-actions/DropUpButton';

const DeliverV2 = ({
  title,
  table,
  loads,
  isOpen,
  onOpen,
  onClose,
  authUser,
  isLoading,
  onSave,
  disabled,
  getOrderDocument,
  deliverEnableAction,
  dispatchSelectEmpty,
  dispatchChangeComment,
  dispatchChangeFieldValue,
  dispatchSelectToRefunded,
  dispatchSelectToDelivered,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSelector(state => state.cart);
  const config = useSelector(state => state.authUser.user.config);
  const { pos_no_entregar } = config;
  const noEntregar = Boolean(pos_no_entregar === '1');
  const canDelivery = noEntregar ? true : disabled;
  const {
    orderSelected: { crmid },
  } = cart;

  const handleDeliver = async () => {
    dispatch(closeModal('cart'));
    history.push(`/almacenes/entregar/${crmid}`);
  };

  return (
    <div className="deliver_container w-100 h-100 d-flex align-items-end">
      <DropUpButton
        title="Entregar"
        color="#70BBFD"
        icon={faCartArrowDown}
        onClick={!canDelivery ? handleDeliver : () => {}}
        disabled={canDelivery}
      />
      <DeliverDialog
        title={title}
        table={table}
        loads={loads}
        onSave={onSave}
        isOpen={isOpen}
        onClose={onClose}
        authUser={authUser}
        isLoading={isLoading}
        getOrderDocument={getOrderDocument}
        deliverEnableAction={deliverEnableAction}
        dispatchSelectEmpty={dispatchSelectEmpty}
        dispatchChangeComment={dispatchChangeComment}
        dispatchChangeFieldValue={dispatchChangeFieldValue}
        dispatchSelectToRefunded={dispatchSelectToRefunded}
        dispatchSelectToDelivered={dispatchSelectToDelivered}
      />
    </div>
  );
};

DeliverV2.propTypes = {
  title: PropTypes.string,
  table: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  authUser: PropTypes.object.isRequired,
  getOrderDocument: PropTypes.func.isRequired,
  deliverEnableAction: PropTypes.bool.isRequired,
  dispatchSelectEmpty: PropTypes.func.isRequired,
  dispatchChangeComment: PropTypes.func.isRequired,
  dispatchChangeFieldValue: PropTypes.func.isRequired,
  dispatchSelectToRefunded: PropTypes.func.isRequired,
  dispatchSelectToDelivered: PropTypes.func.isRequired,
};

DeliverV2.defaultProps = {
  title: 'Entrega a Cliente',
};

export default DeliverV2;
