/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Slide,
  Tooltip,
} from '@material-ui/core';
import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import InputNumber from '../../../Input/Number';
import useSound from '../../../../hooks/sound/useSound';
import Lotes from '../../lotes';
import ProductAPI from '../../../../api/ProductAPI';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const DeliverOrderProducts = ({
  stock,
  products,
  changeInput,
  rest,
  add,
  loading,
  selectAll,
  selectNone,
  updateLotes,
  updateBarCode,
  checkParcialidad,
}) => {
  const inputRef = useRef(null);
  const authUser = useSelector(state => state.authUser);
  const [open, setOpen] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [scannedProduct, setScannedProduct] = useState(null);
  const [changeScan, setChangeScan] = useState(false);
  const { playSound } = useSound();
  const productApi = new ProductAPI();
  const scrolledActionEvent = new Event('scrolledAction');
  const { pos_bloquea_entregar_todo } = authUser.user.config;

  const getPartidas = () => {
    let count = 0;
    products.map((item) => {
      if (item.por_entregar !== 0) {
        count += 1;
      }
    });

    return count;
  };


  const getTotalCantidad = () => {
    let count = 0;
    count = products.reduce((acc, item) => acc + parseFloat(item['por entregar']), 0);

    return count;
  };

  const addBarCode = async (product) => {
    await setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedProduct(null);
    setOpen(false);
  };

  const sendBarCode = async () => {
    setIsLoading(true);
    const response = await productApi.addProductBarCode(
      selectedProduct.id,
      inputRef.current.value,
    );
    const { success } = response;
    if (success) {
      // Update product in the list
      updateBarCode(selectedProduct, inputRef.current.value);
      setOpen(false);
      setSelectedProduct(null);
      inputRef.current.value = '';
      toast.success('Código de barras agregado');
    } else {
      toast.error('No se pudo agregar el código de barra');
    }
    setIsLoading(false);
  };

  const getCantidad = () => {
    let count = 0;
    count = products.reduce((acc, item) => acc + item.por_entregar, 0);

    return count;
  };

  const processLotesSeries = (inputSelected, barCode) => {
    let shouldEnd = true;
    const findIndex = products.findIndex(
      prod => prod.id === inputSelected,
    );
    if (findIndex !== -1) {
      // Product
      const item = products[findIndex];
      const isLote = item.pl_products_batch_status === 'pl_products_batch_status_lot';
      item.TreebesLotes.map((l) => {
        if (l.number === barCode) {
          if (isLote) {
            if (l.qty + 1 > l.qtyinstock) {
              toast.warn('Ha alcanzado la cantidad maxima del stock del lote');
            } else {
              l.qty += 1;
              shouldEnd = false;
            }
          } else if (l.qty === 0) {
            l.qty += 1;
            shouldEnd = false;
          } else {
            localStorage.setItem('barCode', '');
            setScannedProduct(item);
            toast.warn('La serie fue previamente escaneada');
            shouldEnd = true;
          }
        }
      });
      if (shouldEnd) {
        localStorage.setItem('barCode', '');
        setScannedProduct(item);
        return;
      }
      localStorage.setItem('barCode', '');
      setScannedProduct(item);
      if (parseFloat(item['por entregar']) >= item.por_entregar + 1) {
        add(item.id);
        playSound('success');
        toast.success(`${isLote ? 'Lote' : 'Serie'} seleccionado`);
        localStorage.setItem('barCode', '');
      } else {
        playSound('error');
        toast.warn('Cantidad máxima agregada');
        localStorage.setItem('barCode', '');
      }
    } else {
      localStorage.setItem('barCode', '');
    }
  };

  const handleReadBarCode = (e) => {
    const barCodes = products.map(p => p.codigo_barras || '');
    const barCode = localStorage.getItem('barCode') || '';
    const inputSelected = localStorage.getItem('selectedInput') || '';

    if (e.keyCode === 13 && barCode && products.length) {
      // Tiene lotes o series
      if (inputSelected && !barCodes.includes(barCode)) {
        const findIndex = products.findIndex(
          prod => prod.id === inputSelected,
        );
        if (findIndex !== -1) {
          const item = products[findIndex];
          const isNotLote = item.pl_products_batch_status === 'pl_products_batch_status_no';

          if (isNotLote) {
            // Not found
            playSound('invalid');
            toast.error('No se encontró el producto');
            localStorage.setItem('barCode', '');
            return;
          }
          processLotesSeries(inputSelected, barCode);
          return;
        }
        // Not found
        playSound('invalid');
        toast.error('No se encontró el producto');
        localStorage.setItem('barCode', '');
        return;
      }

      const findIndex = products.findIndex(
        prod => prod.codigo_barras === barCode,
      );
      if (findIndex !== -1) {
        // Product
        const item = products[findIndex];
        localStorage.setItem('scanedProductId', item.id);
        document.dispatchEvent(scrolledActionEvent);
        localStorage.setItem('selectedInput', item.id);
        // Si el producto trae lotes o series no hace nada
        if (item.TreebesLotes && item.TreebesLotes.length) {
          localStorage.setItem('barCode', '');
          localStorage.setItem('selectedInput', '');
          setScannedProduct(item);

          return;
        }

        if (parseFloat(item['por entregar']) >= item.por_entregar + 1) {
          add(item.id);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          setChangeScan(!changeScan);
          playSound('error');
          toast.warn('Cantidad máxima agregada');
          localStorage.setItem('barCode', '');
        }
      } else {
        localStorage.setItem('scanedProductId', '');
        playSound('invalid');
        toast.error('No existe el codigo');
        localStorage.setItem('barCode', '');
      }
    } else if (
      e.key !== 'Enter'
      && e.key !== 'Shift'
      && e.key !== 'Backspace'
      && e.key !== 'CapsLock'
    ) {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  const scanedCompleted = () => {
    const total = getTotalCantidad();
    const parcial = getCantidad();

    if (total && parcial && total <= parcial) {
      setOpenCompleted(true);
    }
  };


  const addRestItem = (id, action) => {
    if (action === 'add') {
      add(id);
    }
    if (action === 'rest') {
      rest(id);
    }
    localStorage.setItem('scanedProductId', id);
    document.dispatchEvent(scrolledActionEvent);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCode', '');
    scanedCompleted();

    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [products]);

  useEffect(() => {
    document.addEventListener('scrolledAction', () => {
      const id = localStorage.getItem('scanedProductId');
      const element = document.getElementById(`${id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
      return () => {
        document.removeEventListener('scrolledAction', null, false);
        localStorage.setItem('scanedProductId');
        localStorage.setItem('selectedInput', '');
      };
    });
  }, []);


  return (
    <div className="deliver__products" style={{ height: '100%', margin: 20 }}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center mr-2">
            <span className="mr-2">
              Partidas:
              {' '}
              {getPartidas()}
            </span>
            <span>
              Cantidad:
              {' '}
              {getCantidad()}
            </span>
          </div>
          <Button
            disabled={loading || pos_bloquea_entregar_todo === '1'}
            className="mr-1"
            variant="outlined"
            onClick={selectAll}
          >
            Entregar todo
          </Button>
          <Button disabled={loading || pos_bloquea_entregar_todo === '1'} variant="outlined" onClick={selectNone}>
            Entregar nada
          </Button>
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <div className="w-100 product-list">
        {products.length > 0
          && products.map((item, index) => (
            <div
              id={item.id}
              style={{
                marginBottom: 10,
                border:
                  localStorage.getItem('scanedProductId') === `${item?.id}`
                    ? '8px solid red'
                    : 'inherit',
              }}
            >
              <div
                className={`${item.clase} refund_product`}
                key={item.id}
                style={{
                  border:
                    stock.length && stock[index]?.inventario < item.por_entregar
                      ? '2px solid red'
                      : 'white',
                }}
              >
                <div className="refund_product_stock">
                  {stock.length ? stock[index].inventario : 0}
                  <span>Stock</span>
                </div>
                <div className="refund_product_detail">
                  <div className="refund_product_title">
                    <Tooltip placement="left-start" title={item.producto || ''}>
                      <span>{item.producto}</span>
                    </Tooltip>
                  </div>
                  <div className="refund_product_desc">
                    {`Por Entregar: ${parseFloat(item['por entregar'])}`}
                  </div>
                  <div className="refund_product_desc">
                    {`codigo: ${item.codigo}`}
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mb-2">
                  <div>
                    <span>Código de barra</span>
                  </div>
                  <input
                    className="scanInput"
                    type="text"
                    readOnly
                    value={item.codigo_barras}
                  />
                  {!item.codigo_barras && (
                    <button
                      onClick={() => addBarCode(item)}
                      style={{
                        border: '1px solid gray',
                        borderRadius: 10,
                        width: '100%',
                        marginTop: 2,
                      }}
                      type="button"
                    >
                      Adicionar código
                    </button>
                  )}
                </div>
                <div className="refund_product_qty">
                  <div>
                    <span style={{ whiteSpace: 'nowrap', marginRight: 15 }}>
                      {`Cantidad: ${parseFloat(item['por entregar'])}`}
                    </span>
                  </div>
                  <InputNumber
                    number={
                      stock.length && stock[index].inventario > 0
                        ? item.por_entregar
                        : 0
                    }
                    disabledMinus={
                      parseFloat(item['por entregar']) === 0
                      || item.por_entregar === 0
                      || (stock.length > 0 && stock[index].inventario <= 0)
                      || (item.TreebesLotes && item.TreebesLotes.length > 0)
                    }
                    disabledPlus={
                      parseFloat(item['por entregar']) === item.por_entregar
                      || parseFloat(item['por entregar']) === 0
                      || (stock.length > 0 && stock[index].inventario <= 0)
                      || (item.TreebesLotes && item.TreebesLotes.length > 0)
                    }
                    onClickMinus={() => addRestItem(item.id, 'rest')}
                    onClickPlus={() => addRestItem(item.id, 'add')}
                    circleDiameter="48px"
                    onChange={(value) => {
                      changeInput(parseFloat(value), item.id);
                    }}
                    onWarning={item.field === 0}
                    onDanger={item.por_entregar > item.cantidad}
                  />
                </div>
              </div>
              {item.TreebesLotes && item.TreebesLotes.length > 0 && (
                <Lotes
                  lotes={item.TreebesLotes}
                  product={item}
                  updateLotes={updateLotes}
                  scannedProduct={scannedProduct}
                  selectedProduct={selectedProduct}
                />
              )}
            </div>
          ))}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title">
          Adicionar código de barra
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 15,
            }}
          >
            <div>
              <p style={{ fontSize: 15 }}>Producto:</p>
              <p style={{ fontSize: 14 }}>{selectedProduct?.producto}</p>
            </div>
            <div>
              <p style={{ fontSize: 15 }}>Código barra:</p>
              <div
                style={{
                  border: '1px solid #d8d8d8',
                  borderRadius: 5,
                  display: 'flex',
                  overflow: 'hidden',
                }}
              >
                <input
                  style={{
                    width: '100%',
                    height: 36,
                    border: 0,
                    paddingLeft: 10,
                    outline: 'none',
                  }}
                  disabled={isLoading}
                  ref={inputRef}
                  type="text"
                  placeholder="Agrega el código de barra"
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <Collapse in style={{ width: '100%' }}>
              <LinearProgress />
            </Collapse>
          ) : (
            <>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button onClick={sendBarCode} color="primary">
                Agregar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCompleted}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenCompleted(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Entrega Completa
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ha terminado de seleccionar todos los productos disponibles, desea terminar?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setOpenCompleted(false)} color="primary">
            {/* <img src={noImage} alt="No" /> */}
            No
          </Button>
          <Button
            onClick={() => {
              checkParcialidad();
              setOpenCompleted(false);
            }}
            color="primary"
          >
            {/* <img src={siImage} alt="Si" /> */}
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeliverOrderProducts.propTypes = {
  stock: PropTypes.array,
  products: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  rest: PropTypes.func,
  add: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

DeliverOrderProducts.defaultProps = {
  stock: [],
  products: [],
  loading: false,
  changeInput: () => {},
  rest: () => {},
  add: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default DeliverOrderProducts;
