/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import EventAvailable from '@material-ui/icons/EventAvailable';
import LinkIcon from '@material-ui/icons/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faClipboardCheck, faBoxes } from '@fortawesome/free-solid-svg-icons';

import { NavLink } from 'react-router-dom';

const LayoutMenu = ({
  theme,
  classes,
  logoname,
  organizationname,
  closeDashboardMenu,
  dashboardMenuIsDisplayed,
}) => {
  const authUser = useSelector(state => state.authUser);
  const { domain, user } = authUser;
  const { config } = user;
  const {
    deshabilitar_venta,
    deshabilitar_clientes,
    deshabilitar_notas_de_compra,
    deshabilitar_citas,
    deshabilitar_actividades,
    deshabilitar_almacenes,
    // deshabilitar_envios,
  } = config;

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={dashboardMenuIsDisplayed}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={closeDashboardMenu}
    >
      <div className={classes.drawerHeader}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={closeDashboardMenu}
          style={{ cursor: 'pointer' }}
          className="w-100 d-flex align-items-center justify-content-center"
          onClick={closeDashboardMenu}
        >
          <img
            src={logoname || ''}
            alt={organizationname || ''}
            style={{ width: 'auto', height: '40px' }}
          />
        </div>
      </div>
      <Divider />
      <List>
        {/* <NavLink
          to="/tablero"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Tablero" />
          </ListItem>
        </NavLink> */}
        {
          deshabilitar_venta !== '1' && (
            <NavLink
              to="/pos"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Venta" />
              </ListItem>
            </NavLink>
          )
        }

        {
          deshabilitar_clientes !== '1' && (
            <NavLink
              to="/clients"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
              </ListItem>
            </NavLink>
          )
        }

        {
          deshabilitar_notas_de_compra !== '1' && (
            <NavLink
              to="/expenses"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faWallet} />
                </ListItemIcon>
                <ListItemText primary="Gastos" />
              </ListItem>
            </NavLink>
          )
        }

        {
          deshabilitar_citas !== '1' && (
            <NavLink
              to="/citas"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <EventAvailable />
                </ListItemIcon>
                <ListItemText primary="Citas" />
              </ListItem>
            </NavLink>
          )
        }

        {
          config.pos_only === '0' && (
            <NavLink
              to=""
              onClick={() => {
                window.open(`https://${domain}/index.php`, '_blank');
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="Ir al sistema" />
              </ListItem>
            </NavLink>
          )
        }

        {
          deshabilitar_actividades !== '1' && (
            <NavLink
              to="/actividades"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faClipboardCheck} />
                </ListItemIcon>
                <ListItemText primary="Actividades" />
              </ListItem>
            </NavLink>
          )
        }
        {
          deshabilitar_almacenes !== '1' && (
            <NavLink
              to="/almacenes"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faBoxes} />
                </ListItemIcon>
                <ListItemText primary="Almacenes" />
              </ListItem>
            </NavLink>
          )
        }

        <NavLink
          to="/account/profile"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Configuración" />
          </ListItem>
        </NavLink>
      </List>
    </Drawer>
  ); };

LayoutMenu.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  logoname: PropTypes.string.isRequired,
  organizationname: PropTypes.string.isRequired,
  closeDashboardMenu: PropTypes.func.isRequired,
  dashboardMenuIsDisplayed: PropTypes.bool.isRequired,
};

export default LayoutMenu;
