/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../../../../helpers/tools';
import BankIcon from '../../../../Icons/BankIcon';
import CajaIcon from '../../../../Icons/CajaIcon';

const BankCheckOut = ({
  payment, bank, setBank, setPayIndex, currency = null, rewardsPoints = {},
}) => {
  const [bankList, setBankList] = useState([]);
  const authUser = useSelector(state => state.authUser);
  const { user: { config: { store } } } = authUser;
  const optionIcon = {
    Caja: <CajaIcon height={60} width={60} fill={bank?.entity_label === 'Caja' ? '#8252a0' : '#b7bfc6'} />,
    Banco: <BankIcon height={60} width={60} fill={bank?.entity_label === 'Banco' ? '#8252a0' : '#b7bfc6'} />,
    'Puntos Recompensa': <span style={{ fontSize: 35, fill: payment?.method === 'LBL_PUNTOS_RECOMPENSA' ? '#8252a0' : '#b7bfc6' }}>{rewardsPoints ? formatMoney(rewardsPoints?.puntos_saldo) : 0}</span>,
  };

  const handleBankList = () => {
    const bankListAvailable = payment.body.filter((box) => {
      if (currency) {
        return box.currency_code === currency;
      }
      return box;
    }).filter((box) => {
      if (box.almacenes && box.almacenes.length) {
        return box.almacenes.includes(store.crmid);
      }
      return box;
    });
    if (bankListAvailable.length === 1) {
      setBank(bankListAvailable[0]);
    }

    setBankList(bankListAvailable);
  };

  React.useEffect(() => {
    handleBankList();
  }, [payment]);


  return (
    <div style={{ paddingTop: '15px' }}>
      <div>
        <h6>Selecciona banco o caja</h6>
      </div>
      <div className="d-flex" style={{ gap: 10, overflowX: 'auto', padding: 8 }}>
        {bankList.map((banc, index) => (
          <Paper
            elevation={1}
            key={Math.random()}
            style={{
              cursor: 'pointer',
              padding: 10,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: bank?.entity_label === banc.entity_label ? '2px solid #8252a0' : '',
            }}
            onClick={() => {
              if (bank?.entity_label !== banc.entity_label) {
                setBank(banc);
                setPayIndex(index);
              }
            }}
          >
            {optionIcon[`${banc.tcyb_tipo}`]}
            <span style={{ fontSize: 11, textAlign: 'center' }}>
              {banc?.entity_label}
            </span>
          </Paper>
        ))}
      </div>
    </div>
  ); };

export default BankCheckOut;
