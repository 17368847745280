/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import CheckOutModal from './_components/CheckOutModal';
import { openModal } from '../../redux/actions/modalActions';
import GuideApi from '../../api/GuideApi';

const CheckOut = ({
  canSell,
  cart,
  ctrlPressed,
  customer,
  defaultOrderStatus,
  dispatchEditOrder,
  handleMakeBill,
  isLoading,
  isOpen,
  makeBill,
  modalTitle,
  onClose,
  onOpen,
  onSave,
  orderStatuses,
  paymentTypes,
  posCheckoutBill,
  smallButton,
  title,
  toolTipText,
  totalToPay,
  type,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authUser.user);
  const [sellWithStock, setSellWithStock] = React.useState(true);
  const [isVerifyStock, setIsVerifyStock] = React.useState(false);
  const {
    products, cartProductsByArray, orderSelected: { status }, sinStock,
  } = useSelector(state => state.cart);
  const { pos_no_cobrar, store } = user.config;
  const { shipping_id, almacenes, config: { pos_sininv } } = user;
  const noCobrar = Boolean(pos_no_cobrar === '1');
  const canUserSell = noCobrar ? true : !canSell;
  const { defaultCustomer, selectedCustomer } = customer;
  const deliver = useSelector(state => state.shipping?.shippingList);

  const guideApi = new GuideApi();
  const { orderSelected } = cart;

  const verifyStock = async () => {
    const cartProductsIdWidthStock = [];
    setIsVerifyStock(true);

    // Verificando si el producto tiene stock
    cartProductsByArray.forEach(async (prod) => {
      let inStock = {};

      if (!pos_sininv) {
        if (prod.inStock) {
          inStock = { ...prod.inStock };
        } else {
          inStock = {
            inStock: prod.qtyinstock,
            status: !(prod.qtyinstock - prod.quantity < 0),
            sumQty: prod.quantity,
          };
        }
      } else inStock = { status: true, sumQty: prod.quantity || 1, inStock: 10000 };

      cartProductsIdWidthStock.push(inStock);

      if (cartProductsIdWidthStock.length) {
        const noStockProduct = cartProductsIdWidthStock.filter(stock => !stock?.status);
        if (noStockProduct.length) {
          setSellWithStock(false);
        } else {
          setSellWithStock(true);
        }
      }
      setIsVerifyStock(false);
    });
  };

  React.useEffect(() => {
    if (!status || status === 'Creado') {
      verifyStock();
    }
  }, [cartProductsByArray]);

  const openLocalModal = async () => {
    if (selectedCustomer.crmid === defaultCustomer.crmid) {
      onOpen();

      return;
    }
    // const shippingIsSelected = cart.products.findIndex(prod => prod.crmid === shipping_id);
    // Total products
    const productQty = cart.products.filter(p => p.crmid !== shipping_id).reduce((acc, prod) => acc + prod.quantity, 0);
    // Total products with shipping
    const shippingProducts = [];
    deliver.map(p => p.products.map(prod => shippingProducts.push(prod)));
    const totalShippingProducts = shippingProducts.reduce((acc, prod) => acc + prod.quantity, 0);

    if (store.forzaEnvio === '1') {
      const shippingIsSelected = cart.products.findIndex((prod) => {
        let productid = '';
        if (prod.crmid) {
          productid = (prod.crmid && prod.crmid.indexOf('x') !== -1)
            ? prod.crmid.split('x').pop()
            : prod.crmid;
        } else {
          productid = (prod.productid && prod.productid.indexOf('x') !== -1)
            ? prod.productid.split('x').pop()
            : prod.productid;
        }

        return productid === shipping_id;
      });

      // if is Order get guides
      if (orderSelected && orderSelected.order) {
        toast.info('Verificando envíos...');
        const response = await guideApi.getOrderGuides(orderSelected.crmid);
        const { success, result } = response;
        if (!result.length && success) {
          toast.info('Debes seleccionar un envío antes de cobrar');
          dispatch(openModal('shipping'));
          return;
        }
      } else if (shippingIsSelected === -1 && (deliver.length === 0 || totalShippingProducts !== productQty)) {
        if (totalShippingProducts !== productQty) {
          toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
          dispatch(openModal('shipping'));
          return;
        }

        if (Object.keys(orderSelected).length > 0) {
          const response = await guideApi.getOrderGuides(orderSelected.crmid);
          const { success, result } = response;
          if (!result.length && success) {
            toast.info('Debes seleccionar un envío antes de cobrar');
            dispatch(openModal('shipping'));
            return;
          }
        } else {
          toast.info('Debes seleccionar un envío antes de cobrar');
          dispatch(openModal('shipping'));
          return;
        }
      } else if (shippingIsSelected !== -1 && totalShippingProducts !== productQty) {
        toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
        dispatch(openModal('shipping'));
        return;
      }
    }
    onOpen();
  };

  return (
    <>
      {smallButton ? (
        <Tooltip title="Cobrar">
          <IconButton
            onClick={canSell ? openLocalModal : () => {}}
            disabled={!canSell}
          >
            <FontAwesomeIcon icon="hand-holding-usd" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          placement="bottom"
          open={ctrlPressed}
          title={toolTipText}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
        >
          <div className="w-100 h-100">
            <Button
              id="key_selector_button"
              className="w-100 h-100 checkOutButton"
              color={type !== 'refund' ? 'primary' : 'secondary'}
              variant="contained"
              onClick={canSell ? openLocalModal : () => {}}
              disabled={pos_sininv ? false : (!sinStock ? canUserSell : true)}
              /* disabled={!isVerifyStock && sellWithStock ? canUserSell : true}*/
            >
              <span>
                <FontAwesomeIcon size="2x" icon="hand-holding-usd" />
              </span>
              <span className="pl-1">{title}</span>
            </Button>
            {/* <DropUp /> */}
          </div>
        </Tooltip>
      )}

      {/* <CheckOutDialog
      color={color}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      onSave={onSave}
      totalToPay={totalToPay}
      defaultOrderStatus={defaultOrderStatus}
      orderStatuses={orderStatuses}
      paymentTypes={paymentTypes}
    /> */}
      <CheckOutModal
        cart={cart}
        customer={customer}
        defaultOrderStatus={defaultOrderStatus}
        dispatchEditOrder={dispatchEditOrder}
        handleMakeBill={handleMakeBill}
        isLoading={isLoading}
        isOpen={isOpen}
        makeBill={makeBill}
        onSave={onSave}
        orderStatuses={orderStatuses}
        paymentTypes={paymentTypes}
        posCheckoutBill={posCheckoutBill}
        title={modalTitle}
        toggle={onClose}
        totalToPay={totalToPay}
      />
    </>
  ); };

CheckOut.propTypes = {
  canSell: PropTypes.bool.isRequired,
  cart: PropTypes.object,
  ctrlPressed: PropTypes.bool,
  customer: PropTypes.object,
  defaultOrderStatus: PropTypes.string,
  dispatchEditOrder: PropTypes.func,
  handleMakeBill: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  makeBill: PropTypes.bool,
  modalTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  orderStatuses: PropTypes.array,
  paymentTypes: PropTypes.array.isRequired,
  posCheckoutBill: PropTypes.string,
  smallButton: PropTypes.bool,
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  totalToPay: PropTypes.number.isRequired,
  type: PropTypes.string,
};

CheckOut.defaultProps = {
  cart: {},
  ctrlPressed: true,
  customer: {},
  defaultOrderStatus: '',
  dispatchEditOrder: () => {},
  handleMakeBill: () => {},
  makeBill: false,
  modalTitle: 'Cobro',
  orderStatuses: [],
  posCheckoutBill: 'Preguntar',
  smallButton: false,
  title: 'Cobrar',
  toolTipText: 'CTRL + ENTER para realizar cobro',
  type: 'checkout',
};

export default CheckOut;
