/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Slide } from '@material-ui/core';
import ProductGrid from './product-grid/product-grid';
import ProductTable from './products-table/ProductTable';
import { addProductToCart } from '../../../redux/actions/cartActions';
import ProductOptionList from './product-options/product-option-list';
import CartBarCodeScanner from './CartBarCodeScanner';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const ProductContainer = ({ searchIndex }) => {
  const dispatch = useDispatch();
  const tabs = useSelector(state => state.tabs);
  const { productView } = useSelector(state => state.posv2);
  const [isOpen, setIsOpen] = useState(false);
  const [productSelected, setProductSelected] = useState(null);

  const handleSelect = async (product) => {
    if (product.Products.length === 1) {
      dispatch(addProductToCart(product.crmid, product.Products[0].crmid, 1));
      return;
    }
    await setProductSelected(product);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <CartBarCodeScanner onSelect={handleSelect} />
      {
          tabs.posTypeTab === 'Vender' && (
            <>
              {
                productView === 'grid' && (
                  <ProductGrid onSelect={handleSelect} />
                )
              }
              {
                (productView === 'list') && (
                  <ProductTable onSelect={handleSelect} activeIndex={searchIndex} />
                )
              }
            </>
          )
        }
      <Dialog
        key={Math.random()}
        fullScreen
        open={isOpen}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
      >
        <ProductOptionList
          product={productSelected}
          handleClose={() => handleClose()}
        />
      </Dialog>
    </div>
  ); };

ProductContainer.propTypes = {
  searchIndex: PropTypes.string.isRequired,
};

export default ProductContainer;
