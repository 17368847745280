/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import CotizarAPI from '../../api/CotizarAPI';
import ClientAPI from '../../api/ClientAPI';
import { addOrderToCart, clearCart } from './cartActions';
import {
  removeCustomer,
  selectCustomer,
} from './customerActions';
import { fetchAllProducts } from './productActions';
import { fetchAllOrders } from './orderActions';
import { loading, loaded } from './loadActions';
import { clearSell } from './sellActions';
import { changeTab } from './tabActions';
import { addDiscount, removeDiscount } from './discountActions';
import { orderHelper, printerHelper, toolsHelper } from '../../helpers';

export const FETCH_ALL_COTIZACIONES = 'FETCH_ALL_COTIZACIONES';
export const ADD_COTIZACION = 'ADD_COTIZACION';
export const GET_COTIZACIONES_BY_PAGE = 'GET_COTIZACIONES_BY_PAGE';
export const FILTER_COTIZACIONES = 'FILTER_COTIZACIONES';
export const SET_MULTIFILTER_COTIZACION = 'SET_MULTIFILTER_COTIZACION';
export const CLEAR_MULTIFILTER_COTIZACION = 'CLEAR_MULTIFILTER_COTIZACION';
export const REMOVE_MULTIFILTER_COTIZACION = 'REMOVE_MULTIFILTER_COTIZACION';
export const SELECT_COTIZACION = 'SELECT_COTIZACION';

const target = 'cotizacion';

export const SET_ALL_QUOTS_PAGES = 'SET_ALL_QUOTS_PAGES';
export const SET_CURRENT_PAGES = 'SET_CURRENT_PAGES';
export const SET_TOTAL_QUOTS = 'SET_TOTAL_QUOTS';

export const UPDATE_PAGINATOR = 'UPDATE_PAGINATOR';
export const ADD_NEW_QUOTES = 'ADD_NEW_QUOTES';

export function setAllQuotsPages(allPages) {
  return {
    allPages,
    type: SET_ALL_QUOTS_PAGES,
  };
}

export function setCurrentPage(currentPage) {
  return {
    currentPage,
    type: SET_CURRENT_PAGES,
  };
}

export function setTotalQuots(totalQuots) {
  return {
    totalQuots,
    type: SET_TOTAL_QUOTS,
  };
}

const displayGroupMessage = (message) => {
  if (message.success) {
    toast.success(` ✔ ${message.success}`);
  }

  else if (message.warning) {
    toast.warn(` ❕ ${message.warning}`);
  }

  else if (message.error) {
    toast.error(` ❌ ${message.error}`);
  }

  else {
    toast.success(` ❔ ${message.information}`);
  }
};

export function addCotizacion(cotizaciones) {
  return {
    type: ADD_COTIZACION,
    cotizaciones,
  };
}

export function getCotizacionesByPage(page = 0) {
  return async (dispatch) => {
    dispatch(loading(target));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: GET_COTIZACIONES_BY_PAGE,
        page,
      });
    } catch {
      /* Continue regardless of error */
    } finally {
      dispatch(loaded(target));
    }
  };
}

export function setMultiFilterCotizacion(item) {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { filters } = order;
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: SET_MULTIFILTER_COTIZACION,
        filters,
        item,
      });
    } catch (err) {
      console.log('setMultiFilterCotizacion err: ', err);
    }
  };
}

export function filterCotizacion(word) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    const { tabs } = getState();
    try {
      await new Promise(resolve => setTimeout(resolve));
      switch (tabs.posDisplayTab) {
        case 'grid':
          dispatch({
            type: FILTER_COTIZACIONES,
            word,
          });
          break;
        case 'list':
          dispatch(setMultiFilterCotizacion({ title: 'search', value: word, type: 'search' }));
          break;
        default:
          dispatch({
            type: FILTER_COTIZACIONES,
            word,
          });
      }
    } catch {
      /* Continue regardless of error */
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function clearAllFiltersCotizacion() {
  return {
    type: CLEAR_MULTIFILTER_COTIZACION,
  };
}

export function removeMultiFilterOrder(word) {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { filters } = order;
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: REMOVE_MULTIFILTER_COTIZACION,
        filters,
        word,
      });
    } catch (err) {
      console.log('removeMultiFilter err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function fetchAllCotizaciones(page = 1, filters = null, search = '') {
  const cotizarApi = new CotizarAPI();
  return async (dispatch) => {
    dispatch(loading(target));
    dispatch({
      type: FETCH_ALL_COTIZACIONES,
    });
    try {
      let response;
      if (filters) {
        response = await cotizarApi.getFilteredQuotes(filters);
      } else {
        response = await cotizarApi.getAll(page, search);
      }
      await dispatch(setAllQuotsPages(response.pages));
      await dispatch(setCurrentPage(page));
      await dispatch(setTotalQuots(response.count));
      dispatch(addCotizacion(response.result));
    }
    catch (err) {
      dispatch(addCotizacion([]));
    } finally {
      dispatch(clearAllFiltersCotizacion());
      dispatch(loaded(target));
    }
  };
}

export function selectCotizacion(crmid) {
  return async (dispatch, getState) => {
    const cotizarApi = new CotizarAPI();
    const { cotizacion, customer } = getState();
    const { customersByArray } = customer;
    let foundOrder = cotizacion.cotizaciones.find(element => element.crmid === crmid);

    if (foundOrder === undefined) {
      const { result, success } = await cotizarApi.getQuote(crmid);
      if (success) {
        foundOrder = result;
      } else {
        toast.error('No se logró obtener las cotizaciones, intentelo mas tarde.');
        return;
      }
    }
    const crmidUser = foundOrder.account_id.split('x').pop();
    let foundCustomer = customersByArray.find(item => item.crmid === crmidUser);
    if (foundCustomer !== undefined) {
      if (toolsHelper.stringIsEmpty(foundOrder.hdnDiscountAmount)
          || toolsHelper.stringIsEmpty(foundOrder.hdnDiscountPercent)) {
        const result = orderHelper.getDiscountStateFromOrder(foundOrder);
        await dispatch(addDiscount(result));
      }
    } else {
      const clientApi = new ClientAPI();
      const response = await clientApi.getAccount(crmidUser);
      const { success } = response;
      if (success) {
        foundCustomer = response.result;
      } else {
        await toast.success('Cliente encontrado sin RFC y sin correo electrónico');
        const { accountname } = foundOrder;
        foundCustomer = { accountname };
      }
    }
    if (toolsHelper.stringIsEmpty(foundOrder.hdnDiscountAmount)
            || toolsHelper.stringIsEmpty(foundOrder.hdnDiscountPercent)) {
      const result = orderHelper.getDiscountStateFromOrder(foundOrder);
      dispatch(addDiscount(result));
    }
    await dispatch({ type: SELECT_COTIZACION });
    await dispatch(addOrderToCart(foundOrder));
    await dispatch(selectCustomer(foundCustomer, false));
    /* } else {
      toast.error('No se logró obtener las cotizaciones, intentelo mas tarde.');
    } */
  };
}

export function cotizar() {
  return async (dispatch, getState) => {
    const cotizarApi = new CotizarAPI();
    const {
      cart,
      discount,
      customer,
      authUser,
      filters,
    } = getState();
    dispatch(loading(target));
    toast.info(' 🚀 Enviando productos para cotizar...');
    try {
      const { sostatus } = authUser.user.config;
      const printConfig = printerHelper.createPrintQuote(authUser.user);
      console.log('printConfig: ', printConfig);
      
      const response = await cotizarApi.create(cart, discount, customer, sostatus, filters, printConfig);
      const { success, message, result } = response;
      if (success) {
        displayGroupMessage(message);
        dispatch(clearCart());
        dispatch(removeCustomer());
        dispatch(removeDiscount());
        dispatch(fetchAllProducts());
        dispatch(fetchAllOrders());
        await dispatch(fetchAllCotizaciones());
        await dispatch(changeTab('posType', 'Cotizaciones'));
        dispatch(clearSell());
        const { crmid } = result;
        dispatch(selectCotizacion(crmid));
      }
      else {
        toast.error(message);
      }
    }
    catch {
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded(target));
    }
  };
}

export function cloneQuote() {
  return async (dispatch, getState) => {
    const cotizarApi = new CotizarAPI();
    const {
      cart,
      discount,
      customer,
      authUser,
      filters,
    } = getState();
    dispatch(loading(target));
    toast.info(' 🚀 Clonando cotización...');
    try {
      const { user } = authUser;
      const { order: { quotestage } } = cart.orderSelected;
      // Get quote status in english
      const realQuoteStatus = Object.keys(user.quotestage).find(key => user.quotestage[key] === quotestage);
      const response = await cotizarApi.create(cart, discount, customer, realQuoteStatus, filters);
      const { success, message, result } = response;
      if (success) {
        displayGroupMessage(message);
        dispatch(clearCart());
        dispatch(removeCustomer());
        dispatch(removeDiscount());
        dispatch(fetchAllProducts());
        dispatch(fetchAllOrders());
        await dispatch(fetchAllCotizaciones());
        await dispatch(changeTab('posType', 'Cotizaciones'));
        dispatch(clearSell());
        const { crmid } = result;
        dispatch(selectCotizacion(crmid));
      }
      else {
        toast.error(message);
      }
    }
    catch {
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded(target));
    }
  };
}

export function editarCotizacion() {
  return async (dispatch, getState) => {
    const cotizarApi = new CotizarAPI();
    const {
      cart,
      discount,
      customer,
      authUser,
    } = getState();
    dispatch(loading(target));
    toast.info(' 🚀 Editando...');
    try {
      const { user: { quotestage } } = authUser;
      const { orderSelected: { status } } = cart;
      const sostatus = Object.entries(quotestage).find(x => x[1] === status);
      const response = await cotizarApi.edit(cart, discount, customer, sostatus);
      const { success, message, result } = response;
      if (success) {
        const { crmid } = result;
        displayGroupMessage(message);
        dispatch(clearCart());
        dispatch(removeCustomer());
        dispatch(removeDiscount());
        dispatch(fetchAllProducts());
        dispatch(fetchAllOrders());
        await dispatch(fetchAllCotizaciones());
        await dispatch(selectCotizacion(crmid));
        dispatch(clearSell());
      }

      else {
        toast.error(message);
      }
    }

    catch {
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded(target));
    }
  };
}


export function addNewQuotes(quotes) {
  return {
    type: ADD_NEW_QUOTES,
    quotes,
  };
}

export const fetchListQuotes = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const cotizarApi = new CotizarAPI();
  dispatch(loading(target));
  const response = await cotizarApi.getListTreebesQuotes(page, search, accountId);
  const {
    result, success, pages, count,
  } = response;
  if (success) {
    if (search !== '') {
      dispatch(addCotizacion(result));
    } else {
      dispatch(addNewQuotes(result));
    }
    dispatch({
      type: UPDATE_PAGINATOR,
      page,
      pages,
      count,
    });
    dispatch(loaded(target));
  } else {
    toast.error('Error cargando cotizaciones');
  }
};
