/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly, faMapMarkerAlt, faRoute } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import IconWidthBG from '../../../../../../Icons/IconWidthBG/IconWidthBG';
import { toggleModal } from '../../../../../../../redux/actions/modalActions';


const StoreShippingSection = ({ storePickup, setStorePickup }) => {
  const dispatch = useDispatch();
  const [almacenesOptions, setAlmacenesOptions] = React.useState([]);
  const [almacenEnvio, setAlmacenEnvio] = React.useState(null);
  const [almacenSelected, setAlmacenSelected] = React.useState(null);
  const [clientAddress, setClientAddress] = React.useState(null);

  const { orderSelected } = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const authUser = useSelector(state => state.authUser);
  const { address, selectedCustomer, defaultCustomer } = customer;
  const { user: { almacenes, config: { store } } } = authUser;

  const onChange = (select) => {
    if (select) {
      const storePicked = almacenes.find(x => x.crmid === select.value);
      setStorePickup(storePicked);
    }
  };


  const buildStoreList = () => {
    const storesForOrigin = almacenes.filter(s => s.origen_envios === '1');
    if (almacenEnvio) {
      const findIndex = storesForOrigin.findIndex(s => s.crmid === almacenEnvio.crmid);
      if (findIndex === -1) {
        storesForOrigin.push(almacenEnvio);
      }
    }
    if (almacenSelected) {
      const findIndex = storesForOrigin.findIndex(s => s.crmid === almacenSelected.crmid);
      if (findIndex === -1) {
        storesForOrigin.push(almacenSelected);
      }
    }

    // Format store options for select input
    const stores = storesForOrigin.map(storeOrig => ({
      value: storeOrig.crmid,
      label: storeOrig.entity_label,
    }));

    setAlmacenesOptions(stores);
  };

  const handleSetStores = () => {
    let storeSelectedIndex = '';
    // Get Store selected
    // There is an order with a store selected
    if (orderSelected && orderSelected.almid) {
      storeSelectedIndex = almacenes.findIndex(s => s.crmid === orderSelected.almid);
    } else {
      storeSelectedIndex = almacenes.findIndex(s => s.crmid === store.crmid);
    }

    if (storeSelectedIndex !== -1) {
      const storeSelect = almacenes[storeSelectedIndex];
      setAlmacenSelected(storeSelect);
      setStorePickup(storeSelect);
      if (Object.hasOwnProperty.call(storeSelect, 'treebesalmid_env') && storeSelect.treebesalmid_env !== '' && storeSelect.treebesalmid_env !== '0' && storeSelect.treebesalmid_env !== null) {
        // Set almacen de envios
        const envioStoreIndex = almacenes.findIndex(s => s.crmid === storeSelect.treebesalmid_env);
        if (envioStoreIndex !== -1) {
          const envioStore = almacenes[envioStoreIndex];
          setAlmacenEnvio(envioStore);
          setStorePickup(envioStore);
        }
      }
    }
  };


  const updateClientAddress = async () => {
    const newAddress = address.find(
      add => add.crmid === selectedCustomer.treebesdireccionenvioid,
    );
    if (newAddress) {
      await setClientAddress(newAddress);
    } else {
      await setClientAddress(null);
    }
  };

  useEffect(() => {
    updateClientAddress();
  }, [customer]);

  useEffect(() => {
    handleSetStores();

    return () => {
      setAlmacenesOptions([]);
      setAlmacenEnvio(null);
      setAlmacenSelected(null);
      setStorePickup(null);
    };
  }, []);

  useEffect(() => {
    buildStoreList();
  }, [almacenSelected, almacenEnvio]);

  return (
    <div className="mx-3 my-2">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ fontSize: 14 }}
      >
        <div className="d-flex align-items-center my-3">
          <IconWidthBG
            primaryColor="#23a1db"
            secondaryColor="white"
            width={27}
            height={27}
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
          </IconWidthBG>
          <strong className="mx-2">Almacén: </strong>
          {
            almacenesOptions.length > 0 && (
            <Select
              onChange={onChange}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={{
                value: storePickup.crmid,
                label: storePickup.entity_label,
              }}
              isSearchable
              name="Almacen"
              options={almacenesOptions}
            />
            )
          }
        </div>
      </div>
      <p className="d-flex align-items-center my-3" style={{ fontSize: 14 }}>
        <IconWidthBG
          primaryColor="#23a1db"
          secondaryColor="white"
          width={27}
          height={27}
          enabled
          square
          rounded
          actionClick={() => {}}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faMapMarkerAlt} />
        </IconWidthBG>
        <strong className="mx-2">Origen: </strong>
        <span>
          {storePickup?.treebesdireccionenvioid && storePickup?.treebesdireccionenvioid !== '0'
            ? storePickup?.___treebesdireccionenvioid_formatted___
              || storePickup?.treebesdireccionenvioid_label
            : <span style={{ color: 'red' }}>Debes agregar una dirección al almacén</span>}
        </span>
      </p>
      <p className="d-flex align-items-center my-3" style={{ fontSize: 14 }}>
        <IconWidthBG
          primaryColor="#23a1db"
          secondaryColor="white"
          width={27}
          height={27}
          enabled
          square
          rounded
          actionClick={() => {
            dispatch(toggleModal('customerEdit'));
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faRoute} />
        </IconWidthBG>
        <strong className="mx-2">Destino: </strong>
        <span>
          {clientAddress
            ? `${clientAddress?.direccion}, #${clientAddress?.num_ext}, ${clientAddress?.neighborhood}, C.P ${clientAddress?.postcode}, ${clientAddress?.city}, ${clientAddress?.state}, ${clientAddress?.country}`
            : ''}
        </span>
        <span className="ml-2">
          <Button
            type="button"
            size="small"
            variant="outlined"
            disabled={selectedCustomer.crmid === defaultCustomer.crmid}
            onClick={() => dispatch(toggleModal('customerEdit'))}
          >
            Cambiar dirección
          </Button>
        </span>
      </p>
    </div>
  );
};

export default StoreShippingSection;
