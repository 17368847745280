/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen, faDolly, faTruck, faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { BorderRight, Delete } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import FlexMenu from '../../../headless-menu/FlexMenu';
import { formatMoney } from '../../../../../helpers/tools';
import { openModal } from '../../../../../redux/actions/modalActions';
import { deleteShipping } from '../../../../../redux/actions/shippingActions';

/* TODO
 * [x] Agregar selector de cliente para poder seguir con envios
 * [x] Obtener envios desde el store
 * [x] Mostrar envios
 * [x] Validar si hay productos en el carrito
 * [x] Eliminar envio
 * [] Separar componentes
 */
const CartDeliverMenu = () => {
  const dispatch = useDispatch();
  const shipping = useSelector(state => state.shipping);
  const cart = useSelector(state => state.cart);
  const { products } = cart;
  const { shippingList } = shipping;

  const handleAddShipping = () => {
    dispatch(openModal('shipping'));
  };

  return (
    <FlexMenu>
      <Badge badgeContent={shippingList?.length || 0} color="primary" invisible={!shippingList || !shippingList?.length}>
        <FlexMenu.Trigger
          as={IconButton}
          aria-label="Menu"
          style={{
            borderRadius: '5px',
            height: 40,
            border: '1px solid #b8b8b8',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <FontAwesomeIcon icon={faTruck} style={{ fontSize: '16px' }} />
        </FlexMenu.Trigger>
      </Badge>
      <FlexMenu.OptionList as={Paper} position="left" style={{ width: 286 }}>
        <>
          {
          shippingList && shippingList.length > 0 && shippingList.map(envio => (
            <>
              <DeliverItem
                key={envio.crmid}
                ship={envio}
              />
              <Divider />
            </>
          ))
        }
          <List>
            <ListItem className="d-flex justify-content-center">
              <Button disabled={!products || !products.length} variant="contained" color="primary" onClick={handleAddShipping}>
                Nuevo Envío
              </Button>
            </ListItem>
          </List>
        </>
      </FlexMenu.OptionList>
    </FlexMenu>
  ); };

export default CartDeliverMenu;

const DeliverItem = ({ ship }) => {
  const dispatch = useDispatch();
  const removeShip = () => {
    dispatch(deleteShipping(ship));
  };
  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <FontAwesomeIcon style={{ fontSize: 34 }} icon={faTruck} />
        </ListItemAvatar>
        <ListItemText
          primary={ship.carrier}
          secondary={(
            <>
              <span style={{
                padding: '5px',
                backgroundColor: '#b7bfc630',
                borderRadius: '4px',
              }}
              >
                $
                {formatMoney(ship.totalPrice)}
              </span>
              <span> </span>
              <span style={{
                padding: '5px',
                backgroundColor: '#b7bfc630',
                borderRadius: '4px',
              }}
              >
                Cant:
                {ship.products?.length || 0}
              </span>
            </>
        )}
        />
        <ListItemSecondaryAction>
          <IconButton aria-label="Remove" onClick={removeShip}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem divider>
        <ListItemText primary="Origen" secondary={ship.dirOrigen || '-'} />
      </ListItem>
      {
        ship.dirDestino && (
          <ListItem>
            <ListItemText primary="Destino" secondary={ship.dirDestino} />
          </ListItem>
        )
      }
    </List>
  ); };
