/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatMoney, formatString } from '../../../../helpers/tools';
import Stock from './Stock';

const useStyles = makeStyles(() => ({
  lightTooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    padding: '15px',
    opacity: 1,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
  },
}));

const ProductTableItem = ({
  hit, onSelect, store, index,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const authUser = useSelector(state => state.authUser);
  const { user: { product_image_default } } = authUser;

  return (
    <tr
      key={hit.objectID}
      onClick={() => onSelect(hit)}
      style={{
        backgroundColor: !hit[`alm_${store.crmid}`] && hit?.inventariable !== false
          ? '#F05B7026'
          : index % 2 === 0
            ? '#fff'
            : '#f6f6f7',
      }}
    >
      {/* Image */}
      <td className="image-td">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {hit.discount > 0 && (
          <div
            style={{
              backgroundColor: '#23a1dbd9',
              color: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '0',
              left: '0',
              height: '15px',
            }}
          >
            <span style={{ fontSize: '12px' }}>
              -
              {hit.discount}
              {' '}
              %
            </span>
          </div>
          )}
          <div
            style={{
            // width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: 'auto',
              backgroundColor: 'white',
            }}
          >
            <Tooltip
              enterDelay={1000}
              classes={{ tooltip: classes.lightTooltip }}
              disableFocusListener
              placement="right"
              onOpen={() => setOpen(true)}
              open={open}
              onClose={() => setOpen(false)}
              title={(
                <Stock store={store} hit={hit} />
              )}
            >
              <img
                src={hit.tpv_image || product_image_default}
                alt="Product"
                style={{ width: '63px', height: '65px' }}
              />
            </Tooltip>
            {/* Font awesome list icon */}
            {hit.Products && hit.Products.length > 1 && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: '#8152a0',
                color: 'white',
                width: '10px',
                height: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
              }}
            />
            )}
          </div>
        </div>
      </td>
      <td>{hit.sku}</td>
      <td style={{ maxWidth: '200px', overflow: 'hidden' }}>
        {formatString(hit.categorias)}
      </td>
      <td>{hit.marca}</td>
      <td>{hit.entity_label}</td>
      <td>
        <div>
          {hit.discount > 0 && (
          <div>
            <p style={{ textDecoration: 'line-through', fontSize: '12px' }}>
              {formatMoney(hit.unit_price)}
            </p>
            <strong>{formatMoney(hit.unit_price_included_discount)}</strong>
          </div>
          )}
          {!hit.discount && (
          <div>
            <strong>{formatMoney(hit.unit_price)}</strong>
          </div>
          )}
        </div>
      </td>
      <td>
        <div>
          {/* <strong>{formatMoney(hit.unit_price_included_discount_without_taxes)}</strong> */}
          <strong>{formatMoney(hit.unit_price_included_tax)}</strong>
        </div>
      </td>
    </tr>
  ); };


ProductTableItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  hit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ProductTableItem;
