import {
  Button,
  Checkbox,
  Collapse, LinearProgress, Paper, Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useSound from '../../../../hooks/sound/useSound';
import { formatString } from '../../../../helpers/tools';

const ExternalDeliverProducts = ({
  products, changeInput, loading, selectAll, selectNone, selectedProducts,
}) => {
  const { playSound } = useSound();
  const handleReadBarCode = (e) => {
    const barCode = localStorage.getItem('barCode') || '';
    console.log('barCode', barCode);
    // const barCode = e.target.value;
    if (e.keyCode === 13 && barCode && products.length) {
      const findIndex = products.findIndex(prod => `${prod.codigo_barras}` === barCode);
      if (findIndex !== -1) {
        const item = products[findIndex];
        const selectedIndex = selectedProducts.findIndex(p => p === item.treebesmdaid);
        if (selectedIndex !== -1) {
          playSound('error');
          localStorage.setItem('barCode', '');
          toast.warn('Producto seleccionado');
          return;
        }
        if (item.qtyinstock > 0) {
          changeInput(item);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          playSound('error');
          toast.warn('No hay disponible en stock');
          localStorage.setItem('barCode', '');
        }
      } else {
        playSound('invalid');
        toast.error('No existe el codigo');
        localStorage.setItem('barCode', '');
      }
    } else if (e.key !== 'Enter' && e.key !== 'Shift') {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCode', '');
    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [selectedProducts]);

  return (
    <div className="deliver__products">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <Button disabled={loading} className="mr-1" variant="outlined" onClick={selectAll}>
            Seleccionar todo
          </Button>
          <Button disabled={loading} variant="outlined" onClick={selectNone}>
            Seleccionar nada
          </Button>
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <Paper className="w-100 product-list">
        {
        products.length > 0 && products.map(item => (
          <div className="refund_product" key={item.treebesmdaid}>
            <div className="refund_product_stock">
              {item.qtyinstock}
              <span>Stock</span>
            </div>
            <div className="refund_product_detail">
              <div className="refund_product_title">
                <Tooltip placement="left-start" title={item.productname || ''}>
                  <span>{formatString(item.productname)}</span>
                </Tooltip>
              </div>
              <div className="refund_product_desc">
                {`Por entregar: ${item.cantidad}`}
              </div>
              <div className="refund_product_desc">
                {`codigo: ${item.productcode}`}
              </div>
            </div>
            <div className="refund_product_qty">
              <div style={{
                display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column',
              }}
              >
                <span>
                  {`Cantidad: ${item.cantidad}`}
                </span>
                <input className="scanInput" type="text" readOnly value={item.codigo_barras} />
              </div>
              <Checkbox
                checked={selectedProducts.includes(item.treebesmdaid)}
                onChange={() => changeInput(item)}
                disabled={item.qtyinstock < 1}
                value={item.id}
              />
            </div>
          </div>
        ))
      }
      </Paper>
    </div>
  ); };

ExternalDeliverProducts.propTypes = {
  products: PropTypes.array,
  selectedProducts: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

ExternalDeliverProducts.defaultProps = {
  products: [],
  selectedProducts: [],
  loading: false,
  changeInput: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default ExternalDeliverProducts;
