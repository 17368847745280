/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  /* Menu, */
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Actions */
import { useDispatch, useSelector } from 'react-redux';
/* import IconButtonInput from '../../../../Input/IconButton';
import SeeOnCrmAction from '../components/SeeOnCrmAction';
import ShippingRequestAction from '../components/ShippingRequestAction';
import ChangeOrderCurrencyAction from '../components/ChangeOrderCurrencyAction'; */
import { toggleOpenDetail } from '../../../../../redux/actions/cartActions';

const ReleatedControls = ({
  cart,
  tooltip,
  dispatchOpenModal,
}) => {
  const dispatch = useDispatch();
  /* const tabs = useSelector(state => state.tabs);
  const [anchorEl, setAnchorEl] = React.useState(null); */
  const { openDetail } = cart;
  // const open = Boolean(anchorEl);

  // const { posTypeTab } = tabs;

  function handleClick() {
    // setAnchorEl(event.currentTarget);
    dispatch(toggleOpenDetail(!openDetail));
  }

  /* function handleClose() {
    setAnchorEl(null);
  } */

  /* const handleModal = (modal = '') => {
    dispatchOpenModal(modal);
    handleClose();
  }; */
  /* Constants */
  // const { orderSelected } = cart;
  /* const orderNo = (orderSelected && orderSelected.order)
    ? (orderSelected.order.salesorder_no || orderSelected.order.label)
    : ''; */
  /* Enables */
  // const isOrder = Boolean(orderNo);

  /* Props */
  /* const rest = {
    width: '60px', height: '60px', iconSize: '18px', enable: isOrder,
  }; */
  return (
    <>
      <Tooltip
        title={tooltip}
        placement="bottom"
        classes={{ tooltip: 'maxToolTip' }}
      >
        <span>
          <IconButton
            id="cart-releated-btn"
            aria-label="More"
            aria-controls="cart-releated-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              icon="info-circle"
              style={{ width: '21px', heigth: '21px' }}
            />
          </IconButton>
        </span>
      </Tooltip>
      {/* <Menu
        id="cart-releated-menu"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="cart_controls"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        {
          posTypeTab !== 'Vender' && (
            <>
              <IconButtonInput
                onClick={() => handleModal('file')}
                icon="folder-open"
                label="Archivos"
                tooltip="Archivos"
                {...rest}
              />
              <IconButtonInput
                onClick={() => handleModal('addressCustomer')}
                icon="map-marker-alt"
                label="Dir. de Envío"
                tooltip="Dirección de Envío"
                {...rest}
              />
            </>
          )
        }
        {
          posTypeTab === 'Historico' && (
            <>
              <IconButtonInput
                onClick={() => handleModal('factura')}
                icon="file-signature"
                label="Facturas"
                tooltip={isOrder ? 'Facturas' : 'Seleccione una orden primero'}
                {...rest}
              />
              <IconButtonInput
                onClick={() => handleModal('fde')}
                icon="hand-holding-usd"
                label="F. de Efectivo"
                tooltip={isOrder ? 'Flujo de Efectivo' : 'Seleccione una orden primero'}
                {...rest}
              />
              <IconButtonInput
                onClick={() => handleModal('mda')}
                icon="dolly"
                label="M. de Almacén"
                tooltip={isOrder ? 'Movimiento de Almacén' : 'Seleccione una orden primero'}
                {...rest}
              />
              <ShippingRequestAction enable={isOrder} appLocation="orden" tooltip={isOrder ? 'Guías' : 'Seleccione una orden primero'} />
              <ChangeOrderCurrencyAction enable={isOrder} tooltip={isOrder ? 'Cambiar moneda de la orden' : 'Cambiar moneda cotización'} />
            </>
          )
        }
        {
          posTypeTab !== 'Vender' && (
            <SeeOnCrmAction enable={isOrder} appLocation="orden" tooltip={isOrder ? 'Ver orden en sistema' : 'Seleccione una orden primero'} />
          )
        }
      </Menu> */}
    </>
  );
};

ReleatedControls.propTypes = {
  cart: PropTypes.object.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};
ReleatedControls.defaultProps = {
  tooltip: 'Relacionados a la Orden',
};

export default ReleatedControls;
