/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import Lote from './components/Lote';

const Lotes = ({
  lotes,
  product,
  updateLotes,
  scannedProduct,
  selectedProduct,
}) => {
  console.log('lotes', lotes);
  console.log('updateLotes', updateLotes);
  console.log('product', product);
  const tipo = {
    pl_products_batch_status_lot: 'Lotes',
    pl_products_batch_status_serial: 'Series',
  };
  const isSeries = tipo[product.pl_products_batch_status] === 'Series';
  const localLotes = [...lotes];
  const [options, setOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [open, setOpen] = useState(false);


  const ExpansionPanell = withStyles({
    expanded: {
      margin: 'auto',
    },
  })(ExpansionPanel);

  const addQty = (loteId, qty) => {
    const allLotes = [...localLotes];
    const loteIndex = allLotes.findIndex(l => l.treebeslotesid === loteId);
    if (loteIndex === -1) {
      return;
    }

    allLotes[loteIndex].qty = qty;
    updateLotes(allLotes, product);
  };

  const buildSelectOption = () => {
    const otp = [];
    lotes.map(l => otp.push({
      value: l.treebeslotesid,
      label: l.number,
    }));

    setOptions(otp);
  };

  const onChange = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectOptions([]);
      setSelectedOption([]);

      const resetLotes = localLotes.map((l) => {
        l.qty = 0;
        return l;
      });

      updateLotes(resetLotes, product);
      return;
    }
    const values = selectedOptions.map(s => s.value);
    setSelectOptions(selectedOptions);
    setSelectedOption(values);
    const totalAmount = localLotes.reduce((acc, val) => acc + val.qty, 0);
    const resetLotes = localLotes.map((l) => {
      if (values.includes(l.treebeslotesid)) {
        if (isSeries) {
          l.qty = 1;
        } else if (l.qty === 0 && totalAmount < product.cantidad) { // Add lote with qty = 1
          l.qty += 1;
        }
        return l;
      }

      l.qty = 0;
      return l;
    });

    updateLotes(resetLotes, product);
  };

  useEffect(() => {
    buildSelectOption();
  }, []);

  return (
    <>
      {localLotes.length > 0 && (
        <ExpansionPanell
          classes={{ expanded: { margin: 'auto' } }}
          square
          key={Math.random()}
          expanded={localStorage.getItem('scanedProductId') === product?.id || open}
        >
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon onClick={() => setOpen(!open)} />
            }
          >
            <Typography>{tipo[product.pl_products_batch_status]}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <Select
                isMulti
                defaultValue={selectOptions}
                isDisabled={product.por_entregar === product.cantidad}
                name="lotes"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onChange}
                autoFocus={(scannedProduct && scannedProduct.id === product.id) || product.id === localStorage.getItem('selectedInput')}
                onFocus={() => localStorage.setItem('selectedInput', product.id)}

              />
              {
                tipo[product.pl_products_batch_status] === 'Lotes' && selectedOption.length > 0 && (
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Lote</th>
                        <th>Fecha</th>
                        <th>Disponible</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {localLotes.map(lote => (
                        selectedOption.includes(lote.treebeslotesid) ? (
                          <Lote key={lote.treebeslotesid} add={addQty} product={product} lote={lote} />
                        ) : null
                      ))}
                    </tbody>
                  </table>
                )
              }
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanell>
      )}
    </>
  );
};

export default Lotes;
