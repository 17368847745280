/* eslint-disable camelcase */

import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class CotizarAPI extends ConfigurationAPI {
  create = async (cart, discount, customer, sostatus, filters, printConfig) => {
    this.credentials.method = 'POST';

    const { discountTaxes } = filters;
    const totalCart = cart.total;
    const { promotions } = cart;
    const totalDiscount = discount.total;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));

    const products = [];
    cart.products.forEach((product) => {
      const productid = (product.productid && product.productid.indexOf('x') !== -1)
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price: product.priceWithTaxes / (1 - (product.discount_percent / 100)),
        pu: product.unit_price,
        product_id: product.parentId,
        productid: product.crmid || productid,
        quantity: product.quantity,
        comment: product.comment,
      };
      products.push(custom);
    });

    /**
     * Working on promotions
     */
    if (promotions && promotions.free_products) {
      promotions.free_products.forEach((product) => {
        const promotionProduct = {
          option: [
            {
              product_option_value_id: product.productid,
            },
          ],
          discount_percent: 0,
          price: product.unit_price,
          pu: product.unit_price,
          product_id: null,
          quantity: product.quantity,
          productid: product.productid,
          comment: '',
        };
        products.push(promotionProduct);
      });
    }

    const data = {
      products,
      subtotal: cart.subTotal,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      order_status: sostatus,
      comment: cart.description,
      sin_impuestos: discountTaxes ? 1 : 0,
    };

    if (printConfig && printConfig.print_templateid && printConfig.print_impresoraid) {
      data.print_templateid = printConfig.print_templateid;
      data.print_impresoraid = printConfig.print_impresoraid;
    }

    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(`${this.url}/Quotes`, this.credentials);
      const json = await response.json();
      const information = json.success ? `Guardado Exitoso ${json.result.salesorder_no}` : json.error.message;
      const message = { ...json.message, information };
      return { ...json, message };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  edit = async (cart, discount, customer, factura = null, quoteState, envio = null, filters) => {
    this.credentials.method = 'PUT';
    const { discountTaxes } = filters;
    const { orderSelected: { crmid, order } } = cart;
    const { promotions, activePromotions } = cart;
    const totalCart = cart.total;
    const totalDiscount = discount.total;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));

    let products = [];
    cart.products.forEach((product) => {
      const productid = (product.productid && product.productid.indexOf('x') !== -1)
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price: product.priceWithTaxes === 0 ? (0.01) : (product.priceWithTaxes / (1 - (product.discount_percent / 100))),
        pu: product.unit_price,
        product_id: product.parentId,
        productid: product.crmid || productid,
        quantity: product.quantity,
        comment: product.comment,
      };
      products.push(custom);
    });

    /**
     * Working on promotions
     */
    if (activePromotions) {
      products = products.filter(prod => prod.price > 0.02);
      if (promotions && promotions.free_products) {
        promotions.free_products.forEach((product) => {
          const promotionProduct = {
            option: [
              {
                product_option_value_id: product.productid,
              },
            ],
            discount_percent: 0,
            price: product.unit_price,
            pu: product.unit_price,
            product_id: null,
            quantity: product.quantity,
            productid: product.productid,
            comment: '',
          };
          products.push(promotionProduct);
        });
      }
    }

    const data = {
      crmid,
      products,
      sin_impuestos: discountTaxes ? 1 : 0,
      subtotal: cart.subTotal,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      treebesdireccionenvioid: envio,
      treebesdireccionfacturaid: factura,
      comment: cart.description,
      order_status: quoteState,
    };

    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(`${this.url}/Quotes`, this.credentials);
      const json = await response.json();
      const information = json.success ? `Guardado Exitoso ${json.result.salesorder_no}` : json.error.message;
      const message = { ...json.message, information };
      return { ...json, message };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getAll = async (page = 1, search = '') => {
    try {
      const response = await fetch(`${this.url}/list/Quotes/?page=${page}&search=${search}`, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log({ cotizarGetAll: err });
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  getFilteredQuotes = async (filters) => {
    try {
      const response = await fetch(`${this.url}/list/Quotes/?search_col=${filters}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: `Error en la petición del producto: ${err}` };
    }
  };

  getQuote = async (id) => {
    try {
      const response = await fetch(`${this.url}/id/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default CotizarAPI;
