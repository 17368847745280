/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { CurrentRefinements, SortBy } from 'react-instantsearch';
import ToggleSwitch from './ToggleSwitch';

const ProductsSubHeader = ({ searchIndex }) => {
  const transformItems = (items) => {
    const customItems = [];
    for (const item of items) {
      if (item.label === 'unit_price') {
        customItems.push({ ...item, label: 'Precio' });
        continue;
      }
      if (item.label === 'discount') {
        customItems.push({ ...item, label: 'Descuento' });
        continue;
      }
      if (item.attribute.includes('alm_')) {
        continue;
      }
      if (item.attribute.includes('hierarchical_categories')) {
        customItems.push({ ...item, label: 'Categorias' });
        continue;
      }
      customItems.push(item);
    }

    return customItems;
  };
  return (
    <div style={{
      padding: 10, display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '15px', backgroundColor: '#f6f6f7',
    }}
    >
      <SortBy
        defaultValue={`${searchIndex}`}
        items={[
          // Add a index by order prices
          { label: 'Por defecto', value: `${searchIndex}` },
          { label: 'Lo mas vendido', value: `${searchIndex}:bestseller_rank:desc` },
          { label: 'Trendy', value: `${searchIndex}:trendy_score:desc` },
          { label: 'Descuento', value: `${searchIndex}:discount:desc` },
          { label: 'Menor precio', value: `${searchIndex}:unit_price_included_tax:asc` },
          { label: 'Mayor precio', value: `${searchIndex}:unit_price_included_tax:desc` },
          { label: 'Nombre A-Z', value: `${searchIndex}:entity_label:asc` },
          { label: 'Nombre Z-A', value: `${searchIndex}:entity_label:desc` },
          { label: 'Codigo', value: `${searchIndex}:sku:desc` },
        ]}
      />
      <ToggleSwitch />
      <CurrentRefinements transformItems={transformItems} />
      {/* <span>{searchIndex}</span> */}
    </div>
  );
};


ProductsSubHeader.propTypes = {
  searchIndex: PropTypes.string.isRequired,
};

export default ProductsSubHeader;
