import { setStorage, removeStorage } from '../../helpers/pricebook';

export const ADD_PRICEBOOK = 'ADD_PRICEBOOK';
export const REMOVE_PRICEBOOK = 'REMOVE_PRICEBOOK';
export const REGENERATING_INDEX = 'REGENERATING_INDEX';
export const FINISH_REGENERATING_INDEX = 'FINISH_REGENERATING_INDEX';
export const MEILI_INDEX = 'MEILI_INDEX';

export function addPricebook(pricebook) {
  setStorage(pricebook);
  return (dispatch) => {
    dispatch({
      type: ADD_PRICEBOOK,
      pricebook,
    });
  };
}

export function changeMeiliIndex(meiliIndex) {
  return (dispatch) => {
    dispatch({
      type: MEILI_INDEX,
      meiliIndex,
    });
  };
}

export function removePricebook() {
  removeStorage();
  return (dispatch) => {
    dispatch({ type: REMOVE_PRICEBOOK });
  };
}

export function regenerateIndex() {
  removeStorage();
  return (dispatch) => {
    dispatch({ type: REGENERATING_INDEX });
  };
}

export function finishRegenerateIndex() {
  removeStorage();
  return (dispatch) => {
    dispatch({ type: FINISH_REGENERATING_INDEX });
  };
}
