import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditCustomer from '../../../Customer/components/EditCustomer';
import {
  addAddresForCustomer, deleteAddressByCustomer, editAddresForCustomer, editCustomer, editEnvioFactura, fetchAddressByCustomer,
} from '../../../../redux/actions/customerActions';
import { toggleModal } from '../../../../redux/actions/modalActions';

const EditCustomerModal = () => {
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const modals = useSelector(state => state.modals);
  const customer = useSelector(state => state.customer);
  const { selectedCustomer } = customer;
  const dispatchEditCustomer = (custome, address = []) => dispatch(editCustomer(custome, address));
  const dispatchToggleModal = modal => dispatch(toggleModal(modal));
  const dispatchGetAddressCustomer = id => dispatch(fetchAddressByCustomer(id));
  const dispatchEditAddressByCustomer = (id, address) => dispatch(editAddresForCustomer(id, address));
  const dispatchAddAddressByCustomer = (id, address) => dispatch(addAddresForCustomer(id, address));
  const dispatchEditEnvioFactura = cliente => dispatch(editEnvioFactura(cliente));
  const dispatchDeleteAddressByCustomer = (customerId, crmid, label) => dispatch(deleteAddressByCustomer(customerId, crmid, label));
  return (
    <EditCustomer
      title={`Editar al cliente: ${selectedCustomer.accountname}`}
      loads={loads}
      modals={modals}
      address={customer.address || []}
      selectedCustomer={selectedCustomer}
      dispatchToggleModal={dispatchToggleModal}
      dispatchEditCustomer={dispatchEditCustomer}
      dispatchGetAddressCustomer={dispatchGetAddressCustomer}
      dispatchEditAddressByCustomer={dispatchEditAddressByCustomer}
      dispatchAddAddressByCustomer={dispatchAddAddressByCustomer}
      dispatchEditEnvioFactura={dispatchEditEnvioFactura}
      deleteAddress={dispatchDeleteAddressByCustomer}
    />
  );
};

export default EditCustomerModal;
