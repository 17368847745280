import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Menu,
  Button,
  MenuItem,
  ListItem,
  ListItemIcon,
  Checkbox,
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { toolsHelper } from '../../../../../helpers';

import InputMoney from '../../../../Input/Money';

const RefundFdeItem = ({
  item,
  metodosPagos,
  onChangeMonto,
  selectMethod,
  selectSingle,
  selectSub,
  tab,
}) => {
  const orderSelected = useSelector(state => state.cart.orderSelected);
  const [anchorMet, setAnchorMet] = React.useState(null);
  const [anchorSub, setAnchorSub] = React.useState(null);

  return (
    <ListItem>
      <ListItemIcon className="mr-2">
        <Checkbox
          className="p-1"
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={item.select}
          onClick={() => selectSingle(item.id)}
        />
      </ListItemIcon>
      <div className="refund_toRefund_container">
        <div className="d-flex align-items-center">
          <Button
            aria-controls="met-menu"
            aria-haspopup="true"
            onClick={e => setAnchorMet(e.currentTarget)}
            disabled={(item.metodo === 'LBL_PUNTOS_RECOMPENSA' || item.metodo === 'Puntos Recompensa') && !item.edit}
          >
            {item.metodo === 'LBL_PUNTOS_RECOMPENSA' ? 'Puntos Recompensa' : item.metodo}
          </Button>
          <Menu
            id="met-menu"
            anchorEl={anchorMet}
            keepMounted
            open={Boolean(anchorMet)}
            onClose={() => setAnchorMet(null)}
          >
            {metodosPagos.map(met => (
              <MenuItem
                key={`key_${met.key}`}
                selected={met.method === item.metodo}
                onClick={() => {
                  setAnchorMet(null);
                  selectMethod(met.key, item.id);
                }}
              >
                {met.method === 'LBL_PUNTOS_RECOMPENSA' ? 'Puntos Recompensa' : met.method}
              </MenuItem>
            ))}
          </Menu>
          <ArrowRight className="mx-1" />
          <Button
            aria-controls="sub-menu"
            aria-haspopup="true"
            onClick={e => setAnchorSub(e.currentTarget)}
            disabled={item.metodo === 'LBL_PUNTOS_RECOMPENSA' || item.metodo === 'Puntos Recompensa'}
          >
            {item.sub.entity_label}
          </Button>
          <Menu
            id="sub-menu"
            anchorEl={anchorSub}
            keepMounted
            open={Boolean(anchorSub)}
            onClose={() => setAnchorSub(null)}
          >
            {item.method.body.map(sub => (
              <MenuItem
                key={`key_sub_${sub.crmid}`}
                selected={sub.crmid === item.tcybid}
                onClick={() => {
                  setAnchorSub(null);
                  selectSub(item.id, sub.crmid);
                }}
              >
                {sub.entity_label}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className="d-flex flex-column justify-content-between">
          {item.edit ? (
            <InputMoney
              disabled={(item.metodo === 'LBL_PUNTOS_RECOMPENSA' || item.metodo === 'Puntos Recompensa' || tab !== 0)}
              number={item.monto}
              onChange={(value) => {
                const { id } = item;
                let obj = {};
                if (value > parseFloat(orderSelected.total)) {
                  obj = { value: parseFloat(orderSelected.total), id };
                } else {
                  obj = { value, id };
                }
                onChangeMonto(obj);
              }}
            />
          ) : (
            <span className="fde_list_mont">
              {`$ ${toolsHelper.formatMoney(item.monto)} ${item.sub?.currency_code}`}
            </span>
          )}
        </div>
      </div>
    </ListItem>
  );
};

RefundFdeItem.propTypes = {
  item: PropTypes.object.isRequired,
  metodosPagos: PropTypes.array.isRequired,
  onChangeMonto: PropTypes.func,
  selectMethod: PropTypes.func,
  selectSingle: PropTypes.func,
  selectSub: PropTypes.func,
};

RefundFdeItem.defaultProps = {
  onChangeMonto: () => {},
  selectMethod: () => {},
  selectSingle: () => {},
  selectSub: () => {},
};

export default RefundFdeItem;
