/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../../../../../hooks/use-debounce';

const QtyInput = ({
  product,
  noValidaExistencia,
  previosQtySaved,
  handleChangeQuantity,
}) => {
  const [inputValue, setInputValue] = useState(0);
  const debouncevalue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (inputValue) {
      handleChangeQuantity(product.crmid, inputValue);
    }
  }, [debouncevalue]);


  return (
    <input
      style={{ minWidth: 50 }}
      className="qty-input"
      id={`key_${product.crmid}`}
      type="number"
      min={0}
      max={
      noValidaExistencia
        ? product.quantity
        : product.quantity - previosQtySaved(product) || 0
    }
      data-id={product.crmid}
      defaultValue={
      product.sendQuantity - previosQtySaved(product) < 0
        ? 0
        : product.sendQuantity - previosQtySaved(product) || 0
    }
      data-productid={product.productid}
      form="novalidatedform"
      onChange={e => setInputValue(e.target.value)}
      disabled={
      noValidaExistencia
        ? false
        : product.qtyinstock <= 0
          ? true
          : product.productcode === 'ENVIO' || !product.sendQuantityMax
    }
      value={
      noValidaExistencia
        ? product.sendQuantity
        : product.qtyinstock === 0
          ? 0
          : product.quantity === product.sendQuantity
            ? product.sendQuantity - previosQtySaved(product)
            : product.sendQuantity
    }
    />
  ); };

export default QtyInput;
