import {
  ADD_PRICEBOOK, REMOVE_PRICEBOOK, MEILI_INDEX, REGENERATING_INDEX, FINISH_REGENERATING_INDEX,
} from '../actions/priceBookActions';
import { getStorage } from '../../helpers/pricebook';

const getDefaultState = () => ({
  activePriceBook: { ...getStorage() },
  meiliIndex: '',
  isIndexing: false,
});

const setPriceBook = (state, action) => ({
  ...state,
  activePriceBook: { ...action.pricebook },
});

const setMeiliIndex = (state, action) => ({
  ...state,
  meiliIndex: action.meiliIndex || '',
});

export default function (state = getDefaultState(), action) {
  const { type } = action;
  switch (type) {
    case ADD_PRICEBOOK:
      return setPriceBook(state, action);
    case MEILI_INDEX:
      return setMeiliIndex(state, action);
    case REMOVE_PRICEBOOK:
      return { ...getDefaultState() };
    case REGENERATING_INDEX:
      return { ...state, isIndexing: true };
    case FINISH_REGENERATING_INDEX:
      return { ...state, isIndexing: false };
    default:
      return state;
  }
}
