import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateCustomer from '../../../Customer/components/CreateCustomer';
import { createCustomer } from '../../../../redux/actions/customerActions';
import { toggleModal } from '../../../../redux/actions/modalActions';

const CreateCustomerModal = () => {
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const modals = useSelector(state => state.modals);
  const customer = useSelector(state => state.customer);
  const dispatchCreateCustomer = (custome, address = []) => dispatch(createCustomer(custome, address));
  const dispatchToggleModal = modal => dispatch(toggleModal(modal));
  return (
    <CreateCustomer
      loads={loads}
      modals={modals}
      customer={customer}
      dispatchToggleModal={dispatchToggleModal}
      dispatchCreateCustomer={dispatchCreateCustomer}
    />
  );
};

export default CreateCustomerModal;
