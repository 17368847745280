/* eslint-disable import/no-cycle */
import React from 'react';
import { isMobile } from 'react-device-detect';
import CartSellAction from '../../../../components/rkr2-components/cart-sell-actions/CartSellAction';

const PosCartContainerActionV2 = () => (
  <div className="w-100">
    <div
      className={
            isMobile
              ? 'py-2 px-3 h-100 d-flex align-items-center '
              : 'h-100 w-100 d-flex align-items-end'
          }
    >
      <CartSellAction />
    </div>
  </div>
);

export default PosCartContainerActionV2;
