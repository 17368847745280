/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable camelcase */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

const Promotions = () => {
  const dispatch = useDispatch();
  const [activePromo, setActivePromo] = React.useState(false);
  const cart = useSelector(state => state.cart);
  const tabs = useSelector(state => state.tabs);
  const {
    promotions, cartProductsByArray, activePromotions, orderSelected, productsWidthpromotions,
  } = cart;
  const { posTypeTab } = tabs;
  const productPromotions = cartProductsByArray.length ? cartProductsByArray.filter(prod => prod.unit_price === 0.01 && prod.treebespromocionesid && prod.treebespromocionesid !== '0') : [];

  const orderNo = (orderSelected && orderSelected.order)
    ? (orderSelected.order.salesorder_no || orderSelected.order.label)
    : '';
  const isOrder = Boolean(orderNo);
  const isCotización = orderSelected.order && orderSelected.order.quote_no ? true : false;

  React.useEffect(() => {
    setActivePromo(activePromotions);
  }, [activePromotions]);

  return (
    <>
      {
        (isOrder || isCotización) && !productsWidthpromotions.length
          ? (productPromotions.length ? productPromotions.map(prod => (
            <TableRow tabIndex={0} className="bg-light" key={prod.productid}>
              <>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {prod.productname}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {prod.quantity}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {prod.unit_price ? prod.unit_price === 0.01 ? 'Gratis' : prod.unit_price : ''}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0  px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    <span>
                      {
                        prod.unit_price && prod.unit_price !== 0.01 && (
                          '$'
                        )
                      }
                      {prod.unit_price ? prod.unit_price === 0.01 ? 'Gratis' : prod.unit_price : ''}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    <FontAwesomeIcon
                      icon="certificate"
                      style={{ width: '21px', heigth: '21px', color: '#23a1db' }}
                    />
                  </div>
                </TableCell>
              </>

            </TableRow>
          )) : '')
          : promotions && promotions.free_products && promotions.free_products.length ? promotions.free_products.map(prod => (
            <TableRow tabIndex={0} className="bg-light" key={prod.productid}>
              <>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {prod.name}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {prod.quantity}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {prod.unit_price}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0  px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    <span>
                      $
                      {prod.unit_price}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="m-0 px-0"
                >
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    <FontAwesomeIcon
                      icon="certificate"
                      style={{ width: '21px', heigth: '21px', color: '#23a1db' }}
                    />
                  </div>
                </TableCell>
              </>

            </TableRow>
          )) : ''
    }
    </>

  ); };


export default Promotions;
