/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removePricebook } from '../../../redux/actions/priceBookActions';
import { selectStore } from '../../../redux/actions/authUserActions';
import StoreSelect from '../../Store/StoreSelect';

const SelectStore = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const dispatchSelectStore = async (element, pos) => {
    const {
      crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, forzaenvios, currencyid,
    } = element.dataset;
    await dispatch(removePricebook());
    await dispatch(selectStore(crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, pos, forzaenvios, currencyid));
    /* await dispatch(fetchAllProducts());
        await dispatch(fetchAllOrders()); */
  };
  return (
    <>
      {
        authUser && (
          <StoreSelect authUser={authUser} dispatchSelectStore={dispatchSelectStore} showSelectMessage={false} />
        )
      }
    </>
  );
};

export default SelectStore;
