/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
import {
  CircularProgress,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import React, {
  useEffect, useLayoutEffect, useReducer, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import moment from 'moment';
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import CustomSelect from '../../../CustomInputs/components/custom-select';
import CustomDatePicker from '../../../CustomInputs/components/custom-date-picker';
import './order-table.scss';
import { fetchOrderView } from '../../../../redux/actions/orderActions';
import OrdersPaginator from '../../../Pagination/orders';
import OrderTableItem from './components/OrderTableItem';
import { openModal } from '../../../../redux/actions/modalActions';
import {
  setActiveOrderFilters,
  setActiveQuoteFilters,
} from '../../../../redux/actions/filtersActions';
import OrderStatus from '../../../rkr2-components/status-menu/OrderStatus';
import {
  getColorStatusFDE,
  getColorStatusMDA,
  getColorStatusQuote,
  geteColorSosStatus,
} from '../../../../helpers/tools';

const INITIAL_STATE = {};

const formReducer = (state, event) => ({
  ...state,
  [event.name]: event.value,
});

const OrderTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);
  const loads = useSelector(state => state.loads);
  const tabs = useSelector(state => state.tabs);
  const cart = useSelector(state => state.cart);
  const { order: filterOrder, quote: filterQuote } = useSelector(
    state => state.filters,
  );
  const authUser = useSelector(state => state.authUser);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');
  const [reset, setReset] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const { headers, data, page } = useSelector(state => state.order.orderData);
  const listSelected = useSelector(state => state.order.orderListSelected);
  const {
    user: {
      config: { store, pos_sell_tab },
    },
  } = authUser;
  const { orderSelected } = cart;
  const { posTypeTab } = tabs;

  const [formData, setFormData] = useReducer(formReducer, INITIAL_STATE);
  const orderAndQuoteStatusKeys = [
    'sostatus',
    'quotestage',
    'estado_fde',
    'estado_mda',
  ];

  const handleChange = (event) => {
    setShowReset(true);
    const isCheckbox = event.nativeEvent.target.type === 'checkbox';
    setFormData({
      name: event.nativeEvent.target.name,
      value: isCheckbox ? event.nativeEvent.target.checked : event.nativeEvent.target.value,
    });
    // Reset filter
    if (!event.nativeEvent.target.value) {
      if (posTypeTab === 'Historico') {
        const resetFilters = filterOrder.filter(filter => filter[0] !== event.nativeEvent.target.name);
        dispatch(setActiveOrderFilters(resetFilters));
      }

      if (posTypeTab === 'Cotizaciones') {
        const resetFilters = filterQuote.filter(filter => filter[0] !== event.nativeEvent.target.name);
        dispatch(setActiveQuoteFilters(resetFilters));
      }
    }
  };

  const handleChangeDate = (name, date) => {
    setShowReset(true);
    let formattedDate = null;
    if (date[0]) {
      formattedDate = [
        moment(date[0]).format('DD-MM-YYYY'),
        date[1] ? moment(date[1]).format('DD-MM-YYYY') : null,
      ];
    }
    setFormData({
      name,
      value: formattedDate,
    });
  };
  const handleChangePickList = (selectedOption, name) => {
    setShowReset(true);
    const dataList = selectedOption
      ? selectedOption.map(item => item.value)
      : [];
    if (!dataList.length) {
      if (posTypeTab === 'Historico') {
        const resetFilters = filterOrder.filter(filter => filter[0] !== name);
        dispatch(setActiveOrderFilters(resetFilters));
      }

      if (posTypeTab === 'Cotizaciones') {
        const resetFilters = filterQuote.filter(filter => filter[0] !== name);
        dispatch(setActiveQuoteFilters(resetFilters));
      }
    }
    setFormData({
      name,
      value: dataList,
    });
  };

  const getPickListFilters = (name) => {
    const list = formData[name];
    const defaultPickListValue = [];
    const listOfFilterValue = list || [];
    const selectedHeader = headers.find(head => head.name === name);
    if (
      selectedHeader
      && listOfFilterValue.length
      && (selectedHeader.search_type === 'picklist'
        || selectedHeader.search_type === 'owner')
    ) {
      listOfFilterValue.map((value) => {
        const listValue = selectedHeader.picklist_values.find(
          item => item.value === value,
        );
        if (listValue) {
          defaultPickListValue.push(listValue);
        }
      });
    }

    return defaultPickListValue;
  };

  const getDateFilters = (name) => {
    const splitDateRange = formData[name] && formData[name].includes(',')
      ? formData[name].split(',')
      : formData[name];
    let range = [null, null];

    if (splitDateRange) {
      let formatDate = [];
      let formatDateEnd = [];
      switch (splitDateRange.length) {
        case 0:
          range = [null, null];
          break;
        case 1:
          if (splitDateRange[0]) {
            formatDate = splitDateRange[0].split('-');
            range = [
              splitDateRange[0]
                ? moment(
                  `${formatDate[1]}-${formatDate[0]}-${formatDate[2]}`,
                ).toDate()
                : null,
              null,
            ];
          }
          break;
        default:
          if (splitDateRange[0] && splitDateRange[1]) {
            formatDate = splitDateRange[0].split('-');
            formatDateEnd = splitDateRange[1].split('-');
            range = [
              splitDateRange[0]
                ? moment(
                  `${formatDate[1]}-${formatDate[0]}-${formatDate[2]}`,
                ).toDate()
                : null,
              splitDateRange[1]
                ? moment(
                  `${formatDateEnd[1]}-${formatDateEnd[0]}-${formatDateEnd[2]}`,
                ).toDate()
                : null,
            ];
          }
          break;
      }
    }

    return range;
  };

  const setFormDataByActiveFilters = () => {
    // filterQuote
    if (posTypeTab === 'Historico') {
      if (filterOrder && filterOrder.length && headers && headers.length) {
        // eslint-disable-next-line array-callback-return
        filterOrder.map((filter) => {
          const head = headers.find(header => header.name === filter[0]);
          if (head) {
            if (
              head.native_type === 'picklist'
              || head.native_type === 'owner'
            ) {
              const pickListValue = {
                name: head.name,
                value: filter[2].includes(',')
                  ? filter[2].split(',')
                  : [filter[2]],
              };
              setFormData(pickListValue);
            } else {
              setFormData({
                name: head.name,
                value: (formData[head.name] && formData[head.name] !== filter[2]) ? formData[head.name] : filter[2],
              });
            }
          }
        });
      }
    }

    if (posTypeTab === 'Cotizaciones') {
      if (filterQuote && filterQuote.length) {
        // eslint-disable-next-line array-callback-return
        filterQuote.map((filter) => {
          const head = headers.find(header => header.name === filter[0]);
          if (head) {
            if (
              head.native_type === 'picklist'
              || head.native_type === 'owner'
            ) {
              setFormData({
                name: head.name,
                value: filter[2].includes(',')
                  ? filter[2].split(',')
                  : [filter[2]],
              });
            } else {
              setFormData({
                name: head.name,
                value: filter[2],
              });
            }
          }
        });
      }
    }
  };

  const getSearchParams = () => {
    const searchParams = [];
    // eslint-disable-next-line no-use-before-define
    setFormDataByActiveFilters();
    const keyValues = Object.keys(formData);
    // Solo agrega el almacen si son ordenes no en cotizaciones
    if (posTypeTab === 'Historico') {
      searchParams.push(['treebesalmid', 'c', `${store.name}`]);
    }

    keyValues.map((key) => {
      const head = headers.find(header => header.name === key);
      if (
        key !== 'undefined'
        && formData[key]
        && head
        && formData[key].length > 0
        && !orderAndQuoteStatusKeys.includes(key)
      ) {
        searchParams.push([`${key}`, `${head.comparator}`, `${formData[key]}`]);
      }
    });

    if (posTypeTab === 'Historico') {
      if (filterOrder && filterOrder?.length) {
        filterOrder.forEach((element) => {
          // eslint-disable-next-line array-callback-return
          searchParams.map((filterSearch) => {
            if (!filterSearch.includes(element[0])) {
              searchParams.push(element);
            }
          });
        });
      }
      dispatch(setActiveOrderFilters(searchParams));
    }

    if (posTypeTab === 'Cotizaciones') {
      if (filterQuote && filterQuote?.length) {
        filterQuote.forEach((element) => {
          if (!searchParams.length) {
            searchParams.push(element);
          } else {
            // eslint-disable-next-line array-callback-return
            searchParams.map((filterSearch) => {
              if (!filterSearch.includes(element[0])) {
                searchParams.push(element);
              }
            });
          }
        });
      }
      dispatch(setActiveQuoteFilters(searchParams));
    }

    if (!keyValues.length) {
      setFormDataByActiveFilters();
    }

    return searchParams;
  };

  const search = () => {
    setActualPage(1);
    const searchParams = getSearchParams();
    dispatch(fetchOrderView(listSelected?.cvid, 1, searchParams, orderBy, order));
  };

  const searchPagination = () => {
    const searchParams = getSearchParams();
    dispatch(
      fetchOrderView(
        listSelected?.cvid,
        actualPage,
        searchParams,
        orderBy,
        order,
      ),
    );
  };

  const searchWithEnter = (e) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  const updatePage = (page) => {
    setActualPage(page);
  };

  const handleRequestSort = async (property) => {
    let newOrder = 'desc';
    await setOrderBy(property);

    if (property === orderBy && order === 'desc') {
      newOrder = 'asc';
    }
    await setOrder(newOrder);
    const searchParams = getSearchParams();
    dispatch(
      fetchOrderView(
        listSelected?.cvid,
        actualPage,
        searchParams,
        property,
        newOrder,
      ),
    );
  };

  const clearOrder = async () => {
    await setOrderBy('');
    search();
  };

  const clearFilter = async () => {
    clearTimeout();
    await setShowReset(false);
    await setReset(true);

    if (posTypeTab === 'Historico') {
      dispatch(setActiveOrderFilters([]));
    } else {
      dispatch(setActiveQuoteFilters([]));
    }

    Object.keys(formData).map((key) => {
      setFormData({
        name: key,
        value: null,
      });
    });
    await setReset(false);
  };

  const inputType = (header) => {
    switch (header.native_type) {
      case 'picklist':
      case 'owner':
        return (
          <CustomSelect
            options={header.picklist_values}
            isMulti={header.picklist_multiple}
            handleChange={handleChangePickList}
            name={header.name}
            defaulValue={getPickListFilters(header.name)}
          />
        );
      case 'date':
      case 'datetime':
        return (
          <CustomDatePicker
            onChange={handleChangeDate}
            headerName={header.name}
            range={getDateFilters(header.name)}
          />
        );
      default:
        return (
          <input
            className="custom-inputs"
            type="text"
            name={header.name}
            onChange={e => handleChange(e)}
            onKeyUp={e => searchWithEnter(e)}
            value={formData[header?.name] || ''}
          />
        );
    }
  };

  useEffect(() => {
    if (listSelected && page && page !== actualPage) {
      searchPagination();
    }
  }, [actualPage]);

  useEffect(() => {
    if (pos_sell_tab && Object.keys(orderSelected).length > 0) {
      dispatch(openModal('cart'));
    }
  }, [orderSelected]);

  useEffect(() => {
    search();
  }, [store]);

  useEffect(() => {
    if (loads.refreshOrdersIsLoading) {
      search();
    }
  }, [loads.refreshOrdersIsLoading]);

  useEffect(() => {
    if (tabs.posTypeTab === 'Historico' || tabs.posTypeTab === 'Cotizaciones') {
      Object.keys(formData).map((key) => {
        setFormData({
          name: key,
          value: null,
        });
      });
      setFormDataByActiveFilters();
    }
  }, [headers, tabs]);

  useLayoutEffect(() => {
    const orderId = location.pathname.split('/')[3];
    if (orderId && orderSelected?.crmid) {
      dispatch(openModal('cart'));
    }
  }, [modals?.cartModalIsOpen]);

  return (
    <>
      <div style={{ overflow: 'auto', height: 'calc(100vh - 225px)' }}>
        {headers && headers.length && (
          <Table style={{ width: '100%', overflowX: 'auto' }}>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 2,
              }}
            >
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className="fixed-cell order-cell">Action</TableCell>
                {['Historico', 'Cotizaciones'].includes(posTypeTab) && headers.some(head => orderAndQuoteStatusKeys.includes(head.name)) && (
                  <TableCell className="order-cell">Estados</TableCell>
                )}
                {headers.map(
                  header => !orderAndQuoteStatusKeys.includes(header.name) && (
                  <TableCell className="order-cell" key={header.name}>
                    {orderBy === header.name && (
                    <Close
                      style={{
                        fontSize: 20,
                        marginRight: 10,
                        cursor: 'pointer',
                      }}
                      onClick={clearOrder}
                    />
                    )}
                    <TableSortLabel
                      className={orderBy === header.name ? 'sortable' : ''}
                      active={orderBy === header.name}
                      direction={order}
                      onClick={() => handleRequestSort(header.name)}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                  ),
                )}
              </TableRow>
              <TableRow
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  overflowX: 'auto',
                  position: 'relative',
                  top: -1,
                }}
              >
                <TableCell className="fixed-cell fixed-cell-top order-cell p-5">
                  <div className="d-flex">
                    {loads.listIsLoading || loads.orderIsLoading ? (
                      <CircularProgress />
                    ) : (
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => search()}
                      >
                        <SearchIcon />
                      </Fab>
                    )}
                    {((posTypeTab === 'Historico' && filterOrder.length > 1)
                      || (posTypeTab === 'Cotizaciones'
                        && filterQuote.length > 0)) && (
                        <IconButton
                          aria-label="Delete"
                          style={{ backgroundColor: '#f2f2f2' }}
                          onClick={() => clearFilter()}
                        >
                          <Close fontSize="small" style={{ color: 'red' }} />
                        </IconButton>
                    )}
                  </div>
                </TableCell>
                {headers.some(head => orderAndQuoteStatusKeys.includes(head.name)) && (
                  <TableCell className="fixed-cell-top order-cell">
                    {/* <span>Estado general</span> */}
                    <div className="d-flex justify-center">
                      {posTypeTab === 'Historico' && (
                        <>
                          {headers.some(head => head.name === 'sostatus') && (
                            <OrderStatus
                              statusName="sostatus"
                              statusColorFn={geteColorSosStatus}
                              statusIcon={faFileInvoice}
                              filterList={filterOrder}
                              setFilterStoreFn={setActiveOrderFilters}
                            />
                          )}
                          {headers.some(
                            head => head.name === 'estado_fde',
                          ) && (
                            <OrderStatus
                              statusName="estado_fde"
                              statusColorFn={getColorStatusFDE}
                              statusIcon={faHandHoldingUsd}
                              filterList={filterOrder}
                              setFilterStoreFn={setActiveOrderFilters}
                            />
                          )}
                          {headers.some(
                            head => head.name === 'estado_mda',
                          ) && (
                            <OrderStatus
                              statusName="estado_mda"
                              statusColorFn={getColorStatusMDA}
                              statusIcon={faDolly}
                              filterList={filterOrder}
                              setFilterStoreFn={setActiveOrderFilters}
                            />
                          )}
                        </>
                      )}
                      {posTypeTab === 'Cotizaciones' && (
                        <>
                          {headers.some(
                            head => head.name === 'quotestage',
                          ) && (
                            <OrderStatus
                              statusName="quotestage"
                              statusColorFn={getColorStatusQuote}
                              statusIcon={faFileInvoice}
                              filterList={filterQuote}
                              setFilterStoreFn={setActiveQuoteFilters}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                )}
                {reset === false
                  && headers.map(
                    item => !orderAndQuoteStatusKeys.includes(item.name) && (
                    <TableCell className="order-cell">
                      <div>{inputType(item)}</div>
                    </TableCell>
                    ),
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0 ? (
                data.map(row => (
                  <OrderTableItem
                    row={row}
                    headers={headers}
                    searchPagination={() => searchPagination()}
                  />
                ))
              ) : (
                <TableRow />
              )}
            </TableBody>
          </Table>
        )}
      </div>
      <div>{data && <OrdersPaginator updatePage={updatePage} />}</div>
    </>
  );
};

export default OrderTable;
