/* eslint-disable camelcase */
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

const FilterMenuToggle = () => {
  const authUser = useSelector(state => state.authUser);
  const { user: { meilisearch_attributes } } = authUser;
  const openFiltersEvent = () => {
    window.dispatchEvent(new CustomEvent('openMyFilters'));
  };
  return (
    <IconButton
      onClick={openFiltersEvent}
      aria-label="Delete"
      disabled={!meilisearch_attributes || meilisearch_attributes.length === 0}
      style={{
        borderRadius: '5px',
        height: 40,
        border: '1px solid #b8b8b8',
      }}
    >
      <FontAwesomeIcon
        icon={faFilter}
        style={{ fontSize: '16px' }}
      />
    </IconButton>
  );
};

export default FilterMenuToggle;
