/* eslint-disable camelcase */

// Define the enum for print types
const PrintType = {
  ORDER: 'order',
  QUOTE: 'quote',
};

function createPrintConfig(userStore, type) {
  const { config, almacenes } = userStore;
  const templateKey = type === PrintType.ORDER ? 'pos_plantilla_pred_sell' : 'pos_plantilla_pred_cot';
  const printAfterKey = type === PrintType.ORDER ? 'pos_print_after_sell' : 'pos_print_after_quo';

  if (!config || !userStore[templateKey] || !almacenes) return null;
  if (config[printAfterKey] !== '1') return null;

  const storeSelected = almacenes.find(almacen => almacen.crmid === config.store.crmid);
  if (!storeSelected?.printers?.length) return null;

  return {
    print_templateid: userStore[templateKey],
    print_impresoraid: storeSelected.printers[0].printer_id,
  };
}

export const createPrintOrder = userStore => createPrintConfig(userStore, PrintType.ORDER);
export const createPrintQuote = userStore => createPrintConfig(userStore, PrintType.QUOTE);

export default {
  createPrintOrder,
  createPrintQuote,
  PrintType,
};
