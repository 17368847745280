/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Customer from '../../Customer/index';
import CartStateOrder from './CartStateOrder';
import CartStateControls from './CartStateControls';
import OrderStatusBlock from '../../ActualOrder/components/OrderStatusBlock';
import CartShippingAction from '../../rkr2-components/cart-shipping-action';
import useRewardPoints from '../../../hooks/useRewardPoints';
import UserCartInfo from '../../rkr2-components/cart/UserCartInfo';

const CartState = ({
  alertProducts,
  authUser,
  cart,
  changeCurrentOrderStatus,
  closeMailModal,
  currentOrderStatus,
  customer,
  dispatchAdjustStock,
  dispatchChangeSalesOrderStatus,
  dispatchCloseCheckOutModal,
  dispatchCotizar,
  dispatchCreateSell,
  dispatchEditOrder,
  dispatchOpenCheckOutModal,
  dispatchOpenDiscountModal,
  dispatchOpenFileModal,
  dispatchOpenTicketsModal,
  dispatchOpenTimbrarModal,
  dispatchSetOrderStatus,
  editOrder,
  editarCotizacion,
  enableEdit,
  getDocumentOrder,
  handleEditOrder,
  handleMakeBill,
  loads,
  makeBill,
  modals,
  openCheckOutRefunModal,
  openDescriptionModal,
  openEmailModal,
  openRefundModal,
  orderDate,
  orderId,
  orderNo,
  orderStatuses,
  posType,
  posCheckoutBill,
  saveSell,
  sendEmail,
  sendEmailIsLoading,
  sendEmailIsOpen,
  shouldRefoundMoney,
  shouldRefoundProducts,
  tabs,
  timbrar,
  dispatchSetOrderCustomer,
}) => {
  const customerLocal = useSelector(state => state.customer);
  const { points, getCustomerPoints } = useRewardPoints();
  React.useEffect(() => {
    if (posType === 'Vender' && !!customerLocal.selectedCustomer.crmid) {
      getCustomerPoints();
    }
  }, [posType, customerLocal.selectedCustomer]);
  return (
    <div className="w-100">
      <div className="cart-state">
        <div style={{ display: 'flex' }}>
          {
          posType === 'Cotizaciones' && (
            <CartStateOrder
              cart={cart}
              changeCurrentOrderStatus={changeCurrentOrderStatus}
              currentOrderStatus={currentOrderStatus}
              dispatchSetOrderStatus={dispatchSetOrderStatus}
              isCotizacion={posType === 'Cotizaciones'}
              loads={loads}
              orderNo={orderNo}
              orderStatuses={orderStatuses}
              posType={posType}
            />
          )
        }
          {
          posType === 'Historico' && (
            <OrderStatusBlock
              order={cart.orderSelected.order}
              tab={tabs.posTypeTab}
              searchPagination={() => {}}
            />
          )
        }
          {
          posType === 'Vender' && (
            <UserCartInfo customer={customer} points={points} />
          )
        }
        </div>
        <Customer />
        {
        posType === 'Vender' && (
          <CartShippingAction />
        )
      }
        <CartStateControls
          alertProducts={alertProducts}
          authUser={authUser}
          cart={cart}
          closeMailModal={closeMailModal}
          customer={customer}
          dispatchAdjustStock={dispatchAdjustStock}
          dispatchChangeSalesOrderStatus={dispatchChangeSalesOrderStatus}
          dispatchCloseCheckOutModal={dispatchCloseCheckOutModal}
          dispatchCotizar={dispatchCotizar}
          dispatchCreateSell={dispatchCreateSell}
          dispatchEditOrder={dispatchEditOrder}
          dispatchOpenCheckOutModal={dispatchOpenCheckOutModal}
          dispatchOpenDiscountModal={dispatchOpenDiscountModal}
          dispatchOpenFileModal={dispatchOpenFileModal}
          dispatchOpenTicketsModal={dispatchOpenTicketsModal}
          dispatchOpenTimbrarModal={dispatchOpenTimbrarModal}
          editOrder={editOrder}
          editarCotizacion={editarCotizacion}
          enableEdit={enableEdit}
          getDocumentOrder={getDocumentOrder}
          handleEditOrder={handleEditOrder}
          handleMakeBill={handleMakeBill}
          isCotizacion={posType === 'Cotizaciones'}
          isOrder={Boolean(orderNo)}
          isProducts={posType === 'Vender'}
          loads={loads}
          makeBill={makeBill}
          modals={modals}
          openCheckOutRefunModal={openCheckOutRefunModal}
          openDescriptionModal={openDescriptionModal}
          openEmailModal={openEmailModal}
          openRefundModal={openRefundModal}
          orderDate={orderDate}
          orderId={orderId}
          orderNo={orderNo}
          posCheckoutBill={posCheckoutBill}
          posType={posType}
          saveSell={saveSell}
          sendEmail={sendEmail}
          sendEmailIsLoading={sendEmailIsLoading}
          sendEmailIsOpen={sendEmailIsOpen}
          shouldRefoundMoney={shouldRefoundMoney}
          shouldRefoundProducts={shouldRefoundProducts}
          tabs={tabs}
          timbrar={timbrar}
          dispatchSetOrderCustomer={dispatchSetOrderCustomer}
        />
      </div>
    </div>
  ); };

CartState.propTypes = {
  alertProducts: PropTypes.array.isRequired,
  authUser: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  changeCurrentOrderStatus: PropTypes.func.isRequired,
  closeMailModal: PropTypes.func.isRequired,
  currentOrderStatus: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired,
  dispatchAdjustStock: PropTypes.func.isRequired,
  dispatchChangeSalesOrderStatus: PropTypes.func.isRequired,
  dispatchCloseCheckOutModal: PropTypes.func.isRequired,
  dispatchCotizar: PropTypes.func.isRequired,
  dispatchCreateSell: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchOpenCheckOutModal: PropTypes.func.isRequired,
  dispatchOpenDiscountModal: PropTypes.func.isRequired,
  dispatchOpenFileModal: PropTypes.func.isRequired,
  dispatchOpenTicketsModal: PropTypes.func.isRequired,
  dispatchOpenTimbrarModal: PropTypes.func.isRequired,
  dispatchSetOrderStatus: PropTypes.func.isRequired,
  editOrder: PropTypes.bool.isRequired,
  editarCotizacion: PropTypes.func.isRequired,
  enableEdit: PropTypes.bool.isRequired,
  getDocumentOrder: PropTypes.func.isRequired,
  handleEditOrder: PropTypes.func.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  makeBill: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  openCheckOutRefunModal: PropTypes.func.isRequired,
  openDescriptionModal: PropTypes.func.isRequired,
  openEmailModal: PropTypes.func.isRequired,
  openRefundModal: PropTypes.func.isRequired,
  orderDate: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  orderNo: PropTypes.string.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  posType: PropTypes.string.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
  saveSell: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  sendEmailIsLoading: PropTypes.bool.isRequired,
  sendEmailIsOpen: PropTypes.bool.isRequired,
  shouldRefoundMoney: PropTypes.bool.isRequired,
  shouldRefoundProducts: PropTypes.bool.isRequired,
  tabs: PropTypes.object.isRequired,
  timbrar: PropTypes.func.isRequired,
  dispatchSetOrderCustomer: PropTypes.func.isRequired,
};

export default CartState;

