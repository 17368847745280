/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import DeliverDialog from '../../../Deliver/components/DeliverDialog';
import {
  changeFieldValue,
  chnageComment,
  clearTable,
  selectEmptyToTable,
  selectToDelivered,
  selectToRefunded,
} from '../../../../redux/actions/tableActions';
import { getOrderPdf } from '../../../../redux/actions/posActions';
import { closeModal } from '../../../../redux/actions/modalActions';
import { setActivityToOrder } from '../../../../redux/actions/orderActions';
import { cotizar } from '../../../../redux/actions/cotizarActions';
import DropUpButton from '../DropUpButton';

const CartCotizatbtn = () => {
  const dispatch = useDispatch();
  const tabs = useSelector(state => state.tabs);
  const table = useSelector(state => state.table);
  const loads = useSelector(state => state.loads);
  const authUser = useSelector(state => state.authUser);
  const modals = useSelector(state => state.modals);
  const cart = useSelector(state => state.cart);
  const { enableAction } = table;
  const { posTypeTab } = tabs;
  const { pos_deliver_out_stock } = authUser.user.config;
  const deliverOutStock = Boolean(pos_deliver_out_stock === '1');
  const orderNo = cart.orderSelected && cart.orderSelected.order
    ? cart.orderSelected.order.salesorder_no || cart.orderSelected.order.label
    : '';
  const isOrder = posTypeTab === 'Historico' && Boolean(orderNo);

  const RenderDeliverDalog = () => (
    <DeliverDialog
      table={table}
      loads={loads}
      authUser={authUser}
      dispatchSelectEmpty={() => dispatch(selectEmptyToTable())}
      dispatchChangeComment={comment => dispatch(chnageComment(comment))}
      dispatchChangeFieldValue={obj => dispatch(changeFieldValue(obj))}
      dispatchSelectToRefunded={() => dispatch(selectToRefunded())}
      dispatchSelectToDelivered={() => dispatch(selectToDelivered())}
      deliverEnableAction={Boolean(enableAction || deliverOutStock)}
      getOrderDocument={data => dispatch(getOrderPdf(data))}
      isOpen={Boolean(modals.deliverModalIsOpen)}
      onClose={() => {
        dispatch(closeModal('deliver'));
        dispatch(clearTable());
      }}
      isLoading={Boolean(loads.orderIsLoading)}
      title={isOrder ? `Entregar a cliente de la Orden: ${orderNo}` : 'Entrega'}
      onSave={() => {
        dispatch(setActivityToOrder(cart.orderSelected.crmid, 'Entregar'));
      }}
    />
  );
  return (
    <>
      <DropUpButton
        color="#70BBFD"
        title="Cotizar"
        icon={faFileInvoice}
        onClick={cart.totalProducts > 0 ? () => dispatch(cotizar()) : () => {}}
      />
      <RenderDeliverDalog />
    </>
  );
};

export default CartCotizatbtn;
