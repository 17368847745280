import { faList, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePosV2View } from '../../../../../redux/actions/PosV2Actions';

const ProductListMode = () => {
  const { productView } = useSelector(state => state.posv2);
  const dispatch = useDispatch();
  const changeToGrid = () => {
    dispatch(changePosV2View('grid'));
  };
  const changeToList = () => {
    dispatch(changePosV2View('list'));
  };
  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid #b8b8b8',
        borderRadius: '5px',
        marginRight: '10px',
        height: 40,
      }}
    >
      <IconButton
        aria-label="Delete"
        onClick={changeToGrid}
        style={{
          borderRadius: 0,
          backgroundColor:
            productView === 'grid' ? '#24A1DB' : 'transparent',
          color: productView === 'grid' ? 'white' : '',
        }}
      >
        <FontAwesomeIcon icon={faTable} style={{ fontSize: '16px' }} />
      </IconButton>
      <div
        style={{ width: '1px', height: '100%', backgroundColor: '#b8b8b8' }}
      />
      <IconButton
        aria-label="Delete"
        onClick={changeToList}
        style={{
          borderRadius: 0,
          backgroundColor:
              !productView || productView === 'list'
                ? '#24A1DB'
                : 'transparent',
          color:
              !productView || productView === 'list'
                ? 'white'
                : '',
        }}
      >
        <FontAwesomeIcon icon={faList} style={{ fontSize: '16px' }} />
      </IconButton>
    </div>
  );
};

export default ProductListMode;
