import React from 'react';
import './ToggleSwitch.scss';
import { useToggleRefinement } from 'react-instantsearch';
import { useSelector } from 'react-redux';

const ToggleSwitch = () => {
  const authUser = useSelector(state => state.authUser);
  const { user: { config: { store } } } = authUser;
  const { refine, value } = useToggleRefinement({ attribute: `alm_${store.crmid}`, on: true });

  return (
    <div className="toggle-container">
      <span style={{ fontSize: 14 }}>Solo Existencias</span>
      <button
        type="button"
        onClick={() => {
          refine({ isRefined: value.isRefined });
        }}
        className={`toggle-btn ${value.isRefined ? 'toggled' : ''}`}
      >
        <div className="thumb" />
      </button>
    </div>
  ); };

export default ToggleSwitch;
