/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  List,
  Menu,
  Button,
  Drawer,
  Avatar,
  Divider,
  MenuItem,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Lock,
  ViewList,
  ViewModule,
} from '@material-ui/icons';

import preval from 'preval.macro';

import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import {
  NavLink,
  withRouter,
} from 'react-router-dom';

import { connect } from 'react-redux';
import { changeTab } from '../../../redux/actions/tabActions';
import { closeModal } from '../../../redux/actions/modalActions';
import { fetchAllOrders } from '../../../redux/actions/orderActions';
import { fetchAllProducts } from '../../../redux/actions/productActions';
import { removePricebook } from '../../../redux/actions/priceBookActions';
import { selectStore, selectBC } from '../../../redux/actions/authUserActions';
import {
  fetchBalance,
  saveACC,
  saveCC,
  setTicket,
} from '../../../redux/actions/BalanceActions';

/* Rutines */
import { getCC } from '../../../redux/actions/posActions';

import authUserProps from '../../../propTypes/authUserProps';

import Balance from '../../Balance';
import Connection from './Connection';
import LayoutUserInfo from './LayoutUserInfo';
import StoreSelect from '../../Store/StoreSelect';
import Tickets from '../../Tickets';
import Pricebook from '../../Pricebook';
import Currency from '../../Currency';
import PrintMode from '../../rkr2-components/print-mode';

class LayoutBarMenu extends PureComponent {
  static propTypes = {
    tabs: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    authUser: authUserProps.isRequired,
    balance: PropTypes.object.isRequired,
    loads: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,

    dispatchSelectStore: PropTypes.func.isRequired,

    /* Fetch */
    dispatchFetchBalance: PropTypes.func.isRequired,
    dispatchGetCC: PropTypes.func.isRequired,
    dispatchGetACC: PropTypes.func.isRequired,

    /* CC */
    dispatchCloseDialogCC: PropTypes.func.isRequired,
    dispatchSaveCC: PropTypes.func.isRequired,

    /* ACC */
    dispatchCloseDialogACC: PropTypes.func.isRequired,
    dispatchSaveACC: PropTypes.func.isRequired,

    /* MCC */
    dispatchGetMCC: PropTypes.func.isRequired,
    dispatchReSendTicket: PropTypes.func.isRequired,
    dispatchCloseDialogMCC: PropTypes.func.isRequired,

    /* router */
    history: PropTypes.object.isRequired,
    dispatchChangeProfileView: PropTypes.func.isRequired,

    /* BC */
    dispatchSelectBC: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { anchorStoreMenu: null, anchorUserMenu: null, rightDrawer: false };
  }

  openStoreMenu = (event) => {
    const { currentTarget } = event;
    this.setState({ anchorStoreMenu: currentTarget });
  }

  closeStoreMenu = () => {
    this.setState({ anchorStoreMenu: null });
  }

  openUserMenu = (event) => {
    const { currentTarget } = event;
    this.setState({ anchorUserMenu: currentTarget });
  }

  closeUserMenu = () => {
    this.setState({ anchorUserMenu: null });
  }

  toggleDrwawer = () => {
    const { rightDrawer } = this.state;
    if (!rightDrawer) {
      const { dispatchFetchBalance } = this.props;
      dispatchFetchBalance();
    }
    this.setState(prevState => ({ rightDrawer: !prevState.rightDrawer }));
  }

  slideList = () => {
    const {
      loads,
      modals,
      balance,
      authUser,
      dispatchSelectStore,
      /* Fetch */
      dispatchFetchBalance,
      dispatchGetCC,
      dispatchGetACC,

      /* CC */
      dispatchCloseDialogCC,
      dispatchSaveCC,

      /* AC */
      dispatchCloseDialogACC,
      dispatchSaveACC,

      /* MCC */
      dispatchGetMCC,
      dispatchCloseDialogMCC,
      dispatchReSendTicket,

      /* BC */
      dispatchSelectBC,
    } = this.props;
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
    return (
      <div
        className="drawer_content"
        role="presentation"
      >
        <div className="w-100">
          <LayoutUserInfo authUser={authUser} />
          <Divider />
          <Connection authUser={authUser} />
          <Currency />
          <StoreSelect authUser={authUser} dispatchSelectStore={dispatchSelectStore} />
          <Pricebook />
          <PrintMode />
        </div>
        <Tickets type="CC" />
        <div className="w-100 h-auto">
          <Balance
            authUser={authUser}
            balance={balance}
            balanceIsLoading={Boolean(loads.balanceIsLoading)}
            dispatchFetchBalance={dispatchFetchBalance}

            /* Fetch */
            dispatchGetCC={dispatchGetCC}
            dispatchGetACC={dispatchGetACC}

            /* dialogCC */
            dialogCCIsOpen={Boolean(modals.dialogCCModalIsOpen)}
            dialogCCIsLoading={Boolean(loads.dialogCCIsLoading)}
            closeDialogCC={dispatchCloseDialogCC}
            dispatchSaveCC={dispatchSaveCC}

            /* dialogACC */
            dialogACCIsOpen={Boolean(modals.dialogACCModalIsOpen)}
            dialogACCIsLoading={Boolean(loads.dialogACCIsLoading)}
            closeDialogACC={dispatchCloseDialogACC}
            dispatchSaveACC={dispatchSaveACC}

            /* dialogMCC */
            dispatchGetMCC={dispatchGetMCC}
            dialogMCCIsOpen={Boolean(modals.dialogMCCModalIsOpen)}
            dialogMCCIsLoading={Boolean(loads.dialogMCCIsLoading)}
            closeDialogMCC={dispatchCloseDialogMCC}
            sendTicket={dispatchReSendTicket}
          />
          <div className="w-100 d-flex align-items-end">
            <Divider />
            <List>
              <ListItem button component="a" href="/account/profile">
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                <ListItemText primary="Perfil" />
              </ListItem>
              <ListItem button component="a" href="/account/logout">
                <ListItemIcon><Lock /></ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
              </ListItem>
              <ListItem dense>
                <ListItemText primary="Versión" secondary={`Build: ${dateTimeStamp}`} />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      tabs,
      classes,
      history,
      authUser,
      dispatchSelectStore,
      dispatchChangeProfileView,
    } = this.props;
    const { anchorUserMenu, rightDrawer } = this.state;
    const userMenuIsDisplayed = Boolean(anchorUserMenu);
    const stores = authUser.isAuth ? authUser.user.almacenes : [];
    const { user } = authUser;
    const last = user.last_name.charAt(0);
    const { location: { pathname } } = history;
    const enableViewSwitch = Boolean(pathname.includes('/account/profile'));

    const switchView = (tabs.profileViewTab || false);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          style={{ display: 'none' }}
          aria-owns={userMenuIsDisplayed ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.openUserMenu}
          color="inherit"
          className={classes.menuButton}
        >
          <AccountCircleIcon />
        </IconButton>
        <Collapse in={enableViewSwitch}>
          <IconButton onClick={() => dispatchChangeProfileView(!switchView)}>
            {!switchView ? (
              <ViewList color="primary" />
            ) : (
              <ViewModule color="secondary" />
            )}
          </IconButton>
        </Collapse>
        <IconButton
          onClick={() => { this.toggleDrwawer(); }}
          color="inherit"
          className="mx-2"
        >
          <Avatar className="General__UserAvatarStyle">{last}</Avatar>
        </IconButton>
        <Drawer
          anchor="right"
          open={rightDrawer}
          onClose={() => { this.toggleDrwawer(); }}
        >
          { this.slideList() }
        </Drawer>
        <Menu
          id="menu-appbar"
          anchorEl={anchorUserMenu}
          open={userMenuIsDisplayed}
          onClose={this.closeUserMenu}

        >
          <MenuItem onClick={this.closeUserMenu}>
            <NavLink
              to="/account/profile"
            >
              Perfil
            </NavLink>
          </MenuItem>
          <MenuItem onClick={this.closeUserMenu}>
            <NavLink
              to="/account/logout"
            >
              Cerrar Sesión
            </NavLink>
          </MenuItem>
          <Divider />
          {
            stores.map(store => (
              <MenuItem
                button
                key={`${this.constructor.name}-${store.crmid}`}
                onClick={(event) => {
                  event.preventDefault();
                  const { currentTarget } = event;
                  dispatchSelectStore(currentTarget);
                  this.closeStoreMenu();
                }}
                data-crmid={store.crmid}
                data-name={store.almacen}
              >
                {store.almacen}
              </MenuItem>
            ))
          }
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tabs: state.tabs,
  loads: state.loads,
  modals: state.modals,
  balance: state.balance,
  authUser: state.authUser,
});

const mapDispatchToProps = (dispatch, getState) => ({
  dispatchSelectStore: async (element, pos) => {
    const { crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, forzaenvios, currencyid } = element.dataset;
    await dispatch(removePricebook());
    await dispatch(selectStore(crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, pos, forzaenvios, currencyid));
    /* await dispatch(fetchAllProducts());
    await dispatch(fetchAllOrders()); */
    
  },
  dispatchFetchBalance: () => dispatch(fetchBalance()),

  /* CC */
  dispatchGetCC: (date = '') => dispatch(getCC('dialogCC', 'cc', date)),
  dispatchCloseDialogCC: () => dispatch(closeModal('dialogCC')),
  dispatchSaveCC: (data, mcc = false) => dispatch(saveCC(data, mcc)),

  /* ACC */
  dispatchGetACC: (date = '') => dispatch(getCC('dialogACC', 'ACC', date)),
  dispatchCloseDialogACC: () => dispatch(closeModal('dialogACC')),
  dispatchSaveACC: data => dispatch(saveACC(data)),

  /* MCC */
  dispatchGetMCC: (date = '') => dispatch(getCC('dialogMCC', 'MCC', date)),
  dispatchCloseDialogMCC: () => dispatch(closeModal('dialogMCC')),
  dispatchReSendTicket: data => dispatch(setTicket(data)),

  /* Tabs */
  dispatchChangeProfileView: actual => dispatch(changeTab('profileView', actual)),

  /* Bank */
  dispatchSelectBC: (element) => {
    const { crmid, parent } = element.dataset;
    dispatch(selectBC(crmid, parent));
  },
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutBarMenu));
