import { useRef, useState } from 'react';

const useMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  return {
    isOpen,
    toggleMenu,
    menuRef,
  };
};

export default useMenu;
