/* eslint-disable import/no-cycle */
import { IconButton } from '@material-ui/core';
import { Edit, Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDesc } from '../../../../redux/actions/cartActions';
import { editOrder } from '../../../../redux/actions/sellActions';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';
import useDebounce from '../../../../hooks/use-debounce';

const OrderDescription = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const tabs = useSelector(state => state.tabs);
  const modals = useSelector(state => state.modals);
  const [isEdit, setIsEdit] = useState(false);
  const { orderSelected, products } = cart;
  const { posTypeTab } = tabs;
  const [description, setDescription] = useState(orderSelected?.description || '');
  const debouncevalue = useDebounce(description, 500);

  const handleSave = async () => {
    await dispatch(setDesc(description));
    if (posTypeTab !== 'Vender') {
      dispatch(editOrder());
    }
    setIsEdit(false);
  };

  useEffect(() => {
    if (orderSelected && orderSelected?.description !== '') {
      setDescription(orderSelected?.description || '');
      dispatch(openModal('orderNotes'));
    } else {
      dispatch(closeModal('orderNotes'));
    }
  }, [orderSelected?.description]);

  useEffect(() => {
    if (posTypeTab === 'Vender') {
      setIsEdit(true);
      if (!modals.orderNotesModalIsOpen) {
        dispatch(openModal('orderNotes'));
      }
    }
  }, []);

  useEffect(() => {
    if (posTypeTab === 'Vender') {
      dispatch(setDesc(description));
    }
  }, [debouncevalue]);

  return (
    <div>
      {
        modals.orderNotesModalIsOpen && products.length > 0 && (
          <div style={{
            backgroundColor: '#F6F6F7',
            margin: '5px 10px',
            borderRadius: '5px',
            overflow: 'hidden',
            padding: '4px 8px',
          }}
          >
            <small>Agrega aqui la descripcion de esta venta</small>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
              <textarea
                disabled={!isEdit}
                value={description}
                onChange={e => setDescription(e.target.value)}
                cols="30"
                rows="3"
                style={{
                  backgroundColor: '#F6F6F7', width: '100%', border: 'none', outline: 'none',
                }}
              />
              <>
                {
                    posTypeTab !== 'Vender' && (
                      <>
                        {
                          isEdit ? (
                            <IconButton aria-label="Save" onClick={handleSave}>
                              <Save fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton aria-label="Update" onClick={() => setIsEdit(true)}>
                              <Edit fontSize="small" />
                            </IconButton>
                          )
                        }
                      </>
                    )
                  }
              </>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default OrderDescription;
