/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Avatar, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, withStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProductAPI from '../../../../api/ProductAPI';
import { formatString, createOrderRef } from '../../../../helpers/tools';
import ListMdaItem from '../../../Mda/components/ListMda/ListMdaItem';

import './deliver.styles.scss';
import MdaAPI from '../../../../api/MdaApi';
import DeliverOrderProducts from '../DeliverOrderProducts';
import OrderMda from '../../OrderMda';
import FullDialog from '../../../FullDialog';

/*
"treebeslotes": {
   // se pueden agregar n keys (una por cada productid), segun se requiera
    "802631": { // 802631 es el productid
        "id": [ // se pueden agregar n elementos, segun los TreebesLotes que se estén "usando"
            "12345" // el treebeslotesid, para TreebesLotes nuevos se debe mandar 1
        ],
        "number": [ // la longitud de este array debe coincidir con la de "id" en el mismo orden
            "s1" // se necesita para los TreebesLotes nuevos (al recibir compras, por ejemplo)
        ],
        "quantity": [ // la longitud de este array debe coincidir con la de "id" en el mismo orden
            "1" // la cantidad que se está "usando" de ese TreebesLotes
        ]
    }
}
*/
const ExpansionPanell = withStyles({
  expanded: {
    margin: 'auto',
  },
})(ExpansionPanel);

const Deliver = () => {
  const history = useHistory();
  const params = useParams();
  const authUser = useSelector(state => state.authUser);
  const [open, setOpen] = useState(false);

  const [isDeliver, setIsDeliver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMda, setLoadingMda] = useState(false);
  const [products, setProducts] = useState([]);
  const [stock, setStock] = useState([]);
  const [entity, setEntity] = useState({});
  const [order, setOrder] = useState({});
  const [mda, setMda] = useState([]);
  const [isParcial, setIsPartial] = useState(false);

  const productApi = new ProductAPI();
  const mdaApi = new MdaAPI();
  const { user: { config: { store, id, pos_deliver_out_stock } } } = authUser;

  const move = 'SC';
  const ref = `${move}-${createOrderRef()}-${id || '-1'}`;

  const orderDeliverDetails = async () => {
    setLoading(true);
    const response = await productApi.getOrderDetail(params.orderId, params.orderId, store.crmid, 'SC');

    if (response.success === false) {
      toast.error('Error cargando la orden');
      setLoading(false);

      return;
    }

    if (response.PRODUCTOS?.length) {
      const formatProducts = [];
      response.PRODUCTOS.map((prod, index) => {
        // Format lotes
        let lotesUpdated = [];
        if (prod.TreebesLotes && prod.TreebesLotes.length) {
          lotesUpdated = prod.TreebesLotes.map(l => ({ ...l, qty: 0 }));
        }

        const formatedProduct = {
          ...prod,
          cantidad: parseFloat(prod.cantidad),
          por_entregar: 0,
          in_stock: response.stock[index].inventario,
          TreebesLotes: lotesUpdated.length > 0 ? [...lotesUpdated] : (prod.TreebesLotes && prod.TreebesLotes.length > 0 ? [...prod.TreebesLotes] : []),
        };


        formatProducts.push(formatedProduct);
      });

      // .log('formatProducts', formatProducts);
      setProducts(formatProducts);
    }

    if (Object.keys(response.EMPRESA).length) {
      setEntity(response.EMPRESA);
    }

    if (response.DATOS_SO && Object.keys(response.DATOS_SO).length) {
      setOrder({ ...response.DATOS_SO, crmid: response.CRMID });
    }

    if (response.stock.length) {
      setStock(response.stock);
    }

    setLoading(false);
  };

  const orderMda = async () => {
    setLoadingMda(true);
    const response = await mdaApi.get(params.orderId);
    const { success, result } = response;

    if (success && result.length) {
      setMda(result);
      setLoadingMda(false);
    }

    if (!success) {
      toast.error('Ocurrio un error obteniendo los movimientos de almacen');
    }
    setLoadingMda(false);
  };


  const rest = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.id === prodId);
    prods[prodIndex].por_entregar -= 1;
    setProducts([...prods]);
  };

  const add = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.id === prodId);
    prods[prodIndex].por_entregar += 1;

    setProducts([...prods]);
  };

  const selectAll = () => {
    const prods = products;
    prods.map((prod, index) => {
      if (stock[index].inventario > 0) {
        if (stock[index].inventario >= parseFloat(prod['por entregar'])) {
          prods[index].por_entregar = parseFloat(prod['por entregar']);
        } else {
          prods[index].por_entregar = stock[index].inventario;
        }
      }
    });

    setProducts([...prods]);
  };

  const SelectNone = () => {
    const prods = [...products];
    prods.map((prod, index) => {
      prods[index].por_entregar = 0;
    });

    setProducts([...prods]);
  };

  const changeInput = (value, prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.id === prodId);

    if (parseFloat(value) > prods[prodIndex]['por entregar'] || parseFloat(value < 1)) {
      setProducts([...prods]);
      toast.warn(`El valor no puede ser mayor que ${parseFloat(prods[prodIndex]['por entregar'])} o menor que 0`);

      return;
    }


    prods[prodIndex].por_entregar = value;

    setProducts([...prods]);
  };


  const deliverOrder = async () => {
    let noAmount = 0;
    if (products.length) {
      products.map((p) => {
        if (p.in_stock < p.por_entregar) {
          noAmount += 1;
        }
      });

      if (noAmount > 0 && pos_deliver_out_stock !== '1') {
        toast.warn('No puede entregar sin existencia');

        return;
      }
    }

    setIsDeliver(true);
    const response = await mdaApi.deliver(products, ref, store.crmid, order.crmid, entity.id, new Date(), '', 'SC');

    if (response.success) {
      toast.success(response.message || 'Venta entregada correctamente');
      setIsDeliver(false);
      history.goBack();
    } else {
      toast.error(response.message || 'Error al entregar la venta');
      setIsDeliver(false);
    }
  };


  const checkParcialidad = () => {
    const esParcial = products.some(prod => prod.cantidad > prod.por_entregar);
    if (esParcial) {
      setIsPartial(true);
      return;
    }

    deliverOrder();
  };

  const updateLotes = (lotesUpdated, item) => {
    const prods = [...products];
    const prodIndex = prods.findIndex(p => p.id === item.id);
    prods[prodIndex].TreebesLotes = [...lotesUpdated];

    // Update quantity
    let qty = 0;
    prods[prodIndex].TreebesLotes.map((lote) => {
      qty += lote.qty;
    });
    prods[prodIndex].por_entregar = qty;
    setProducts([...prods]);
  };

  const updateBarCode = (item, barCode) => {
    const prods = [...products];
    const prodIndex = prods.findIndex(p => p.id === item.id);
    prods[prodIndex].codigo_barras = barCode;
    setProducts([...prods]);
  };

  useEffect(() => {
    if (params.orderId && store.crmid) {
      orderDeliverDetails();
      orderMda();
    }
  }, []);

  return (
    <>
      <FullDialog
        title={`Entrega de orden ${order?.subject || ''}`}
        isOpen
        isLoading={isDeliver}
        onClose={() => {
          history.goBack();
          localStorage.setItem('selectedInput', '');
          localStorage.setItem('scanedProductId', '');
        }}
        onSave={checkParcialidad}
        enableAction={!loading && !loadingMda && !isDeliver && products.length > 0}
      >

        <div className="deliver" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <div>
            <Collapse in={Boolean(!loading)} className="w-100">

              <Paper className="deliver__client">
                {
                Object.keys(order).length > 0 && (
                  <ListMdaItem
                    item={{
                      createdtime: order.f_venta,
                      entity_label: ref,
                      de_documentoid_label: store.name,
                      a_documentoid_label: order?.subject,
                    }}
                    isCollapse={false}
                    sc="Entrega"
                  />
                )
              }
                <Divider />
                {
                Object.keys(entity).length > 0 && (
                <List className="w-100">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        {entity.nombre.substring(0, 1)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={formatString(entity.nombre)}
                      secondary="Cliente"
                    />
                  </ListItem>
                </List>
                )
              }
              </Paper>
            </Collapse>
          </div>
          <DeliverOrderProducts updateBarCode={updateBarCode} updateLotes={updateLotes} stock={stock} products={products} changeInput={changeInput} rest={rest} add={add} loading={loading} selectAll={selectAll} selectNone={SelectNone} checkParcialidad={checkParcialidad} />
          <div style={{ with: '100%' }}>
            <ExpansionPanell
              classes={{ expanded: { margin: 'auto', with: '100%' } }}
              square
              expanded={open}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon onClick={() => setOpen(!open)} />
                  }
              >
                <Typography>Movimientos de almacén</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{width: '100%'}}>
                  <OrderMda mda={mda} loading={loadingMda} />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanell>
          </div>
        </div>
      </FullDialog>
      <Dialog open={isParcial} onClose={() => setIsPartial(false)} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">Entrega Parcial</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Estas haciando una entrega parcial, desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPartial(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deliverOrder();
              setIsPartial(false);
            }}
            color="primary"
            autoFocus
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Deliver;
