/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { faSave } from '@fortawesome/fontawesome-free-regular';
import { openModal } from '../../../../redux/actions/modalActions';
import { changeSalesOrderStatus, saveSell } from '../../../../redux/actions/sellActions';
import DropUpButton from '../DropUpButton';

const CartGuardarBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deliver = useSelector(state => state.shipping?.shippingList);
  const authUser = useSelector(state => state.authUser);
  const cart = useSelector(state => state.cart);
  const { shipping_id, config: { store } } = authUser.user;

  function handleSaveSell(orderStatus) {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  }

  function handleSave() {
    if (store.forzaEnvio === '1') {
      const shippingIsSelected = cart.products.findIndex(prod => prod.crmid === shipping_id);
      // Total products
      const productQty = cart.products.filter(p => p.crmid !== shipping_id).reduce((acc, prod) => acc + prod.quantity, 0);
      // Total products with shipping
      const shippingProducts = [];
      deliver.map(p => p.products.map(prod => shippingProducts.push(prod)));
      const totalShippingProducts = shippingProducts.reduce((acc, prod) => acc + prod.quantity, 0);

      if (shippingIsSelected === -1 && (deliver.length === 0 || totalShippingProducts !== productQty)) {
        if (totalShippingProducts !== productQty) {
          toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
        } else {
          toast.info('Debes seleccionar un envío  antes de guardar');
        }
        dispatch(openModal('shipping'));

        return;
      }
      if (shippingIsSelected !== -1 && totalShippingProducts !== productQty) {
        toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
        dispatch(openModal('shipping'));
        return;
      }
    }
    handleSaveSell(authUser.user.config.sostatus);
    history.push('/pos/ventas');
  }

  return (
    <>
      <DropUpButton color="#787985" title="Guardar" icon={faSave} onClick={cart.totalProducts > 0 ? handleSave : () => {}} />
    </>
  ); };

export default CartGuardarBtn;
