/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import {
  Divider, IconButton, List, ListItem, Paper,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '../../../helpers/tools';
import FlexMenu from '../headless-menu/FlexMenu';


const CartSellMenu = () => {
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);

  return (
    <FlexMenu>
      <FlexMenu.Trigger
        as={IconButton}
        aria-label="Menu"
        style={{
          borderRadius: '5px',
          height: 40,
          with: 40,
          border: '1px solid #b8b8b8',
        }}
      >
        <FontAwesomeIcon icon={faInfo} style={{ fontSize: '16px', width: 16 }} />
      </FlexMenu.Trigger>
      {
        (selectedCustomer?.siccode
        || parseFloat(selectedCustomer?.puntos_asignados) > 0
        || (parseFloat(selectedCustomer.credito_d) > 0 && parseFloat(selectedCustomer?.credito_m) > 0)
        || (parseFloat(selectedCustomer?.cxc) > 0 || parseFloat(selectedCustomer?.ndc_aplicadas) > 0 || parseFloat(selectedCustomer?.xamortizar) > 0)) && (
          <FlexMenu.OptionList as={Paper} style={{ width: 230 }}>
            <List component="nav">
              {
                    selectedCustomer && selectedCustomer?.siccode && (
                      <ListItem disableRipple disableTouchRipple style={{ height: '100%', cursor: 'default' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: 5, fontSize: 14 }}>
                              RFC:
                            </span>
                            <span style={{ fontWeight: 300, fontSize: 'medium' }}>{selectedCustomer?.siccode || '-'}</span>
                          </div>
                        </div>
                      </ListItem>
                    )
                    }
              {
                    selectedCustomer?.puntos_asignados && parseFloat(selectedCustomer?.puntos_asignados) > 0 && (
                      <>
                        <Divider />
                        <ListItem disableRipple disableTouchRipple style={{ height: '100%', cursor: 'default' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 300, fontSize: 'medium' }}>Puntos</span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Asignado:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.puntos_asignados) || 0}
                              </span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Saldo:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.puntos_saldo) || 0}
                              </span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Usados:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.puntos_usados) || 0}
                              </span>
                            </div>
                          </div>
                        </ListItem>
                      </>
                    )
                    }
              {/* Créditos */}
              {
                    selectedCustomer && parseFloat(selectedCustomer.credito_d) > 0 && parseFloat(selectedCustomer?.credito_m) > 0 && (
                      <>
                        <Divider />
                        <ListItem disableRipple disableTouchRipple style={{ height: '100%', cursor: 'default' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 300, fontSize: 'medium' }}>Crédito</span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Limite de crédito:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.credito_m)}
                              </span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Dias de crédito:

                                </strong>
                                {' '}
                                {selectedCustomer?.credito_d || 0}
                              </span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Deuda mas antigua

                                </strong>
                                {' '}
                                {selectedCustomer?.fecha_deuda}
                              </span>
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Crédito disponible:

                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.saldo_credito) || 0}
                              </span>
                            </div>
                          </div>
                        </ListItem>
                      </>
                    )
                    }
              {/* Saldos */}
              {
                    selectedCustomer && (parseFloat(selectedCustomer?.cxc) > 0 || parseFloat(selectedCustomer?.ndc_aplicadas) > 0 || parseFloat(selectedCustomer?.xamortizar) > 0) && (
                      <>
                        <Divider />
                        <ListItem disableRipple disableTouchRipple style={{ height: '100%', cursor: 'default' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 300, fontSize: 'large' }}>Saldo</span>
                              {
                                parseFloat(selectedCustomer?.cxc) > 0 && (
                                  <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                    <strong>
                                      Por Cobrar:

                                    </strong>
                                    {' '}
                                    {formatMoney(selectedCustomer?.cxc) || 0}
                                  </span>
                                )
                              }
                              {
                                selectedCustomer && parseFloat(selectedCustomer.ndc_aplicadas) > 0 && (
                                  <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                    <strong>
                                      Notas de crédito:
                                    </strong>
                                    {' '}
                                    {formatMoney(selectedCustomer?.ndc_aplicadas) || 0}
                                  </span>
                                )
                              }
                              {
                                selectedCustomer && parseFloat(selectedCustomer.xamortizar) > 0 && (
                                  <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                    <strong>
                                      Anticipos a favor:
                                    </strong>
                                    {' '}
                                    {formatMoney(selectedCustomer.xamortizar) || 0}
                                  </span>
                                )
                              }
                            </div>
                          </div>
                        </ListItem>
                      </>
                    )
                    }
            </List>
          </FlexMenu.OptionList>
        )
      }
    </FlexMenu>


  );
};

export default CartSellMenu;
