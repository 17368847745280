/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import { connect, useSelector } from 'react-redux';
import LayoutBarMenu from './LayoutBarMenu';
import CountUpTimer from '../../CountUp';
import SelectStore from '../../rkr2-components/select-store';
import ActiveIndex from '../../rkr2-components/active-index/ActiveIndex';

const renderContent = (classes, logo, organizationname, almacen) => (
  <img
    src={`${almacen ? (almacen.image || logo) : logo}`}
    alt={almacen ? almacen.direccion : organizationname}
    style={{ width: 'auto', height: '40px' }}
    className={`${classes.logo} align-self-center`}
  />
);

const renderRightIcon = classes => (<LayoutBarMenu classes={classes} />);

const renderLeftIcon = (classes, dashboardMenuIsDisplayed, closeDashboardMenu, openDashboardMenu) => (
  <IconButton
    color="inherit"
    aria-label="Open drawer"
    onClick={
      dashboardMenuIsDisplayed
        ? closeDashboardMenu
        : openDashboardMenu
    }
    className={classes.menuButton}
  >
    <MenuIcon />
  </IconButton>
);

const LayoutBar = ({
  classes,
  closeDashboardMenu,
  dashboardMenuIsDisplayed,
  history,
  logoname,
  openDashboardMenu,
  organizationname,
  almacen,
}) => {
  const location = useLocation();
  const timerStart = useSelector(state => state.tasks.startTimer);
  return (
    <AppBar
      className={`${classNames(classes.appBar)} box_shadow_none positionRelative`}
      style={{ zIndex: 1050 }}
      position="relative"
    >
      <Toolbar>
        <div className={`${classes.grow} d-flex`}>
          {renderLeftIcon(classes, dashboardMenuIsDisplayed, closeDashboardMenu, openDashboardMenu, history)}
          {renderContent(classes, logoname, organizationname, almacen)}
        </div>
        <div>
          {
            (timerStart) && (<CountUpTimer hoursMinSecs={{ hours: 0, minutes: 0, seconds: 0 }} global />)
          }
        </div>
        <ActiveIndex />
        <SelectStore />
        {renderRightIcon(classes)}
      </Toolbar>
    </AppBar>
  ); };

LayoutBar.propTypes = {
  logoname: PropTypes.string,
  classes: PropTypes.object.isRequired,
  almacen: PropTypes.object.isRequired,
  organizationname: PropTypes.string.isRequired,
  openDashboardMenu: PropTypes.func.isRequired,
  closeDashboardMenu: PropTypes.func.isRequired,
  dashboardMenuIsDisplayed: PropTypes.bool.isRequired,
};

LayoutBar.defaultProps = {
  logoname: `${process.env.PUBLIC_URL}/img/logo.svg`,
};
export default connect()(LayoutBar);
