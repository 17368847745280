import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProductAPI from '../../../../api/ProductAPI';

const Stock = (hit) => {
  const authUser = useSelector(state => state.authUser);
  const { user: { config: { store } } } = authUser;
  const [stockResult, setStockResult] = React.useState(null);
  const getProductStock = async () => {
    const ProductApi = new ProductAPI();
    const data = {
      crmid: hit.hit.crmid || 0,
      treebesalmid: store.crmid,
      products_ids: hit.hit?.Products.map(p => p.crmid),
    };
    const response = await ProductApi.getProductsStock(data);
    const formatted = response.result ? Object.values(response.result).map((p) => {
      const findProduct = hit.hit.Products.find(product => product.crmid === `${p.productid}`);
      return {
        ...p,
        name: findProduct.productname,
        code: findProduct.productcode,
      };
    }) : null;
    setStockResult(formatted || null);
  };

  useEffect(() => {
    getProductStock();
  }, []);

  return (
    <div>
      {stockResult ? Object.values(stockResult).map(stock => (
        <div key={stock.productid} style={{ fontSize: 14, marginBottom: 10}}>
          <p style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <strong>{stock.name}</strong>
            <span>
              <strong>
                Sku:
                {' '}
              </strong>
              {stock.code}
            </span>
            <span>
              <strong>
                Stock:
                {' '}
              </strong>
              {stock.qtyinstock}
            </span>
          </p>
        </div>
      )) : (<span>Buscando...</span>)}
    </div>
  );
};

export default Stock;
