/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
  AUTHENTICATE_USER,
  CHANGE_ALM_AUTH_USER,
  CHANGE_GENERIC_BUTTON_CAJA,
  CHANGE_GENERIC_BUTTON_HISTORICO,
  CHANGE_SELL_ORDER_STATUS_AUTH_USER,
  DEFINE_FILTERS,
  DEFINE_PRINT_SIZE,
  DEFINE_SELL_TAB,
  HANDLE_CART_CONCEPTS,
  HANDLE_CHECKOUT_BILL,
  HANDLE_FAST_MODE,
  HANDLE_PRINT_AFTER_SELL,
  HANDLE_PRINT_SIZE,
  HANDLE_SELL_TAB,
  RESET_SET_FILTER,
  SELECT_BC,
  SELECT_STORE_AUTH_USER,
  SET_FAST_COLUMN,
  SET_FILTER,
  SET_GENERAL_COLUMN_DEF,
  UNAUTHENTICATE_USER,
  VERIFY_AUTH_USER,
  HANDLE_PROMOTION,
  CHANGE_CONFIG_TASK_STATUS_FILTER,
} from '../actions/authUserActions';

import { userHelper, orderHelper, pricebookHelper } from '../../helpers';

const defaultState = () => ({
  isAuth: false,
  isRemember: false,
  token: '',
  domain: '',
  user: {
    account_default: {},
    almacenes: [],
    config: { test: 'hola' },
    currencies: {},
    first_name: '',
    last_name: '',
    pos_auto_alm: [],
    sostatus: [],
    taxes: [],
  },
  almacenes: [],
  fastMode: false,
});

const defaultFilters = {
  estado_fde: [
    {
      label: 'Pagado/Cobrado',
      value: 'Pagado/Cobrado',
      column: 'estado_fde',
    },
    {
      label: 'Sin Pago/Cobro',
      value: 'Sin Pago/Cobro',
      column: 'estado_fde',
    },
    {
      label: 'Parcialmente Pagado/Cobrado',
      value: 'Parcialmente Pagado/Cobrado',
      column: 'estado_fde',
    },
  ],
  estado_mda: [
    {
      label: 'Entregado',
      value: 'Entregado',
      column: 'estado_mda',
    },
    {
      label: 'Sin Entregar',
      value: 'Sin Entregar',
      column: 'estado_mda',
    },
    {
      label: 'Parcialmente Entregado',
      value: 'Parcialmente Entregado',
      column: 'estado_mda',
    },
  ],
  state: [
    {
      label: 'Creados',
      value: 'Creado',
      column: 'state',
    },
    {
      label: 'Apartados',
      value: 'Apartado',
      column: 'state',
    },
    {
      label: 'Autorizados',
      value: 'Autorizado',
      column: 'state',
    },
    {
      label: 'Cancelados',
      value: 'Cancelado',
      column: 'state',
    },
  ],
};

const authenticate = (state, newUser) => {
  const user = { ...newUser };
  user.user = userHelper.normalizePaymentMethods(newUser.user);
  user.user.config = { ...user.user.config, ...state.user.config };
  // user.config = { ...state.user.user.config, ...user.user.config };
  const authUser = { ...state, ...user };
  userHelper.setStorage(authUser);
  return authUser;
};

const verifyAuthUser = (state, user) => {
  if (user.isAuth) {
    return state;
  }
  return defaultState();
};

const unauthenticate = () => {
  userHelper.emptyStorage();
  pricebookHelper.removeStorage();
  return defaultState();
};

const changeAlmAuthUser = (authUser, { pos_auto_alm }) => {
  const state = { ...authUser };
  state.user.config.pos_auto_alm = pos_auto_alm;
  userHelper.setStorage(state);
  return state;
};

const changeSellOrderStatusAuthUser = (authUser, { sostatus }) => {
  const state = { ...authUser };
  state.user.config.sostatus = sostatus;
  userHelper.setStorage(state);
  return state;
};

const changeTaskFilterAuthUser = (authUser, { pos_filter_projecttaskstatus }) => {
  const state = { ...authUser };
  state.user.config.pos_filter_projecttaskstatus = pos_filter_projecttaskstatus;
  userHelper.setStorage(state);
  return state;
};

const selectStore = (state, { store }) => {
  const authUser = { ...state };
  const { pos } = store;
  if (pos.pos_config_alm) {
    authUser.user.config = userHelper.setStore(pos, authUser.user.config);
  } else {
    authUser.user.config.store = store;
  }
  authUser.user.config.pos_config_alm = store.pos?.pos_config_alm;
  userHelper.setStorage(authUser);
  return authUser;
};

const changeGenericButtonCaja = (state, { pos_botoncaja }) => {
  const authUser = { ...state };
  authUser.user.config.pos_botoncaja = pos_botoncaja;
  userHelper.setStorage(authUser);
  return authUser;
};

const changeGenericButtonHistorico = (state, { pos_botonventas }) => {
  const authUser = { ...state };
  authUser.user.config.pos_botonventas = pos_botonventas;
  userHelper.setStorage(authUser);
  return authUser;
};

const setOrderFilter = (state, {
  option,
  pos_filtrofde,
  pos_filtromda,
  pos_filtrostate,
}) => {
  const authUser = { ...state };
  const { filter } = option;
  authUser.user.config[filter] = option.values;
  switch (filter) {
    case 'estado_fde':
      authUser.user.config.pos_filtrofde = pos_filtrofde;
      break;
    case 'estado_mda':
      authUser.user.config.pos_filtromda = pos_filtromda;
      break;
    default:
      authUser.user.config.pos_filtrostate = pos_filtrostate;
  }
  userHelper.setStorage(authUser);
  return authUser;
};

const resetFilters = (state) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, ...defaultFilters };
  userHelper.setStorage(authUser);
  return authUser;
};

const defineFilters = (state, { user }) => {
  const authUser = { ...state };
  authUser.user.config = orderHelper.defineFiltersFields(user);
  userHelper.setStorage(authUser);
  return authUser;
};

const setGeneralColumnsDef = (state, {
  defs,
  product,
  pos_cols_prods,
  pos_cols_ventas,
}) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  const newState = (
    product
      ? ({
        produColumns: defs,
        pos_cols_prods,
      }) : ({
        orderColumns: defs,
        pos_cols_ventas,
      }));
  authUser.user.config = { ...config, ...newState };
  userHelper.setStorage(authUser);
  return authUser;
};
const handleFastMode = (state, { mode }) => {
  const { fastMode } = state;
  if (mode !== null) {
    return { ...state, fastMode: mode };
  }
  return { ...state, fastMode: !fastMode };
};

const selectBC = (state, { crmid, parentMethod }) => {
  const authUser = { ...state };
  const { user: { metodos_pagos } } = authUser;
  const paymentMethods = [...metodos_pagos];
  paymentMethods.map((item) => {
    if (item.method === parentMethod) {
      item.body.map((cb) => {
        if (cb.crmid === crmid) {
          cb.select = true;
        }
        else cb.select = false;
        return cb;
      });
    }
    return item;
  });
  authUser.user.metodos_pagos = [...paymentMethods];
  return authUser;
};

const handleCartConcepts = (state, { pos_cart_concepts }) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, pos_cart_concepts };
  userHelper.setStorage(authUser);
  return authUser;
};

const defineFastColumn = (state, { post_fast_column }) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, post_fast_column };
  userHelper.setStorage(authUser);
  return authUser;
};

const defineSellTab = (state, { pos_sell_tab }) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, pos_sell_tab };
  userHelper.setStorage(authUser);
  return authUser;
};

const definePrintSize = (state, { pos_print_size }) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, pos_print_size };
  userHelper.setStorage(authUser);
  return authUser;
};

const handlePrintSize = (state, { pos_print_size }) => {
  const authUser = { ...state };
  authUser.user.config.pos_print_size = pos_print_size;
  userHelper.setStorage(authUser);
  return authUser;
};

const handlePromotion = (state, { pos_promo_cart }) => {
  const authUser = { ...state };
  authUser.user.config.pos_promo_cart = pos_promo_cart;
  userHelper.setStorage(authUser);
  return authUser;
};

const handleSellTab = (state, { pos_sell_tab }) => {
  const authUser = { ...state };
  authUser.user.config.pos_sell_tab = pos_sell_tab;
  userHelper.setStorage(authUser);
  return authUser;
};

const handleCheckoutBill = (state, { pos_checkout_bill }) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, pos_checkout_bill };
  userHelper.setStorage(authUser);
  return authUser;
};

const handlePrintAfterSell = (state, { pos_print_after_sell }) => {
  const authUser = { ...state };
  const { user: { config } } = authUser;
  authUser.user.config = { ...config, pos_print_after_sell };
  userHelper.setStorage(authUser);
  return authUser;
};

const initialState = { ...defaultState(), ...userHelper.getStorage() };


export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case AUTHENTICATE_USER:
      return authenticate(state, action.user);
    case VERIFY_AUTH_USER:
      return verifyAuthUser(state, action.user);
    case UNAUTHENTICATE_USER:
      return unauthenticate();
    case CHANGE_ALM_AUTH_USER:
      return changeAlmAuthUser(state, action);
    case CHANGE_SELL_ORDER_STATUS_AUTH_USER:
      return changeSellOrderStatusAuthUser(state, action);
    case CHANGE_CONFIG_TASK_STATUS_FILTER:
      return changeTaskFilterAuthUser(state, action);
    case CHANGE_GENERIC_BUTTON_CAJA:
      return changeGenericButtonCaja(state, action);
    case CHANGE_GENERIC_BUTTON_HISTORICO:
      return changeGenericButtonHistorico(state, action);
    case SELECT_STORE_AUTH_USER:
      return selectStore(state, action);
    case SET_FILTER:
      return setOrderFilter(state, action);
    case SET_GENERAL_COLUMN_DEF:
      return setGeneralColumnsDef(state, action);
    case RESET_SET_FILTER:
      return resetFilters(state);
    case DEFINE_FILTERS:
      return defineFilters(state, action);
    case HANDLE_FAST_MODE:
      return handleFastMode(state, action);
    case SELECT_BC:
      return selectBC(state, action);
    case HANDLE_CART_CONCEPTS:
      return handleCartConcepts(state, action);
    case SET_FAST_COLUMN:
      return defineFastColumn(state, action);
    case DEFINE_SELL_TAB:
      return defineSellTab(state, action);
    case HANDLE_SELL_TAB:
      return handleSellTab(state, action);
    case DEFINE_PRINT_SIZE:
      return definePrintSize(state, action);
    case HANDLE_PRINT_SIZE:
      return handlePrintSize(state, action);
    case HANDLE_CHECKOUT_BILL:
      return handleCheckoutBill(state, action);
    case HANDLE_PRINT_AFTER_SELL:
      return handlePrintAfterSell(state, action);
    case HANDLE_PROMOTION:
      return handlePromotion(state, action);
    default:
      return state;
  }
}
