/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import {
  useSearchBox,
} from 'react-instantsearch';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Tab, Tabs } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  changePosTypeTabToCotizacionesAndClearCart,
  changePosTypeTabToOrdersAndClearCart,
  changePosTypeTabToProductsAndClearCart,
} from '../../../../redux/actions/posActions';
import { resetEditOrder } from '../../../../redux/actions/orderActions';
import { openModal } from '../../../../redux/actions/modalActions';
import { selectCustomer } from '../../../../redux/actions/customerActions';
import ProductListMode from './components/ProductListMode';
import FilterMenuToggle from './components/FilterMenuToggle';
import useDebounce from '../../../../hooks/use-debounce';

const SCAN_THRESHOLD = 100; // milisegundos

const ProductHeader = () => {
  const {
    refine,
  } = useSearchBox();
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const authUser = useSelector(state => state.authUser);
  const { user: { meilisearch_attributes } } = authUser;
  const [value, setValue] = React.useState(0);
  const [scanMode, setScanMode] = React.useState(false);
  const [searchQ, setSearchQ] = React.useState(null);
  const debouncevalue = useDebounce(searchQ, 300);

  const { defaultCustomer } = customer;
  const tabsName = ['Vender', 'Historico', 'Cotizaciones'];

  const lastInputTime = useRef(Date.now());
  const inputBuffer = useRef('');
  const timeoutRef = useRef(null);
  const inputRef = useRef(null);

  function handleFocus() {
    if (scanMode) {
      inputRef.current.select();
    }
  }

  const handleOnChangeTabType = async (event, tabValue) => {
    const { totalProducts, orderSelected } = cart;
    const theAreProducts = Boolean(totalProducts > 0);
    const isOrder = Boolean(Object.keys(orderSelected).length > 0);
    switch (tabsName[tabValue]) {
      case 'Vender':
        dispatch(changePosTypeTabToProductsAndClearCart());
        dispatch(selectCustomer(defaultCustomer));
        dispatch(resetEditOrder());
        setValue(tabValue);
        history.push('/pos/vender');
        break;
      case 'Historico':
        if (theAreProducts && !isOrder) {
          dispatch(openModal('changeTabVentas'));
        } else {
          dispatch(changePosTypeTabToOrdersAndClearCart());
          dispatch(resetEditOrder());
          setValue(tabValue);
          history.push('/pos/ventas');
        }
        break;
      case 'Cotizaciones':
        if (theAreProducts && !isOrder) {
          dispatch(openModal('changeTabCotiza'));
        } else {
          dispatch(changePosTypeTabToCotizacionesAndClearCart());
          dispatch(resetEditOrder());
          setValue(tabValue);
          history.push('/pos/cotizaciones');
        }
        break;
      default:
        break;
    }
  };

  function handleChange(event, newValue) {
    handleOnChangeTabType(event, newValue);
  }

  const checkScanInput = (e) => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastInputTime.current;

    if (elapsedTime < SCAN_THRESHOLD) {
      // Probablemente es un escaneo
      inputBuffer.current += e.target.value.slice(-1);
    } else {
      // Probablemente es entrada manual
      inputBuffer.current = e.target.value;
    }

    lastInputTime.current = currentTime;

    // Actualiza el estado del input
    setSearchQ(e.target.value);

    // Limpia el timeout anterior si existe
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Configura un nuevo timeout
    timeoutRef.current = setTimeout(() => {
      if (inputBuffer.current.length > 0) {
        if (elapsedTime < SCAN_THRESHOLD) {
          // console.log('Código escaneado:', inputBuffer.current);
          // Emit custom event with the scanned code
          if (scanMode) {
            document.dispatchEvent(new CustomEvent('barcode-scanned', { detail: inputBuffer.current }));
            inputRef.current.select();
          }
        } else {
          console.log('Entrada manual:', inputBuffer.current);
          // Habilitar si se desea agregar la cantidad al último producto
          /* if (scanMode) {
            if (cart.products.length) {
              // addValueToLastProduct(inputBuffer.current);
            }
          } */
        }
        if (inputRef.current && scanMode) {
          inputRef.current.focus();
        }
        inputBuffer.current = '';
      }
    }, SCAN_THRESHOLD);
  };

  const listenSerieCloseModalEvent = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      if (scanMode) {
        inputRef.current.select();
      }
    } else {
      const el = document.getElementById('product-search-input');
      el.focus();
      el.select();
    }
  };

  useEffect(() => {
    if (debouncevalue !== null) {
      refine(searchQ);
    }
  }, [debouncevalue]);

  useEffect(() => {
    function handleKeyPress(e) {
      if (scanMode) {
        if (e.key === 'Enter' && inputBuffer.current === '' && document.activeElement === inputRef.current) {
          document.dispatchEvent(new CustomEvent('barcode-scanned', { detail: inputRef.current.value }));
          inputBuffer.current = '';
        }
      }
    }
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [scanMode]);

  useEffect(() => {
    // listen custom event
    document.addEventListener('focusSearch', listenSerieCloseModalEvent);
    return () => {
      document.removeEventListener('focusSearch', listenSerieCloseModalEvent);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        boxShadow: '0 0.5px 0.5px 0 #b8b8b8',
      }}
    >
      <div style={{ maxWidth: '40%' }}>
        <Tabs
          variant="fullWidth"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, valor) => handleChange(event, valor)}
        >
          <Tab style={{ minWidth: 'auto' }} label="Caja" />
          <Tab style={{ minWidth: 'auto' }} label="Venta" />
          <Tab style={{ minWidth: 'auto' }} label="Cotizaciones" />
        </Tabs>
      </div>
      <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
        {/* <SearchBox queryHook={queryHook} placeholder="Buscar" searchAsYouType autoFocus /> */}
        <div style={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'start',
          alignItems: 'center',
          border: `1px solid ${scanMode ? '#24A1DB' : '#b8b8b8'}`,
          borderRadius: '5px',
          padding: '2px 8px',
          height: 40,
          width: '100%',
        }}
        >
          {
            scanMode ? (
              <FontAwesomeIcon
                icon={faForward}
                style={{ fontSize: '12px', color: '#24A1DB', cursor: 'pointer' }}
                onClick={() => {
                  setScanMode(false);
                  toast.info('Modo escaneo desactivado');
                }
                }
              />
            )
              : (
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    toast.info('Modo escaneo activado');
                    setScanMode(true);
                  }}
                />
              )
          }
          <input
            ref={inputRef}
            id="product-search-input"
            className="searchBox-input"
            style={{
              border: 'none',
              outline: 'none',
            }}
            type="search"
            value={searchQ}
            onFocus={handleFocus}
            /* onChange={handleSearch} */
            onChange={checkScanInput}
            placeholder="Buscar Producto"
          />
        </div>
        {
          meilisearch_attributes && meilisearch_attributes.length > 0 && (
            <FilterMenuToggle />
          )
        }
      </div>
      <ProductListMode />
    </div>
  );
};
ProductHeader.propTypes = {};

export default ProductHeader;
