/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import TableAPI from '../../api/TableAPI';
import { loading, loaded } from './loadActions';
import { closeModal, openModal } from './modalActions';

export const ADD_ITEMS_TO_TABLE = 'ADD_ITEMS_TO_TABLE';
export const CHANGE_COMMENT = 'CHANGE_COMMENT';
export const CHANGE_FIELD = 'CHANGE_FIELD';
export const CLEAR_TABLE = 'CLEAR_TABLE';
export const RESET_TO_ORIGIN = 'RESET_TO_ORIGIN';
export const SELECT_EMPTY_TO_TABLE = 'SELECT_EMPTY_TO_TABLE';
export const SELECT_TO_DELIVERED = 'SELECT_TO_DELIVERED';
export const SELECT_TO_REFUNDED = 'SELECT_TO_REFUNDED';
export const SET_REFUND_TICKET = 'SET_REFUND_TICKET';
export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';
export const UPDATE_BAD_PRODUCTS = 'UPDATE_BAD_PRODUCTS';
export const UPDATE_CHANGE_PRODUCTS = 'UPDATE_CHANGE_PRODUCTS';

export function clearTable() {
  return {
    type: CLEAR_TABLE,
  };
}

export function addItemsToTable(typeAction, crmid = undefined) {
  const tableApi = new TableAPI();
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      await dispatch(loading('table'));
      const { discount, cart, authUser: { user: { config: { store, id }, almacenes } } } = getState();
      const { orderSelected } = cart;
      await dispatch(clearTable());
      const orderId = orderSelected.crmid || crmid;
      const response = await tableApi.getOrderDetails(orderId, store.crmid, typeAction);
      const fullStoreIndex = almacenes.findIndex(a => a.crmid === store.crmid);
      const fullStore = almacenes[fullStoreIndex];
      const updatedStore = {
        ...store,
        treebesalmid_dev: fullStore && fullStore.treebesalmid_dev ? fullStore.treebesalmid_dev : null,
        treebesalmid_env: fullStore && fullStore.treebesalmid_env ? fullStore.treebesalmid_env : null,
        treebesalmid_dev_label: fullStore && fullStore.treebesalmid_dev_label ? fullStore.treebesalmid_dev_label : null,
        treebesalmid_env_label: fullStore && fullStore.treebesalmid_env_label ? fullStore.treebesalmid_env_label : null,
      };
      if (response.success && !response.json.success) {
        const { cartProductsByArray, balance, total } = cart;
        await dispatch({
          type: ADD_ITEMS_TO_TABLE,
          cart,
          typeAction,
          response,
          store: updatedStore,
          cartProductsByArray,
          discount,
          balance,
          total,
          id,
        });
      } else {
        await dispatch(closeModal('deliver'));
        await dispatch(closeModal('refund'));
        // const { json: { error } } = response;
        switch (response?.json?.error?.code) {
          case 401:
            toast.warn('No autorizado para realizar esta acción, verifique sus rol y permisos.');
            break;
          default:
            toast.error(`❌ Error desconocido impide obtener datos para la entrega, favor de verificar con el administrador, codigo de error: ${response?.json?.error.message}`);
            break;
        }
      }
    } catch (err) {
      console.log('err addItemsTable: ', err);
    } finally {
      dispatch(loaded('table'));
    }
  };
}

export function selectEmptyToTable() {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: SELECT_EMPTY_TO_TABLE,
      table,
    });
  };
}

export function selectToDelivered() {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: SELECT_TO_DELIVERED,
      table,
    });
  };
}

export function changeFieldValue(obj) {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: CHANGE_FIELD,
      obj,
      table,
    });
  };
}

export function selectToRefunded() {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: SELECT_TO_REFUNDED,
      table,
    });
  };
}

export function chnageComment(word) {
  return async (dispatch) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: CHANGE_COMMENT,
        word,
      });
    } catch {
      /* Continue regardless of error */
    }
  };
}

export function resetToOrigin() {
  return async (dispatch) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({ type: RESET_TO_ORIGIN });
    } catch {
      /* Continue regardless of error */
    }
  };
}

export function setTicket(ticket) {
  return async (dispatch) => {
    try {
      await dispatch(loading('tickets'));
      await new Promise(resolve => setTimeout(resolve));
      await dispatch({ type: SET_REFUND_TICKET, ticket });
      await dispatch(openModal('ticketsRefund'));
    } catch {
      /* Continue regardless of error */
    } finally {
      await dispatch(loaded('tickets'));
    }
  };
}

export function updateStore(store) {
  return (dispatch, getState) => {
    const { authUser: { user: { almacenes } } } = getState();
    const fullStore = almacenes.find(a => a.crmid === store.crmid);

    const newStore = {
      crmid: store.crmid,
      name: store.almacen || store.entity_label,
      image: store.images[0],
      direccion: store.treebesdireccionenvioid_label,
      telefono: store.telefono,
      email: store.email,
      url_web: store.url_web,
      campos: store.talm_tpv_campos_en_ticket,
      direccionid: store.treebesdireccionenvioid,
      pos: {},
      forzaEnvio: store.pos_forzaenvio,
      currencyid: store.pos_currency_id,
      treebesalmid_dev: fullStore.treebesalmid_dev || null,
      treebesalmid_env: fullStore.treebesalmid_env || null,
      treebesalmid_dev_label: fullStore.treebesalmid_dev_label || null,
      treebesalmid_env_label: fullStore.treebesalmid_env_label || null,
    };
    dispatch({
      type: UPDATE_STORE,
      store: newStore,
    });
  };
}

export function updateItems(stock) {
  return (dispatch, getState) => {
    const { table: { items } } = getState();
    const newItems = [...items];

    for (const stockItem of stock) {
      const itemIndex = newItems.findIndex(item => item.crmid === `${stockItem.productid}`);

      if (itemIndex !== -1) {
        newItems[itemIndex].qtyinstock = stockItem.qtyinstock;
      }
    }

    dispatch({
      type: UPDATE_ITEMS,
      items: newItems,
    });
  };
}

export function refoundBadProduct(value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BAD_PRODUCTS,
      value,
    });
  };
}

export function refoundChangeProduct(value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHANGE_PRODUCTS,
      value,
    });
  };
}
