/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import './warehouse.styles.scss';
import {
  faClipboardList, faShoppingCart, faReplyAll, faAdjust, faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Collapse } from 'reactstrap';
import WPurchases from './components/WPurchases';
import WSales from './components/WSales';
import WDeliver from './components/WDeliver';
import WTransfers from './components/WTransfers';
import WTransferProccess from './components/WTransferProccess';


const Warehouse = () => {
  const [option, setOption] = React.useState('');

  const setSelectOption = (section) => {
    localStorage.setItem('deliverOption', section);
    setOption(section);
  };

  useEffect(() => {
    const optionSelected = localStorage.getItem('deliverOption');
    if (optionSelected) {
      setOption(optionSelected);
    } else {
      setOption('sale');
    }
  }, []);

  return (
    <div
      className="warehouse-container"
      style={{
        width: 520,
        position: 'absolute',
        left: '50%',
        marginLeft: -260,
        maxHeight: '90%',
        overflow: 'auto',
        minHeight: '50vh',
      }}
    >
      {
        option === 'purchase' && (<WPurchases />)
      }
      {
        option === 'sales' && (<WSales />)
      }
      {
        option === 'deliver' && (<WDeliver />)
      }

      {
        option === 'transfers' && (<WTransfers />)
      }

      {
        option === 'transfers-process' && (<WTransferProccess />)
      }

      {/* Empty value */}
      <Collapse in={false}>
        <List dense>
          <ListItem classes={{ root: 'bg-white' }}>
            <ListItemText
              primary="Sin datos"
              secondary={`No se encontraron ${option === 'sales' ? 'ventas' : 'compras'}`}
            />
          </ListItem>
        </List>
      </Collapse>

      {/* Footer options */}
      <div
        className="warehouse-container__footer p-2 bg-white w-100 d-flex justify-content-around align-items-center"
      >
        <div role="button" onClick={() => setSelectOption('sales')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'sales' ? '#313C52' : '#B6BEC6' }} icon={faClipboardList} />
          <span style={{ fontSize: 14, color: option === 'sales' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            Entregar
            {' '}
            <br />
            Ventas
          </span>
        </div>
        <div role="button" onClick={() => setSelectOption('deliver')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'deliver' ? '#313C52' : '#B6BEC6' }} icon={faReplyAll} />
          <span style={{ fontSize: 14, color: option === 'deliver' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            Entregas
            <br />
            {' '}
            Externas
          </span>
        </div>
        <div role="button" onClick={() => setSelectOption('purchase')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'purchase' ? '#313C52' : '#B6BEC6' }} icon={faShoppingCart} />
          <span style={{ fontSize: 14, color: option === 'purchase' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            Recibir
            <br />
            {' '}
            Compras
          </span>
        </div>
        <div role="button" onClick={() => setSelectOption('transfers')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'transfers' ? '#313C52' : '#B6BEC6' }} icon={faAdjust} />
          <span style={{ fontSize: 14, color: option === 'transfers' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            Recibir
            {' '}
            <br />
            {' '}
            Transferencia
          </span>
        </div>
        <div role="button" onClick={() => setSelectOption('transfers-process')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'transfers-process' ? '#313C52' : '#B6BEC6' }} icon={faTag} />
          <span style={{ fontSize: 14, color: option === 'transfers-process' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            Procesar
            {' '}
            <br />
            {' '}
            Transferencia
          </span>
        </div>
      </div>
    </div>
  );
};

export default Warehouse;
