/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, {
  useEffect, useRef,
} from 'react';
import {
  useInfiniteHits, useInstantSearch,
} from 'react-instantsearch';
import PropTypes from 'prop-types';
import './product-table.scss';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import SortFor from '../SortFor';
import { setSingleDiscount } from '../../../../redux/actions/cartActions';
import ProductTableItem from './ProductTableItem';

const ProductTable = ({ onSelect, activeIndex }) => {
  const { status } = useInstantSearch();
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const {
    hits, isLastPage, showMore,
  } = useInfiniteHits();
  const sentinelRef = useRef(null);
  const { cartProductsByArray } = cart;
  const { user: { config: { store } } } = authUser;

  const checkMeliDiscounts = () => {
    // Loop throw cart products
    cartProductsByArray.map((cartProduct, index) => {
      const { parentId, crmid, tpoc_id } = cartProduct;
      hits.map((hit) => {
        if (`${hit.crmid}` === parentId || `${hit.crmid}` === crmid || `${hit.crmid}` === tpoc_id) {
          // Check if hit has discount
          if (hit.discount > 0) {
            // Check if cart product has discount
            if (cartProduct.discount_amount <= 0) {
              // Check if discounts are different
              dispatch(setSingleDiscount(index + 1, hit.discount));
            }
          }
        }
      });
    });
  };

  useEffect(() => {
    if (sentinelRef.current !== null && hits.length > 0) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore, hits]);

  useEffect(() => {
    checkMeliDiscounts();
  }, [cartProductsByArray]);

  return (
    <div className="product-table">
      {
        hits.length > 0
          ? (
            <table className="hits-table">
              <thead className="product-table-head">
                <tr>
                  <th style={{ width: 63, backgroundColor: '#f6f6f7' }}>
                    <span>
                      Imagen
                    </span>
                    {' '}
                  </th>
                  <th style={{ backgroundColor: '#f6f6f7' }}>
                    <div>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px',
                      }}
                      >
                        <span>Sku</span>
                        <SortFor
                          key="sku"
                          items={[
                            { label: 'SKU (asc)', value: `${activeIndex}:sku:asc` },
                            { label: 'SKU (desc)', value: `${activeIndex}:sku:desc` },
                          ]}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ backgroundColor: '#f6f6f7' }}>
                    <div>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px',
                      }}
                      >
                        <span>Categoría</span>
                      </div>
                    </div>
                  </th>
                  <th style={{ backgroundColor: '#f6f6f7' }}>
                    <div>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px',
                      }}
                      >
                        <span>Marca</span>
                      </div>
                    </div>
                  </th>
                  <th style={{ backgroundColor: '#f6f6f7' }}>
                    <div>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px',
                      }}
                      >
                        <span>Nombre</span>
                        <SortFor
                          key="name"
                          items={[
                            { label: 'Nombre (asc)', value: `${activeIndex}:entity_label:asc` },
                            { label: 'Nombre (desc)', value: `${activeIndex}:entity_label:desc` },
                          ]}
                        />
                      </div>
                    </div>

                  </th>
                  <th style={{ backgroundColor: '#f6f6f7' }}>
                    <div>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px',
                      }}
                      >
                        <span>Precio</span>
                        <SortFor
                          key="precio"
                          items={[
                            { label: 'Precio (asc)', value: `${activeIndex}:tpv_unit_price_included_tax:asc` },
                            { label: 'Precio (desc)', value: `${activeIndex}:tpv_unit_price_included_tax:desc` },
                          ]}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ backgroundColor: '#f6f6f7' }}>
                    <div>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px',
                      }}
                      >
                        <span>P.Neto</span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="product-table-contant">
                {hits.length > 0 && hits.map((hit, index) => (
                  <ProductTableItem key={hit.objectID} hit={hit} onSelect={onSelect} store={store} index={index} />
                ))}
                {
                  status !== 'loading' && (
                    <tr style={{ height: 50 }} ref={sentinelRef} aria-hidden="true" />
                  )
                }
              </tbody>
            </table>
          )
          : (
            <>
              {
                status === 'loading' || status === 'stalled'
                  ? (
                    <LinearProgress />
                  ) : (
                    <div className="no-results">
                      <h5>No hay resultados para tu busqueda</h5>
                    </div>
                  )
              }
            </>
          )
      }

    </div>
  ); };

ProductTable.propTypes = {
  onSelect: PropTypes.func.isRequired,
  activeIndex: PropTypes.string.isRequired,
};

export default ProductTable;
