/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import {
  setItem, findItem, removeItem, removeAllItems,
} from './storage';

const storageId = 'authUser';
export const setStorage = (authUser) => {
  const { isRemember } = authUser;
  setItem(storageId, authUser, isRemember);
  removeItem(storageId, !isRemember);
};

export const getStorage = () => findItem(storageId);

export const emptyStorage = () => removeAllItems(storageId);

const getPayMathodKey = (method) => {
  switch (method) {
    case 'Efectivo':
      return 1;
    case 'Transferencia (SPEI)':
      return 4;
    case 'Cheque':
      return 5;
    case 'Tarjeta de Credito':
      return 3;
    case 'Tarjeta de Debito':
      return 2;
    default:
      return 0;
  }
};

export const normalizePaymentMethods = (newUser) => {
  let arr = [];
  const user = { ...newUser };
  const { metodos_pagos } = user;
  Object.entries(metodos_pagos).forEach((item) => {
    const elements = item[1];
    if (elements.length === 0) { // -> used to remove invalid payment method
      return;
    }
    let body = [];
    if (elements.length) {
      if (elements.length > 1) {
        body = elements.map((cb) => {
          const select = false;
          return { ...cb, select };
        });
      } else body.push({ ...elements[0], select: true });

      arr = [...arr, { method: item[0], body, key: (getPayMathodKey(item[0])) }];
    }
  });
  arr.sort((a, b) => {
    if (a.key > b.key) return 1;
    if (a.key < b.key) return -1;
    return 0;
  });
  return { ...user, metodos_pagos: arr };
};

export const existUnselectMethod = (methods) => {
  const newMethods = [...methods];
  return newMethods.filter((method) => {
    const { body } = method;
    if (body.some(cb => cb.select === true)) {
      return false;
    }
    return true;
  });
};

const defualtState = () => ({
  post_test: 'Imagen |##| NoVenta |##| Cliente |##| Dirección |##| Atendido |##| Fecha |##| Facturación',
  pos_sell_tab: false,
  pos_print_size: '7cm',
  pos_checkout_bill: 'Preguntar',
  pos_print_after_sell: true,
});
export const getAuthUserConfigDefault = (config) => {
  const newConfig = {
    ...defualtState(),
    ...config,
    pos_cart_concepts: Boolean(config.pos_cart_concepts === '1'),
    pos_config_alm: Boolean(config.pos_config_alm === '1'),
  };
  return newConfig;
};

const defTicketState = () => ({
  alm_ticket_config: 'Imagen |##| NoVenta |##| Cliente |##| Dirección |##| Atendido |##| Fecha |##| Facturación',
});
export const getAuthUserAlmacenesDefault = almacenes => almacenes.map(x => ({ ...x, ...defTicketState() }));

export const setStore = (pos, config) => {
  const posNext = {
    ...defualtState(),
    ...pos,
    pos_sininv: Boolean(pos.pos_sininv === '1'),
    pos_cart_concepts: Boolean(pos.pos_cart_concepts === '1'),
    pos_pos_alm: Boolean(config.pos_config_alm === '1'),
  };
  const store = {
    crmid: pos.crmid,
    name: pos.almacen,
    telefono: pos.telefono,
    email: pos.email,
    url_web: pos.url_web,
    image: (pos.images && pos.images.length > 0) ? pos.images[0] : '',
    campos: pos.talm_tpv_campos_en_ticket,
    direccion: `${pos.direccion || ''} ${pos.num_ext || ''} , Colonia ${pos.neighborhood || ''}, ${pos.postcode || ''} ${pos.city || ''}, ${pos.state || ''} - ${pos.country || ''}`,
  };
  return ({
    ...config,
    ...posNext,
    store,
  });
};

export const normalDomain = (domain) => {
  let account = domain;
  const isStandarDomain = Boolean(domain.includes('.io') || domain.includes('.com'));
  if (!isStandarDomain) account = `comercia.io/0/${domain}`;
  return account.toLowerCase();
};

export function bytesToBase64(str) {
  let bytes;
  if (str instanceof Uint8Array) {
    bytes = str;
  } else {
    bytes = new TextEncoder().encode(str.toString());
  }
  const binString = Array.from(bytes, x => String.fromCodePoint(x)).join('');
  return binString;
}

export default {
  setStore,
  setStorage,
  getStorage,
  normalDomain,
  emptyStorage,
  existUnselectMethod,
  normalizePaymentMethods,
  getAuthUserConfigDefault,
  getAuthUserAlmacenesDefault,
  bytesToBase64,
};
