/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import {
  Tab,
  Grid,
  Tabs,
  Paper,
} from '@material-ui/core';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import { withRouter } from 'react-router-dom';
import tabsProps from '../../../../propTypes/tabsProps';

import PosActionTabs from './components/PosActionTabs';
import SearchBar from '../../../../components/SearchBar';
import PosActionReLoad from './components/PosActionReLoad';

import Alert from '../../../../components/Alert';
import OrderLists from '../../../../components/ActualOrder/components/OrderLists';


class PosItemContainerAction extends PureComponent {
  static propTypes = {
    tabs: tabsProps.isRequired,
    cart: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    authUser: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    dispatchFilterOrders: PropTypes.func.isRequired,
    dispatchMultipleFiltersOrder: PropTypes.func.isRequired,
    dispatchFilterProducts: PropTypes.func.isRequired,
    dispatchChangePosDisplayTabToGrid: PropTypes.func.isRequired,
    dispatchChangePosDisplayTabToList: PropTypes.func.isRequired,
    dispatchUpdateProductQuantityInCart: PropTypes.func.isRequired,
    dispatchChangePosTypeTabToOrdersAndClearCart: PropTypes.func.isRequired,
    dispatchChangePosTypeTabToProductsAndClearCart: PropTypes.func.isRequired,

    /* news */
    dispatchRefreshProducts: PropTypes.func.isRequired,
    dispatchRefreshOrders: PropTypes.func.isRequired,
    dispatchClearAllFilters: PropTypes.func.isRequired,
    dispatchChangePosTypeTabToCotizacionesAndClearCart: PropTypes.func.isRequired,
    dispatchFilterCotizacion: PropTypes.func.isRequired,
    dispatchRefreshCotizaciones: PropTypes.func.isRequired,
    dispatchSelectCustomer: PropTypes.func.isRequired,
    /* Edit */
    dispatchResetOrder: PropTypes.func.isRequired,
    dispatchHandleFastMode: PropTypes.func.isRequired,
    dispatchSelectFastOption: PropTypes.func.isRequired,
    dispatchFastOptionProductSearch: PropTypes.func.isRequired,
    /* Sell */
    dispatchSaveSell: PropTypes.func.isRequired,
    /* Cotizar */
    dispatchCotizar: PropTypes.func.isRequired,
    /* Modal */
    dispatchOpenModal: PropTypes.func.isRequired,
    dispatchCloseModal: PropTypes.func.isRequired,
    /* AlertProps */
    alertAreProductsDesc: PropTypes.string,
    alertAreProductsTitle: PropTypes.string,
    alertAreProductsPrimary: PropTypes.string,
    alertAreProductsSecondary: PropTypes.string,
    alertAreProductsCotizaPrimary: PropTypes.string,
    alertAreProductsCotizaSecondary: PropTypes.string,
    /* Older orders */
    dispatchOlderOrders: PropTypes.func.isRequired,
    /* Older products */
    dispatchOlderProducts: PropTypes.func.isRequired,
    /* Older quots */
    dispatchOlderQuotes: PropTypes.func.isRequired,
  }

  static defaultProps = {
    alertAreProductsSecondary: 'Generar Venta',
    alertAreProductsCotizaSecondary: 'Generar Cotización',
    alertAreProductsTitle: 'Existen productos en tu carrito ...',
    alertAreProductsPrimary: 'Borra el carrito y llevame a Ventas',
    alertAreProductsCotizaPrimary: 'Borra el carrito y llevame a mis Cotizaciones',
    alertAreProductsDesc: 'Tu carrito cuenta con productos aún, si cambias de pestaña; estos productos desaparecenran, te recomendamos las siguientes opciones ...',
  }

  constructor(props) {
    super(props);
    const {
      authUser,
      dispatchChangePosDisplayTabToGrid,
      dispatchChangePosDisplayTabToList,
      dispatchChangePosTypeTabToProductsAndClearCart,
    } = this.props;
    const { user: { config: { pl_pos_view_type } } } = authUser;
    this.state = {
      searchQty: 0,
      searchVal: '',
      searchMode: false,
      tabDisplay: isMobile ? 0 : 1,
    };
    dispatchChangePosTypeTabToProductsAndClearCart();
    if (isMobile) {
      dispatchChangePosDisplayTabToGrid();
    }
    else if (pl_pos_view_type === 'Lista') {
      dispatchChangePosDisplayTabToList();
      this.state = {
        ...this.state,
        tabDisplay: 1,
      };
    } else {
      dispatchChangePosDisplayTabToGrid();
      this.state = {
        ...this.state,
        tabDisplay: 0,
      };
    }
  }

  componentWillReceiveProps(news) {
    const {
      tabs,
      dispatchClearAllFilters,
    } = this.props;
    if (tabs !== news.tabs) {
      this.setSearchEmpty();
      dispatchClearAllFilters();
    }
  }

  setQtyState = number => this.setState({ searchQty: number });

  // @ts-ignore
  handleOnChangeTabType = async (event, value) => {
    const {
      cart,
      customer,
      dispatchOpenModal,
      dispatchResetOrder,
      dispatchSelectCustomer,
      dispatchHandleFastMode,
      dispatchChangePosTypeTabToProductsAndClearCart,
      dispatchChangePosTypeTabToOrdersAndClearCart,
      dispatchChangePosTypeTabToCotizacionesAndClearCart,
      history,
    } = this.props;
    const { totalProducts, orderSelected } = cart;
    const theAreProducts = Boolean(totalProducts > 0);
    const isOrder = Boolean(Object.keys(orderSelected).length > 0);
    const { defaultCustomer } = customer;
    switch (value) {
      case 'Vender':
        dispatchChangePosTypeTabToProductsAndClearCart();
        dispatchSelectCustomer(defaultCustomer);
        dispatchResetOrder();
        history.push('/pos/vender');
        break;
      case 'Historico':
        if (theAreProducts && !isOrder) {
          dispatchOpenModal('changeTabVentas');
        } else {
          await dispatchHandleFastMode(false);
          dispatchChangePosTypeTabToOrdersAndClearCart();
          dispatchResetOrder();
          history.push('/pos/ventas');
        }
        break;
      case 'Cotizaciones':
        if (theAreProducts && !isOrder) {
          dispatchOpenModal('changeTabCotiza');
        } else {
          await dispatchHandleFastMode(false);
          dispatchChangePosTypeTabToCotizacionesAndClearCart();
          dispatchResetOrder();
        }
        history.push('/pos/cotizaciones');
        break;
      default:
        break;
    }
  }

  evaluateTab = (search) => {
    const {
      tabs,
      authUser,
      dispatchFilterOrders,
      dispatchFilterProducts,
      dispatchFilterCotizacion,
      dispatchFastOptionProductSearch,
    } = this.props;
    const { fastMode } = authUser;
    if (search.includes('*')) {
      const values = search.split('*');
      search = values[0];
    }
    switch (tabs.posTypeTab) {
      case 'Vender':
        if (!fastMode) {
          /* dispatchFastOptionProductSearch(search);
        } else { */
          dispatchFilterProducts(search);
        }
        break;
      case 'Historico':
        // dispatchFilterOrders(search);
        this.handleEnterPress('Enter', search);
        break;
      case 'Cotizaciones':
        // dispatchFilterCotizacion(search);
        this.handleEnterPress('Enter', search);
        break;
      default:
        return null;
    }
    return null;
  }

  handleChangeSearch = (event) => {
    if (!event) {
      this.setState({ searchVal: '', searchQty: 0 });
      return;
    }
    const { value } = event.target;
    if (value.includes('*')) {
      const values = value.split('*');
      this.setState({ searchVal: values[0], searchQty: Number.parseFloat(values[1]) });
    } else {
      this.setState({ searchVal: event.target.value, searchQty: 0 });
    }
  };

  setSearchEmpty = () => {
    this.setState({ searchVal: '', searchQty: 0 });
    if (document.querySelector('#search_bar_query')) {
      document.querySelector('#search_bar_query').value = '';
    }
  }

  // @ts-ignore
  handleOnChangeTabDisplay = (event, value) => {
    const {
      dispatchChangePosDisplayTabToGrid,
      dispatchChangePosDisplayTabToList,
    } = this.props;
    if (value === 0) {
      dispatchChangePosDisplayTabToGrid();
    }
    else if (value === 1) {
      dispatchChangePosDisplayTabToList();
    }
    this.setState({ tabDisplay: value });
  }

  handleOnClickRefresh = () => {
    const {
      tabs,
      /* news */
      dispatchRefreshOrders,
      dispatchRefreshProducts,
      dispatchRefreshCotizaciones,
      dispatchMultipleFiltersOrder,
    } = this.props;
    if (tabs.posTypeTab === 'Vender') {
      return () => {
        dispatchRefreshProducts();
        this.setSearchEmpty();
      };
    }

    if (tabs.posTypeTab === 'Historico') {
      return () => {
        this.setSearchEmpty();
        dispatchMultipleFiltersOrder({
          title: 'search',
          value: '',
          type: 'search',
        });
        dispatchRefreshOrders();
      };
    }

    if (tabs.posTypeTab === 'Cotizaciones') {
      return () => {
        dispatchRefreshCotizaciones();
        this.setSearchEmpty();
      };
    }

    return () => {};
  }

  fastSearch = () => {
    const { searchVal } = this.state;
    const { dispatchFastOptionProductSearch } = this.props;
    dispatchFastOptionProductSearch(searchVal);
  };

  handleEnterPress = async (key, search) => {
    const {
      tabs, dispatchOlderOrders, dispatchOlderProducts, dispatchOlderQuotes, authUser, dispatchFastOptionProductSearch,
    } = this.props;
    const { fastMode } = authUser;
    if (tabs.posTypeTab === 'Historico') {
      if (key === 'Enter') {
        await dispatchOlderOrders(search);
      }
    }
    if (tabs.posTypeTab === 'Vender') {
      if (key === 'Enter') {
        if (fastMode) {
          dispatchFastOptionProductSearch(search);
        } else {
          await dispatchOlderProducts(search);
        }
      }
    }
    if (tabs.posTypeTab === 'Cotizaciones') {
      if (key === 'Enter') {
        await dispatchOlderQuotes(search);
      }
    }
  };

  handleSearchMode = async () => {
    const {
      dispatchHandleFastMode,
      dispatchFastOptionProductSearch,
    } = this.props;
    await dispatchHandleFastMode();
    const search = document.querySelector('#search_bar_query');
    if (search) {
      // @ts-ignore
      search.value = '';
      // @ts-ignore
      search.focus();
      await dispatchFastOptionProductSearch('');
      this.setState({ searchQty: 0 });
    }
  }

  render() {
    const {
      history,
      tabs,
      cart,
      modals,
      authUser,
      dispatchCotizar,
      dispatchSaveSell,
      dispatchCloseModal,
      dispatchResetOrder,
      alertAreProductsDesc,
      alertAreProductsTitle,
      dispatchHandleFastMode,
      alertAreProductsPrimary,
      dispatchSelectFastOption,
      alertAreProductsSecondary,
      alertAreProductsCotizaPrimary,
      alertAreProductsCotizaSecondary,
      dispatchUpdateProductQuantityInCart,
      dispatchChangePosTypeTabToOrdersAndClearCart,
      dispatchChangePosTypeTabToCotizacionesAndClearCart,
    } = this.props;
    const currentOrderStatus = authUser.user.config.sostatus;
    const { posTypeTab } = tabs;
    const { tabDisplay, searchVal, searchQty } = this.state;
    const handleOnClickRefres = this.handleOnClickRefresh();
    const { fastMode } = authUser;
    const placeholder = (fastMode ? 'Búsqueda Rápida' : 'Búsqueda General');
    const tooltipTitle = (fastMode
      ? 'Tab -> Busqueda General'
      : 'Tab -> Busqueda Rápida'
    );
    return (
      <Paper className="pos-action mb-1">
        <div className="pl-3 pr-3">
          <Grid container spacing={0} justify="center" alignItems="center">
            {/*  {
              posTypeTab === 'Vender' && (
                <>
                  <Grid item sm={3}>
                    <SearchBar
                      tabs={tabs}
                      cart={cart}
                      searchQty={searchQty}
                      searchVal={searchVal}
                      searchMode={fastMode}
                      placeholder={placeholder}
                      tooltipTitle={tooltipTitle}
                      setQtyState={this.setQtyState}
                      handleSearchMode={this.handleSearchMode}
                      desableSearchMode={posTypeTab !== 'Vender'}
                  // handleOnKeyPress={word => this.handleEnterPress('Enter', word)}
                      handleOnKeyPress={word => this.evaluateTab(word)}
                      dispatchSelectFastOption={dispatchSelectFastOption}
                  // handleChangeSearch={event => this.handleEnterPress('Enter', event.target.value)}
                      handleEnterPress={(key, search) => this.handleEnterPress(key, search)}
                      dispatchUpdateProductQuantityInCart={dispatchUpdateProductQuantityInCart}
                    />
                  </Grid>
                  <Grid item sm={1}>
                    <PosActionReLoad
                      handleOnClick={handleOnClickRefres}
                    />
                  </Grid>
                </>
              )
            } */}

            {/* {
              posTypeTab === 'Vender' && (
              <Grid item sm={2}>
                <div className="pos__center-container pos__tab-contaniner pos__tab-display">
                  <Tabs
                    value={tabDisplay}
                    onChange={this.handleOnChangeTabDisplay}
                    className="pt-2"
                  >
                    <Tab
                      icon={<ViewModuleIcon />}
                    />
                    <Tab
                      icon={<ViewListIcon />}
                    />
                  </Tabs>
                </div>
              </Grid>
              )} */}
            {
                posTypeTab !== 'Vender' && (
                  <>
                    <Grid item sm={posTypeTab === 'Vender' ? 6 : 5}>
                      <PosActionTabs
                        tabs={tabs}
                        handleTab={this.handleOnChangeTabType}
                      />
                    </Grid>
                    {/* <Grid item sm={3} /> */}
                    <Grid item sm={7}>
                      <OrderLists />
                    </Grid>
                  </>
                )
              }
          </Grid>
        </div>
        <Alert
          type="simple"
          isCancellable
          isPrimaryAction
          isSecondaryAction
          desc={alertAreProductsDesc}
          title={alertAreProductsTitle}
          open={Boolean(modals.changeTabVentasModalIsOpen)}
          primaryAction={async () => {
            await dispatchHandleFastMode(false);
            dispatchChangePosTypeTabToOrdersAndClearCart();
            dispatchResetOrder();
            dispatchCloseModal('changeTabVentas');
            history.push('/pos/ventas');
          }}
          primaryActionButton={alertAreProductsPrimary}
          secondaryAction={async () => {
            await dispatchSaveSell(currentOrderStatus);
            dispatchCloseModal('changeTabVentas');
          }}
          secondaryActionButton={alertAreProductsSecondary}
          handleClose={() => dispatchCloseModal('changeTabVentas')}
        />
        <Alert
          type="simple"
          isCancellable
          isPrimaryAction
          isSecondaryAction
          desc={alertAreProductsDesc}
          title={alertAreProductsTitle}
          open={Boolean(modals.changeTabCotizaModalIsOpen)}
          primaryAction={async () => {
            await dispatchHandleFastMode(false);
            dispatchChangePosTypeTabToCotizacionesAndClearCart();
            dispatchResetOrder();
            dispatchCloseModal('changeTabCotiza');
            history.push('/pos/cotizaciones');
          }}
          primaryActionButton={alertAreProductsCotizaPrimary}
          secondaryAction={async () => {
            await dispatchCotizar();
            dispatchCloseModal('changeTabCotiza');
          }}
          secondaryActionButton={alertAreProductsCotizaSecondary}
          handleClose={() => dispatchCloseModal('changeTabCotiza')}
        />
      </Paper>
    );
  }
}

export default withRouter(PosItemContainerAction);
