/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectCustomer from '../../../Customer/components/SelectCustomer';
import { setOrderCustomer } from '../../../../redux/actions/orderActions';
import { fetchAllCustomers, filterCustomers } from '../../../../redux/actions/customerActions';
import { toggleModal } from '../../../../redux/actions/modalActions';

const SelectCustomerModal = () => {
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);
  const modals = useSelector(state => state.modals);
  const loads = useSelector(state => state.loads);
  const cart = useSelector(state => state.cart);
  const { orderSelected } = cart;

  const dispatchEditOrder = account_id => dispatch(setOrderCustomer(account_id));
  const dispatchFetchAllCustomers = () => dispatch(fetchAllCustomers());
  const dispatchFilterCustomers = word => dispatch(filterCustomers(word));
  const dispatchSelectCustomer = (custome, fetch = true) => dispatch(setOrderCustomer(custome, fetch));
  const dispatchToggleModal = modal => dispatch(toggleModal(modal));

  return (
    <SelectCustomer
      customer={customer}
      dispatchEditOrder={dispatchEditOrder}
      dispatchFetchAllCustomers={dispatchFetchAllCustomers}
      dispatchFilterCustomers={dispatchFilterCustomers}
      dispatchSelectCustomer={dispatchSelectCustomer}
      dispatchToggleModal={dispatchToggleModal}
      loads={loads}
      modals={modals}
      orderSelected={orderSelected}
    />
  );
};

export default SelectCustomerModal;
